import React, { useContext, useEffect } from "react";
import { ApolloError } from "@apollo/client";
import { UiSchema } from "@rjsf/utils";
import {
  DefaultedValuesArgs,
  DefaultedValueArgs,
  useCommonDefaultedValue,
  useCommonDefaultedValues,
} from "common-client/utils/submissions";
import { SubmissionFormContext } from "./Form";
import { InformationalTooltip } from "../Common/Tooltip";

export const useDefaultedValues = <
  Keys extends string = string,
  Values extends Record<Keys, any> = Record<Keys, any>,
  Schema extends Record<keyof Values, UiSchema | undefined> = Record<
    keyof Values,
    UiSchema | undefined
  >
>(
  args: DefaultedValuesArgs<Keys, Values, Schema>
) => {
  const { editing, defaultValues } = useContext(SubmissionFormContext);
  return useCommonDefaultedValues({
    ...args,
    useEffect,
    defaultValues,
    editing,
  });
};

export const useDefaultedValue = <Value,>(args: DefaultedValueArgs<Value>) => {
  const { editing, defaultValues } = useContext(SubmissionFormContext);
  return useCommonDefaultedValue({
    ...args,
    useEffect,
    defaultValues,
    editing,
  });
};

export const generateUpsertErrorMessage = (error: ApolloError) => {
  const message =
    error.message.includes("Invalid PDF file") ||
    error.message.includes("A record with the unique ID already exists.")
      ? error.message
      : "Your submission failed to process. Please try again.";

  return message;
};

export const castEmptyStringToNull = ({
  value,
  required,
}: {
  value: Maybe<string>;
  required: boolean;
}) => {
  // see commit message for why this `if` is necessary
  if (required) {
    return value === "" ? null : value;
  } else {
    return value;
  }
};

export const hasSummary = ({ summaries }: { summaries: Array<unknown> }) => {
  return summaries.length > 0;
};

export const getTooltip = ({ uiSchema }: { uiSchema?: UiSchema }) => {
  const tooltipText = uiSchema?.["ui:tooltipText"];
  if (!tooltipText) {
    return;
  }
  return <InformationalTooltip tooltipText={tooltipText} />;
};
