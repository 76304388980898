import React from "react";
import { useModal } from "react-modal-hook";
import Modal from "../../Common/Modal";

type SharedDeleteModalProps = {
  address?: Maybe<string>;
  onSave: () => void;
  onCancel: () => void;
};

type DeleteModalProps<Props> = SharedDeleteModalProps & {
  modalProps: Props;
};

type DeletePanelProps<Props> = DeleteModalProps<Props> & {
  DeletePanel: React.FC<
    Props & Omit<SharedDeleteModalProps, "onSave"> & { onComplete: () => void }
  >;
};

export const useDeleteModal = <Props,>({
  address,
  onSave,
  onCancel,
  DeletePanel,
  modalProps,
}: DeletePanelProps<Props>) => {
  const [showDeleteModal, hideDeleteModal] = useModal(() => {
    const handleCancel = () => {
      hideDeleteModal();
      onCancel();
    };

    const handleComplete = () => {
      onSave();
      hideDeleteModal();
    };

    return (
      <Modal onRequestClose={handleCancel}>
        <DeletePanel
          {...modalProps}
          address={address}
          onCancel={handleCancel}
          onComplete={handleComplete}
        />
      </Modal>
    );
  }, [address, modalProps]);

  return [showDeleteModal, hideDeleteModal] as const;
};
