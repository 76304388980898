import React, { ReactNode } from "react";
import EmptyStateIcon from "../../images/empty-state.svg";

import { Container, Icon, Message } from "./__styles__/EmptyState";

export interface EmptyStateProps {
  message: ReactNode;
  hasPaddingBottom?: boolean;
}

export const EmptyState = ({ message, hasPaddingBottom }: EmptyStateProps) => {
  return (
    <Container hasPaddingBottom={hasPaddingBottom}>
      <Icon src={EmptyStateIcon} alt="Generic house with a circle" />
      <Message>{message}</Message>
    </Container>
  );
};
