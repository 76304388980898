import React, { useEffect, useMemo, useRef } from "react";

import {
  Point,
  LayerConfig,
  LayerHookBuilder,
  LayerHook,
  findRelevantFeature,
  Parcel,
  findRelevantFeatures,
} from "./types";
import ParcelLayer, { id as layerId } from "../layers/parcels";
import { pick } from "lodash";

export type ParcelLayerConfig = LayerConfig<
  { id?: Maybe<string>; point?: Maybe<Point> },
  Array<Parcel & { fullAddress: string }>
>;

const parcelHook: LayerHookBuilder<ParcelLayerConfig> = ({
  config,
  mapLoaded,
  helpers,
}) => {
  const clickedParcel = useRef<Maybe<string>>(null);
  const hoveredParcels = useRef<Array<string>>([]);
  const sourceLayer = "src";

  const gotoConfigParcel = (
    { retry }: { retry: boolean } = { retry: true }
  ) => {
    if (!mapLoaded) {
      return;
    }

    if (clickedParcel.current) {
      helpers.emit({
        type: "removeFeatureState",
        data: {
          id: clickedParcel.current,
          field: "click",
          source: layerId,
          sourceLayer,
        },
      });
    }

    let parcelId = config.value?.id;
    if (!parcelId && config.value?.point) {
      parcelId = helpers.featuresAtPoint({
        layer: "parcels",
        point: config.value.point,
      })[0]?.id;

      // terrible hack - sometimes we switch parcels
      // while also panning the map, and so we have
      // to make sure to try to fetch the parcel from the
      // map layer when the map is finally loaded. Unfortunately,
      // I can't seem to figure out how to infer that from the
      // map state, so I'm just setting a timeout for now
      if (retry && !parcelId) {
        setTimeout(() => gotoConfigParcel({ retry: false }), 1000);
      }
    }

    if (parcelId) {
      helpers.emit({
        type: "setFeatureState",
        data: { id: parcelId, field: "click", source: layerId, sourceLayer },
      });

      clickedParcel.current = parcelId;
    }
  };

  const formatParcel = (feature: any, point: Point) => {
    return {
      ...pick(feature.properties, [
        "address",
        "city",
        "state",
        "zipcode",
        "id",
        "parcelNumber",
      ]),
      propertyIds: feature.properties?.propertyIds
        ? JSON.parse(feature.properties.propertyIds)
        : [],
      fullAddress: `${feature.properties.address}, ${feature.properties.city}, ${feature.properties.state} ${feature.properties.zipcode}`,
      point,
    };
  };

  const parcelIsClickable = (
    parcels: Array<
      Parcel & {
        fullAddress: string;
      }
    >
  ) =>
    typeof config.interactive?.click === "function"
      ? config.interactive.click(parcels)
      : !!config.interactive?.click;

  useEffect(gotoConfigParcel, [
    config.value?.id,
    config.value?.point?.latitude,
    config.value?.point?.longitude,
    mapLoaded,
  ]);

  return useMemo<LayerHook>(() => {
    return {
      zIndex: 1,
      interactiveLayerIds: [layerId],
      render: ({ account }) => {
        return <ParcelLayer key={layerId} accountId={account.id} />;
      },
      canHandleHover: () => !!config.interactive?.hover,
      canHandleClick:
        // we check for an empty feature array here because
        // if you click on the map but *don't* click on an interactive
        // layer, we want to keep the parcel clicked
        event => !!config.interactive?.click && !!event.features?.length,
      onHover: ({ event }) => {
        hoveredParcels.current.forEach(id => {
          helpers.emit({
            type: "removeFeatureState",
            data: { id, field: "hover", source: layerId, sourceLayer },
          });
        });

        hoveredParcels.current = [];
        const feature = findRelevantFeature(event, layerId);

        if (!feature) {
          helpers.emit({ type: "removeTooltip", data: {} });
          helpers.setCursor("grab");
          return;
        }

        const [longitude, latitude] = event.lngLat as [number, number];
        const point = { longitude, latitude };

        const formattedParcel = formatParcel(feature, point);

        if (parcelIsClickable([formattedParcel])) {
          helpers.setCursor("pointer");
        } else {
          helpers.setCursor("grab");
        }

        helpers.emit({
          type: "setFeatureState",
          data: {
            id: feature.id,
            field: "hover",
            source: layerId,
            sourceLayer,
          },
        });

        helpers.emit({
          type: "setTooltip",
          data: {
            ...event.offsetCenter,
            text: feature.properties.address,
          },
        });

        hoveredParcels.current = [feature.id];
      },
      onClick: ({ event }) => {
        const [longitude, latitude] = event.lngLat as [number, number];

        const point = { longitude, latitude };

        const clickedParcelFeatures = findRelevantFeatures(event, layerId);
        const clickedParcelFeature = clickedParcelFeatures[0];

        if (
          clickedParcel.current &&
          clickedParcel.current !== clickedParcelFeature?.id
        ) {
          helpers.emit({
            type: "removeFeatureState",
            data: {
              id: clickedParcel.current,
              field: "click",
              source: layerId,
              sourceLayer,
            },
          });
        }

        const clickedParcels = clickedParcelFeatures.map(feature =>
          formatParcel(feature, point)
        );

        if (!parcelIsClickable(clickedParcels) || !clickedParcels.length) {
          return false;
        }

        if (clickedParcelFeature) {
          clickedParcel.current = clickedParcelFeature.id;
          helpers.emit({
            type: "setFeatureState",
            data: {
              id: clickedParcelFeature.id,
              field: "click",
              source: layerId,
              sourceLayer,
            },
          });
        }

        config.onClick?.(clickedParcels);

        return !!clickedParcelFeatures.length;
      },
      onFocusLost: () => {
        helpers.emit({ type: "removeTooltip", data: {} });

        hoveredParcels.current.forEach(id => {
          helpers.emit({
            type: "removeFeatureState",
            data: { id, field: "hover", source: layerId, sourceLayer },
          });
        });

        hoveredParcels.current = [];
      },
    };
  }, [config]);
};

export default parcelHook;
