import React from "react";
import NoticeBoard from "../Common/NoticeBoard";
import { IssueBanner } from "../Issues";
import { AuthContext } from "../Authorization/AuthContext";
import Icon, { Icons } from "../Common/Icons";

export interface MissingPropertyParams {
  title: string;
  preamble: string;
  latitude?: number;
  longitude?: number;
  address?: Maybe<string>;
  showIssueBanner?: boolean;
}
export default ({
  title,
  preamble,
  latitude,
  longitude,
  address,
  showIssueBanner = true,
}: MissingPropertyParams) => {
  const { isGuest } = React.useContext(AuthContext);
  const defaultIssueText =
    'Please add an address to this property by clicking the "Edit property" button above.';
  const guestIssueText =
    "If our data looks incorrect, please let your local floodplain manager know.";
  const trafficSign = <Icon icon={Icons.TRAFFIC_SIGN} />;

  const shouldShowIssueBanner =
    !isGuest && showIssueBanner && address && longitude && latitude;

  return (
    <div>
      <NoticeBoard
        icon={trafficSign}
        heading={title}
        note={`${preamble}. ${isGuest ? guestIssueText : defaultIssueText}`}
      />
      {shouldShowIssueBanner && (
        <IssueBanner address={address} coordinates={[longitude, latitude]} />
      )}
    </div>
  );
};
