import React from "react";

import { Tooltip } from "./types";
import "./__styles__/Tooltip.scss";

export default ({ tooltip }: { tooltip: Maybe<Tooltip> }) => {
  if (!tooltip) {
    return null;
  }

  return (
    <span style={{ top: tooltip.y, left: tooltip.x }} styleName="tooltip">
      {tooltip.text}
    </span>
  );
};
