import React from "react";
import TabItem from "./TabItem";
import { TabBar } from "./__styles__/TabGroup";

export default <T extends string>({
  currentTab,
  setTab,
  tabs,
  color = "blue",
}: {
  currentTab: T;
  setTab: (value: T) => void;
  tabs: Array<{ label: string | JSX.Element; value: T }>;
  color?: "blue" | "grey";
}) => {
  return (
    <TabBar data-testid="tab-group">
      {tabs.map(tab => (
        <TabItem
          setTab={setTab}
          active={tab.value === currentTab}
          value={tab.value}
          key={tab.value}
          color={color}
        >
          {tab.label}
        </TabItem>
      ))}
    </TabBar>
  );
};
