import { countBy, has, isEmpty, reduce } from "lodash";
import React, { ReactNode, useState } from "react";
import { HEADER_OFFSET, fieldWrapperId, scrollInputIntoView } from "../utils";
import { Controller, useForm, useFormState } from "react-hook-form";

import Icon, { ICON_COLORS, Icons } from "../../../Common/Icons";
import ExtractedField, {
  ExtractedFieldProps,
} from "./ExtractedFields/ExtractedField";
import { Button } from "../../../Common/Button";
import {
  CertificateFormFields,
  CertificateFormFieldDottableKeys,
} from "./CertificatesInputs";
import {
  FieldWrapper,
  FormButtons,
  Loading,
  ProcessingWrapper,
} from "../__styles__/Form";
import { track } from "../../../../utils/tracking";

export type DocumentFormFieldDescription<
  T extends CertificateFormFieldDottableKeys
> = Omit<
  ExtractedFieldProps<T>,
  "inputValue" | "onChange" | "disabled" | "onConfirmAccuracy"
>;

export interface Props {
  onFormSubmit: (data: DeepPartial<CertificateFormFields>) => void;
  inputs: Array<DocumentFormFieldDescription<CertificateFormFieldDottableKeys>>;
  disabled: boolean;
  children?: ReactNode;
  loading?: boolean;
  scrollRef?: Maybe<React.RefObject<HTMLDivElement>>;
  onConfirmAccuracy: (props: { fields: Array<string> }) => void;
}

type FieldsOpenState = {
  [key in CertificateFormFieldDottableKeys]?: boolean;
};

const DocumentForm = ({
  onFormSubmit,
  inputs,
  children,
  loading,
  scrollRef,
  onConfirmAccuracy,
  disabled,
}: Props) => {
  const { handleSubmit, control, reset } = useForm<CertificateFormFields>();
  const windowLocationHash = window.location.hash.replace("#", "");
  const expandedFields = windowLocationHash.split(",");
  const initialFieldsOpen =
    !isEmpty(expandedFields) && !isEmpty(expandedFields[0])
      ? reduce(expandedFields, (acc, field) => ({ ...acc, [field]: true }), {})
      : {};

  const [fieldsOpen, setFieldsOpen] =
    useState<FieldsOpenState>(initialFieldsOpen);
  const expandedFieldCount = countBy(Object.values(fieldsOpen))["true"] ?? 0;

  React.useEffect(() => {
    if (!scrollRef) {
      return;
    }
    const field = document.getElementById(
      fieldWrapperId(expandedFields[0] ?? "")
    );
    const fieldOffset = field?.offsetTop ?? 0;

    if (scrollRef.current && scrollRef.current.scrollTo) {
      scrollRef.current.scrollTo({
        top: fieldOffset - HEADER_OFFSET,
      });
    }
  }, [expandedFields[0]]);

  const { dirtyFields } = useFormState({
    control,
  });

  const closeAllFields = () => {
    setFieldsOpen({});
  };

  const deeplyFilterObject = <T extends Record<string, any>>(
    obj: T,
    prefix: string = ""
  ): DeepPartial<T> => {
    const filteredObject: any = {};

    for (const [key, value] of Object.entries(obj)) {
      if (has(dirtyFields, `${prefix}${key}`)) {
        if (value instanceof Object && !Array.isArray(value)) {
          filteredObject[key] = deeplyFilterObject<T[keyof T]>(
            value,
            `${prefix}${key}.`
          );
        } else {
          filteredObject[key] = value;
        }
      }
    }

    return filteredObject;
  };

  const onSubmit = (data: CertificateFormFields) => {
    const dirtyFormData = deeplyFilterObject(data);

    onFormSubmit(dirtyFormData);
    closeAllFields();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {inputs.map(
        (
          input,
          index: number,
          array: Array<
            DocumentFormFieldDescription<CertificateFormFieldDottableKeys>
          >
        ) => {
          const isLastItem = index === array.length - 1;
          return (
            <Controller
              control={control}
              defaultValue={input.value}
              name={input.name}
              key={index}
              render={({ field }) => {
                return (
                  <FieldWrapper id={fieldWrapperId(input.name)}>
                    <ExtractedField
                      value={input.value}
                      inputValue={field.value ?? ""}
                      label={input.label}
                      disabled={disabled}
                      isLowConfidence={input.isLowConfidence}
                      name={input.name}
                      type={input.type}
                      format={input.format}
                      options={input.options}
                      hint={input.hint}
                      onChange={field.onChange}
                      isOpen={fieldsOpen[input.name] ?? false}
                      onOpen={() => {
                        track("Clicked into extraction field", {
                          field: input.name,
                          isLowConfidence: input.isLowConfidence,
                        });
                        setFieldsOpen({
                          ...fieldsOpen,
                          [input.name]: true,
                        });
                      }}
                      onElementIsOpen={() => {
                        scrollInputIntoView(fieldWrapperId(input.name));
                      }}
                      onClose={() => {
                        setFieldsOpen({
                          ...fieldsOpen,
                          [input.name]: false,
                        });
                      }}
                      onConfirmAccuracy={onConfirmAccuracy}
                      position={isLastItem ? "above-left" : "below-left"}
                    />
                  </FieldWrapper>
                );
              }}
            />
          );
        }
      )}

      {children}

      {(expandedFieldCount > 0 || loading) && (
        <FormButtons>
          {loading && (
            <ProcessingWrapper>
              Processing file{" "}
              <Loading>
                <Icon
                  icon={Icons.SMALL_LOADING}
                  color={ICON_COLORS.LIGHT_GREY}
                />
              </Loading>
            </ProcessingWrapper>
          )}

          <Button
            styleVariant="secondary"
            onClick={() => {
              reset();
              closeAllFields();
            }}
            disabled={loading}
            size="medium"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {}}
            disabled={
              loading || Object.keys(dirtyFields).length === 0 || disabled
            }
            size="medium"
            styleVariant="primary"
          >
            Save Extraction{expandedFieldCount > 1 && "s"}
          </Button>
        </FormButtons>
      )}
    </form>
  );
};

export default DocumentForm;
