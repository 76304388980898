import React from "react";
import { FieldProps } from "@rjsf/utils";
import { Number } from "../../Inputs";
import { Input } from "../__styles__/Inputs";
import { getTooltip, useDefaultedValue } from "../utils";

export const NumberInput = (
  props: FieldProps & { isSubmissionBuilder?: boolean }
) => {
  const { value } = useDefaultedValue({
    value: props.formData,
    uiSchema: props.uiSchema,
    onChange: props.onChange,
  });

  const tooltip = getTooltip(props);

  return (
    <Input isSubmissionBuilder={props.isSubmissionBuilder}>
      <Number
        name={props.idSchema.$id}
        label={props.schema.title}
        required={props.required && !props.disabled}
        placeholder={props.uiSchema?.["ui:placeholder"]}
        value={value}
        onChange={props.onChange}
        disabled={props.disabled}
        error={props.rawErrors?.join(", ")}
        step={props.uiSchema?.["ui:step"]}
        postfix={props.uiSchema?.["ui:postfix"]}
        prefix={props.uiSchema?.["ui:prefix"]}
        labelSize={props.uiSchema?.["ui:labelSize"]}
        tooltip={tooltip}
      />
    </Input>
  );
};
