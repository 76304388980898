import React from "react";
import { BottomBanner } from "../Common/BottomBanner";
import { Body } from "../Common/Typography";
import { StyledLink } from "../Common/__styles__/Typography";
import { colors } from "common-client/utils/styling";

const GeolocateBanner = ({ onClose }: { onClose: () => void }) => {
  return (
    <BottomBanner
      title={"Location permission"}
      subtitle={"Forerunner could not determine your precise location."}
      onClose={onClose}
    >
      <Body type="emphasis" size="default" color="contentInfoDark">
        <StyledLink
          target="_blank"
          href="https://forerunner.helpscoutdocs.com/article/73-location-services"
          css={{ color: colors.contentInfoDark }}
        >
          Get help
        </StyledLink>
      </Body>
    </BottomBanner>
  );
};

export default GeolocateBanner;
