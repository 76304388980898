import React, { useMemo } from "react";
import Marker from "../Marker";
import { LayerConfig, LayerHook, LayerHookBuilder, Point } from "./types";

export type MarkerLayerConfig = LayerConfig<Point>;

const markerHook: LayerHookBuilder<MarkerLayerConfig> = ({ config }) => {
  return useMemo<LayerHook>(() => {
    return {
      render: () => {
        return (
          config.value && (
            <Marker
              key={"marker"}
              latitude={config.value.latitude}
              longitude={config.value.longitude}
            />
          )
        );
      },
      interactiveLayerIds: ["marker"],
    };
  }, [config]);
};

export default markerHook;
