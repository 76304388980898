import React from "react";

import "./__styles__/Icon.scss";

export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 43 43"
      version="1.1"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeMiterlimit="2"
    >
      <g transform="matrix(1,0,0,1,-175,0)">
        <g id="oh-no" transform="matrix(5.16003,0,0,5.16003,16.7588,5.16003)">
          <rect x="30.667" y="-1" width="8.333" height="8.333" fill="none" />
          <g transform="matrix(0.260415,0,0,0.260415,30.6667,-1)">
            <path
              d="M16,32C24.837,32 32,24.837 32,16C32,7.163 24.837,0 16,0C7.163,0 0,7.163 0,16C0,24.837 7.163,32 16,32ZM16,30C8.268,30 2,23.732 2,16C2,8.268 8.268,2 16,2C23.732,2 30,8.268 30,16C30,23.732 23.732,30 16,30Z"
              fill="rgb(33,33,33)"
            />
          </g>
          <g transform="matrix(0.260415,0,0,0.260415,30.6667,-1)">
            <path d="M15,6L15,20L17,20L17,6L15,6Z" fill="rgb(33,33,33)" />
          </g>
          <g transform="matrix(0.260415,0,0,0.260415,30.6667,-1)">
            <path
              d="M16,26C16.828,26 17.5,25.328 17.5,24.5C17.5,23.672 16.828,23 16,23C15.172,23 14.5,23.672 14.5,24.5C14.5,25.328 15.172,26 16,26Z"
              fill="rgb(33,33,33)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
