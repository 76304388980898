import React from "react";

import "./__styles__/Icon.scss";

const Gpkg = () => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 1V7H19" stroke="#2A70FF" strokeMiterlimit="10" />
    <path
      d="M19 20.25V7L13 1H1V10V20.25"
      stroke="#2A70FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M7 11.0667V16.9333C7 17.5467 8.62667 18 10.4667 18C12.3067 18 13.9333 17.5467 13.9333 16.9333V11.0667"
      stroke="#2A70FF"
      strokeMiterlimit="10"
    />
    <path
      d="M13.9333 11.0667C13.9333 11.68 12.3067 12.1333 10.4667 12.1333C8.62667 12.1333 7 11.68 7 11.0667C7 10.4533 8.62667 10 10.4667 10C12.3067 10 13.9333 10.4533 13.9333 11.0667Z"
      stroke="#2A70FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M13.9333 14C13.9333 14.6133 12.3067 15.0667 10.4667 15.0667C8.62667 15.0667 7 14.6133 7 14"
      stroke="#2A70FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M1 20.5V23.5H19V20.5"
      stroke="#2A70FF"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default Gpkg;
