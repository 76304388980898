import React from "react";
import { WatchQueryFetchPolicy } from "@apollo/client";
import _ from "lodash";
import sortBy from "lodash/sortBy";
import Collapsible from "react-collapsible";
import { warningSeveritySortOrder } from "common-client/utils/createAndEditPropertyWarnings";

import {
  PropertyWarning,
  useGetPropertyWarningsQuery,
} from "../../generated/graphql";
import { EmptyState } from "../Common/EmptyState";
import { Icon } from "../Common/Icons/LucideIcons";
import { Button } from "../Common/Button";
import { LayerContext } from "../Maps/layers";
import { useUpdateWarningsModal } from "./PropertyOverview/UpdateWarningsModal";
import { AuthContext } from "../Authorization/AuthContext";
import { EditableWarning } from "../Warnings/WarningList";
import Divider from "../Common/Divider";
import { Body } from "../Common/Typography";
import {
  DescriptionRow,
  TitleWrapper,
  WarningInfo,
  WarningRow,
} from "./__styles__/Warnings";

export const SEVERITY_TO_COLOR = {
  low: "contentSecondary",
  medium: "contentAttention",
  high: "contentCritical",
} as const;

type WarningProps = NonNullable<
  Pick<PropertyWarning, "severity" | "title" | "message">
> & {
  showDivider?: boolean;
};

export const Warning = ({
  severity,
  title,
  message,
  showDivider = true,
}: WarningProps) => {
  const [show, toggleShow] = React.useState(false);

  const trigger = (
    <WarningRow>
      <WarningInfo>
        <Icon
          iconName="alert-circle"
          color={SEVERITY_TO_COLOR[severity]}
          size={16}
        />
        <Body size="default" type="emphasis">
          {title}
        </Body>
      </WarningInfo>
      <Icon
        iconName={show ? "chevron-up" : "chevron-down"}
        color="contentSecondary"
        size={16}
      />
    </WarningRow>
  );

  return (
    <>
      <Collapsible
        trigger={trigger}
        transitionTime={100}
        onOpen={() => toggleShow(true)}
        onClose={() => toggleShow(false)}
      >
        <DescriptionRow>
          <Body size="default" type="regular" color="contentSecondary">
            {message}
          </Body>
        </DescriptionRow>
      </Collapsible>
      {showDivider && <Divider extension={16} />}
    </>
  );
};

interface WarningsContainerProps {
  address?: Maybe<string>;
  propertyId: Maybe<string>;
  publicOnly: boolean;
  canEditWarnings: boolean;
  loading: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}
export default ({
  address,
  propertyId,
  publicOnly,
  canEditWarnings,
  loading: loadingPropertyId,
  fetchPolicy = "cache-and-network",
}: WarningsContainerProps) => {
  if (!propertyId && !loadingPropertyId)
    return <em>There was a problem getting warnings for this address</em>;

  const { updateMap } = React.useContext(LayerContext);
  const { account } = React.useContext(AuthContext);

  const variables = { propertyId: propertyId!, publicOnly };
  const { data, loading, refetch, error } = useGetPropertyWarningsQuery({
    fetchPolicy,
    variables,
    skip: !propertyId,
  });

  const warnings: Array<EditableWarning> =
    account?.accountPropertyWarningDefinitions
      .filter(warning => warning.isActive)
      .map(accountPropertyWarningDefinition => {
        return {
          ...accountPropertyWarningDefinition,
          applies:
            data?.getProperty?.warnings.find(
              warning =>
                warning.accountPropertyWarningDefinitionId ===
                accountPropertyWarningDefinition.id
            )?.applies ?? false,
        };
      }) ?? [];

  const [showUpdateWarningsModal, hideUpdateWarningsModal] =
    useUpdateWarningsModal({
      onCancel: () => {
        hideUpdateWarningsModal();
      },
      onSubmit: async () => {
        updateMap();
        await refetch();
      },
      address,
      propertyId,
      warnings,
    });

  if (loading || loadingPropertyId) return <em>loading...</em>;
  if (error)
    return <em>There was a problem getting warnings for this address</em>;

  const applicableWarnings = sortBy(
    data?.getProperty?.warnings.filter(propertyWarning =>
      warnings.find(
        accountPropertyWarningDefinition =>
          propertyWarning.accountPropertyWarningDefinitionId ===
          accountPropertyWarningDefinition.id
      )
    ),
    ({ severity }) => warningSeveritySortOrder.indexOf(severity)
  ).filter(warning => warning.applies);

  return (
    <>
      <TitleWrapper>
        <Body size="large" type="emphasis">
          Warnings
        </Body>

        {canEditWarnings && propertyId && (
          <Button
            styleVariant="outlineLight"
            data-testid="editWarnings"
            onClick={() => showUpdateWarningsModal()}
            size="small"
            style={{ minWidth: "unset", whiteSpace: "nowrap" }}
          >
            Edit warnings
          </Button>
        )}
      </TitleWrapper>
      {applicableWarnings.length > 0 ? (
        applicableWarnings.map((warning, idx) => (
          <Warning
            key={warning.title}
            {...warning}
            showDivider={idx !== applicableWarnings.length - 1}
          />
        ))
      ) : (
        <>
          <EmptyState
            message="There are no warnings for this property"
            hasPaddingBottom
          />
        </>
      )}
    </>
  );
};
