import { fieldWrapperId, scrollInputIntoView } from "../../../Forms/utils";
import { AuthContext } from "../../../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import React, { useContext, useState } from "react";
import { WidgetProps } from "@rjsf/utils";
import { FormFieldsContext } from ".";

import Collapsible from "react-collapsible";
import TextInput from "../../../../Inputs/Text";
import IconText from "../../../../Common/IconText";
import { Icons, ICON_COLORS } from "../../../../Common/Icons";
import DropdownMenu, { ActionsProps } from "../../../../Inputs/DropdownMenu";

import {
  ActionButtonWrapper,
  Field,
  FormField,
  FormFieldWrapper,
  Header,
} from "../../../Forms/__styles__/Form";
import { FieldWrapper } from "./__styles__/FormFields";
import { Radio } from "../../../../Inputs";

export type FormFieldProps = Pick<
  WidgetProps,
  "label" | "value" | "onChange" | "options"
> & { type: "text" | "boolean" };

export default ({ label, value, options, type, onChange }: FormFieldProps) => {
  const { fieldsOpen, setFieldsOpen } = useContext(FormFieldsContext);
  const { authorized } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);

  const canEditGeneratedDocument = authorized({
    resource: RESOURCE_NAME.GENERATED_DOCUMENT,
    permission: "update",
  });

  const onOpen = () => {
    if (!canEditGeneratedDocument) {
      return;
    }

    setIsEditing(true);
    setFieldsOpen({ ...fieldsOpen, [label]: true });
  };

  const onClose = () => {
    setIsEditing(false);
    setFieldsOpen({ ...fieldsOpen, [label]: false });
  };

  const displayValue = () => {
    if (type === "text") {
      return value;
    } else {
      return value ? "Yes" : "No";
    }
  };

  const isOpen = !!fieldsOpen[label];

  const collapsibleField = (
    <Header expanded={isOpen} onClick={onOpen}>
      <Field>{label}</Field>
      <Field>{displayValue()}</Field>
    </Header>
  );

  const editFieldLabel = (
    <IconText
      icon={Icons.PENCIL}
      color={!canEditGeneratedDocument ? ICON_COLORS.GREY_3 : ICON_COLORS.BLACK}
    >
      Edit field value
    </IconText>
  );

  const actions: Array<ActionsProps> = [
    {
      label: editFieldLabel,
      disabled: !canEditGeneratedDocument,
      onClick: onOpen,
    },
  ];
  const radioOptions = options.enumOptions?.map(option => {
    return {
      text: option.label,
      value: option.value,
    };
  });

  const fieldMap = {
    text: (
      <TextInput
        size="smaller"
        name={label}
        value={value}
        placeholder={"Enter correct value..."}
        onChange={onChange}
      />
    ),
    boolean: (
      <Radio
        name={label}
        onChange={onChange}
        value={value}
        options={radioOptions ?? []}
      />
    ),
  };

  return (
    <FormFieldWrapper>
      <FormField>
        <FieldWrapper
          id={fieldWrapperId(label)}
          style="default"
          expanded={isOpen}
          data-testid="collapsed-field"
        >
          <Collapsible
            trigger={collapsibleField}
            transitionTime={50}
            open={isOpen}
            onOpen={() => scrollInputIntoView(fieldWrapperId(label))}
            onTriggerOpening={onOpen}
            onTriggerClosing={onClose}
            overflowWhenOpen="visible"
          >
            {isEditing && fieldMap[type]}
          </Collapsible>
        </FieldWrapper>
      </FormField>
      <ActionButtonWrapper>
        <DropdownMenu actions={actions} scrollIntoView={true} />
      </ActionButtonWrapper>
    </FormFieldWrapper>
  );
};
