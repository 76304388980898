import React from "react";
import {
  entries,
  flatten,
  groupBy,
  partition,
  sortBy,
  startCase,
} from "lodash";

import {
  AccountPropertyWarningDefinition,
  PropertyWarningGroup,
} from "../../generated/graphql";
import { ExpandableList } from "../Common/ExpandableList";
import { orderPropertyWarningDefinitions } from "common-client/utils/warnings";
import { Icon } from "../Common/Icons/LucideIcons";
import { SEVERITY_TO_COLOR } from "../AddressPanel/Warnings";
import { spacing } from "../../stitches.config";

export interface EditableWarning extends AccountPropertyWarningDefinition {
  applies?: boolean;
}

export interface WarningListProps {
  warnings: EditableWarning[];
  warningRightSection: (
    warning: EditableWarning,
    key: string
  ) => React.ReactNode;
}
const WarningList = ({ warnings, warningRightSection }: WarningListProps) => {
  const sortedWarnings = sortBy(warnings, "group");
  const warningGroups = groupBy(
    flatten(
      partition(
        sortedWarnings,
        warning => warning.group !== PropertyWarningGroup.CUSTOM_WARNING
      )
    ),
    "group"
  );

  return (
    <>
      {entries(warningGroups).map(([groupTitle, warnings], index) => {
        const list = orderPropertyWarningDefinitions(warnings).map(warning => {
          const title = (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: spacing.s.toString(),
              }}
            >
              <Icon
                iconName="alert-circle"
                color={SEVERITY_TO_COLOR[warning.severity]}
                size={16}
              />
              {warning.title}
            </div>
          );

          return {
            title,
            expandableContent: warning.message,
            rightContent: warningRightSection(warning, warning.id),
          };
        });

        return (
          <ExpandableList
            title={`${startCase(groupTitle)} warnings`}
            list={list}
            key={index}
          />
        );
      })}
    </>
  );
};

export default WarningList;
