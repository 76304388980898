import React, { ReactNode } from "react";
import { gql } from "@apollo/client";

import Logo from "../images/logo-white.svg";
import { useGetVersionQuery } from "../generated/graphql";
import { AppType } from "./AppScaffold";

import "./__styles__/CompatibilityCheck.scss";

// Time in ms to wait between checking for version compatibility
const pollInterval = 5 * 60 * 1000;

export const GET_VERSION = gql`
  query GetVersion {
    app {
      majorVersion
    }
  }
`;

// returns true if the browser is compatible, otherwise false
function browserIncompatibility() {
  const agent = window.navigator.userAgent;

  // All IE versions including IE11
  // See: https://stackoverflow.com/questions/31757852/how-can-i-detect-internet-explorer-ie-and-microsoft-edge-using-javascript
  return agent.indexOf("MSIE") !== -1 || agent.indexOf("rv:11.0") !== -1;
}

function useVersionIncompatibility() {
  const { data, loading } = useGetVersionQuery({ pollInterval });

  if (!data || loading) {
    return { incompatible: null, loading: true };
  }

  const clientMajorVersion = window.env.APP_MAJOR_VERSION;
  const serverMajorVersion = data.app.majorVersion;

  return {
    incompatible: clientMajorVersion != serverMajorVersion,
    loading: false,
  };
}

const Layout = ({ children, app }: { children: ReactNode; app: AppType }) => (
  <div styleName="container">
    {app !== "guest" && <img src={Logo} alt="Forerunner logo" />}
    {children}
  </div>
);

const BrowserIncompatibility = ({ app }: { app: AppType }) => (
  <Layout app={app}>
    <h1>We’re sorry, your browser is not compatible.</h1>
    <p>
      Try using another browser such as{" "}
      <a href="https://www.google.com/chrome/index.html">Google Chrome</a>.
    </p>
    <p>
      If you have any questions, feel free to reach out to us at{" "}
      <a href="mailto:support@withforerunner.com">support@withforerunner.com</a>
      .
    </p>
  </Layout>
);

const VersionIncompatibility = ({ app }: { app: AppType }) => (
  <Layout app={app}>
    <h1>
      A new version {app === "guest" ? "" : "of Forerunner"} is available.
    </h1>
    <p>
      Please refresh this page using your browser or{" "}
      <a href="#" onClick={() => location.reload()}>
        click here
      </a>{" "}
      to load the latest version.
    </p>
  </Layout>
);

export default function CompatibilityCheck({
  children,
  app,
}: {
  children: JSX.Element;
  app: AppType;
}) {
  const { incompatible, loading: loadingVersion } = useVersionIncompatibility();

  if (loadingVersion) {
    return null;
  }

  if (browserIncompatibility()) return <BrowserIncompatibility app={app} />;
  if (incompatible) return <VersionIncompatibility app={app} />;

  return children;
}
