import React, { useEffect, useRef } from "react";
import { get, isNil } from "lodash";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import { FieldProps } from "@rjsf/utils";
import { FileRejection } from "react-dropzone";
import { ErrorCode } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

import { SubmissionFormContext } from "../Form";
import Icon, { ICON_COLORS, Icons } from "../../Common/Icons";
import { AuthContext } from "../../Authorization/AuthContext";
import { buildLink } from "common/routing";
import { MAX_SIZE_PER_MIME_TYPE_IN_MB } from "common/utils/documentUploads";
import {
  CertificateUploadStatus,
  CancelationReason,
  useGeneratePresignedUrlMutation,
  useValidateScratchDocumentUploadMutation,
} from "../../../generated/graphql";
import Wrapper from "../../Inputs/Wrapper";
import { CANCELATION_REASON_TEXT } from "../../DocumentUploads/Settings";
import { DropzoneComponent } from "../../FileUploads";
import {
  BrowseStyling,
  DropzonePositioner,
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableData,
  IconPositioner,
  AdditionalInfo,
  NoDocuments,
} from "./__styles__/DocumentUploadField";
import {
  Block,
  Info,
} from "../../DocumentUploads/__styles__/UploadDocumentsForm";
import { EmptyFileContainer } from "../../FileUploads/__styles__/FileUploads";
import { Input } from "../__styles__/Inputs";
import { MIME_TYPE } from "common/constants";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { getTooltip } from "../utils";

type DocumentUploadStatus =
  | CertificateUploadStatus
  | "valid"
  | "invalid"
  | "loading";

export interface DocumentUpload {
  id: Maybe<string>;
  scratchId?: string;
  originalFilename?: string;
  mimetype?: MIME_TYPE;
  status?: "valid" | "invalid" | "loading";
}

const getIconProps = ({
  documentStatus,
  formDataValue,
}: {
  documentStatus?: Maybe<DocumentUploadStatus>;
  formDataValue: DocumentUpload;
}) => {
  if (isNil(formDataValue.id)) {
    switch (documentStatus) {
      case "valid":
        return {
          icon: Icons.GREEN_CHECK,
          color: ICON_COLORS.GREEN,
        };
      case "invalid":
        return {
          icon: Icons.RED_X,
          color: ICON_COLORS.RED,
        };
      case "loading":
        return {
          icon: Icons.SMALL_LOADING,
          color: ICON_COLORS.GREY_4,
        };
      default:
        break;
    }
  }

  return documentStatus === CertificateUploadStatus.CANCELED ||
    documentStatus === CertificateUploadStatus.PROCESSING
    ? {
        icon: Icons.INFORMATION_CIRCLE,
        color: ICON_COLORS.YELLOW,
      }
    : {
        icon: Icons.GREEN_CHECK,
        color: ICON_COLORS.GREEN,
      };
};
const FileNameCell = ({
  fileName,
  status,
  cancelationReason,
  documentUploadId,
}: {
  fileName: string;
  status?: DocumentUploadStatus;
  cancelationReason?: Maybe<CancelationReason>;
  documentUploadId?: Maybe<string>;
}) => {
  const isProcessing = status === CertificateUploadStatus.PROCESSING;
  const reasonText = CANCELATION_REASON_TEXT[cancelationReason ?? "default"];

  const formattedFileName =
    documentUploadId && !isProcessing ? (
      <Link
        to={{
          pathname: buildLink("documentUploadDetail", {
            id: documentUploadId,
          }),
        }}
        target="_blank"
      >
        {fileName}
      </Link>
    ) : (
      <span>{fileName}</span>
    );

  return (
    <TableData>
      <>
        <div>{formattedFileName}</div>
        {(status === CertificateUploadStatus.CANCELED ||
          status === CertificateUploadStatus.PROCESSING) && (
          <AdditionalInfo>
            {status === CertificateUploadStatus.CANCELED
              ? reasonText
              : "File processing..."}
          </AdditionalInfo>
        )}
      </>
    </TableData>
  );
};

const ExistingDocuments = ({
  documentUploadValues,
  setDocumentUploadValues,
  onChange,
  disabled,
}: {
  documentUploadValues: Array<DocumentUpload>;
  setDocumentUploadValues: (args: Array<DocumentUpload> | undefined) => void;
  onChange: any;
  disabled: boolean;
}) => {
  const { documentUploads } = React.useContext(SubmissionFormContext);

  return (
    <>
      <Table css={{ marginTop: "15px" }}>
        <TableHeader>
          <TableRow>
            <TableHeaderCell></TableHeaderCell>
            <TableHeaderCell alignLeft={true}>File name</TableHeaderCell>
            {!disabled && <TableHeaderCell>Remove</TableHeaderCell>}
          </TableRow>
        </TableHeader>
        <TableBody>
          {documentUploadValues.map((formDataValue, index) => {
            const existingDocumentUpload = documentUploads?.find(
              documentUpload => formDataValue.id === documentUpload.id
            );

            const status =
              existingDocumentUpload?.certificateUpload?.status ??
              formDataValue.status;
            const iconProps = getIconProps({
              documentStatus: status,
              formDataValue,
            });

            return (
              <TableRow key={index}>
                <TableData css={{ width: "35px" }}>
                  <IconPositioner css={{ paddingTop: "3px" }}>
                    <Icon {...iconProps} />
                  </IconPositioner>
                </TableData>
                <FileNameCell
                  status={status}
                  cancelationReason={
                    existingDocumentUpload?.certificateUpload?.cancelationReason
                  }
                  fileName={
                    (existingDocumentUpload?.originalFilename ??
                      formDataValue.originalFilename)!
                  }
                  documentUploadId={existingDocumentUpload?.id}
                />
                <TableData
                  css={{
                    height: "16px",
                    width: "16px",
                    textAlign: "right",
                  }}
                >
                  {status !== CertificateUploadStatus.PROCESSING &&
                    !disabled && (
                      <IconPositioner
                        css={{ paddingTop: "2px", marginLeft: "auto" }}
                      >
                        <Icon
                          data-testid="remove-document"
                          icon={Icons.CIRCLE_X}
                          color={ICON_COLORS.GREY_4}
                          onClick={() => {
                            let modifiedUploads:
                              | Array<DocumentUpload>
                              | undefined = cloneDeep(documentUploadValues);
                            modifiedUploads.splice(index, 1);

                            if (modifiedUploads.length === 0) {
                              modifiedUploads = undefined;
                            }

                            setDocumentUploadValues(modifiedUploads);
                            onChange(modifiedUploads);
                          }}
                        />
                      </IconPositioner>
                    )}
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

const Information = (props: FieldProps) => {
  return (
    <Info>
      <Block icon={"limit"}>{props.uiSchema!["ui:fileTypeDescription"]}</Block>
      <Block icon={"pages"}>
        {props.uiSchema!["ui:fileQuantityDescription"]}
      </Block>
      <Block icon={"notify"}>{props.uiSchema!["ui:fileSizeDescription"]}</Block>
    </Info>
  );
};

export const DocumentUploadInput = (props: FieldProps) => {
  const { account } = React.useContext(AuthContext);
  const { addErrorToast } = useStatusToasts();
  useEffect(() => {
    const strippedFormData =
      props.formData?.filter((obj: Object) => Object.keys(obj).length !== 0) ??
      [];
    props.onChange(strippedFormData);
  }, [props.formData]);

  let existingFormData = props.formData?.filter(
    (value: DocumentUpload | Object) => Object.keys(value).length !== 0
  );

  if (!existingFormData?.length) {
    existingFormData = undefined;
  }

  const [documentUploadValues, setDocumentUploadValues] = React.useState<
    Array<DocumentUpload & { clientKey?: string }> | undefined
  >(existingFormData);
  const documentUploadValuesRef = useRef(documentUploadValues);

  useEffect(() => {
    documentUploadValuesRef.current = documentUploadValues;
  }, [documentUploadValues]);

  const [generatePresignedUrl] = useGeneratePresignedUrlMutation({});
  const [validateScratchDocumentUpload] =
    useValidateScratchDocumentUploadMutation();

  const DropzoneContent = () => (
    <>
      <h5>Drop your {props.uiSchema!["ui:documentTypeName"]} here</h5>
      <p>
        or <BrowseStyling>browse</BrowseStyling> to choose an{" "}
        {props.uiSchema!["ui:documentTypeAbbreviation"]}
      </p>
    </>
  );

  const accountDocumentTypeId = get(
    props.schema,
    "items.properties.accountDocumentTypeId.enum",
    []
  )[0];
  const accountDocumentType = account?.accountDocumentTypes.find(
    documentType => accountDocumentTypeId === documentType.id
  );

  const maxFiles = props.schema.maxItems;

  async function onDrop(files: Array<File & { clientKey?: string }>) {
    if (
      maxFiles &&
      files.length + (documentUploadValues?.length ?? 0) > maxFiles
    ) {
      const fileText = maxFiles === 1 ? "file" : "files";
      addErrorToast(`Only ${maxFiles} ${fileText} can be uploaded at a time.`);
    }

    files.forEach(file => {
      file.clientKey = uuidv4();
    });

    const newFormValue = [
      ...(documentUploadValues ?? []),
      ...files.map(file => ({
        ...file,
        id: null,
        accountDocumentTypeId,
        originalFilename: file.name,
        mimetype: file.type as MIME_TYPE,
        status: "loading" as const,
      })),
    ];

    setDocumentUploadValues(newFormValue);
    props.onChange(newFormValue);

    for (const file of files) {
      await generatePresignedUrl({
        variables: {
          data: {
            accountDocumentTypeId,
            clientMimeType: file.type,
            clientKey: file.clientKey!,
          },
        },
        onCompleted: async data => {
          const result = await fetch(data.generatePresignedUrl.presignedUrl, {
            method: "PUT",
            body: file,
            headers: { "Content-Type": file.type },
          });

          if (!result.ok) {
            throw new Error(`Received a ${result.status} when uploading file`);
          }
          await validateScratchDocumentUpload({
            variables: {
              scratchId: data.generatePresignedUrl.scratchId,
              accountDocumentTypeId,
              mimetype: file.type as MIME_TYPE,
            },
            onCompleted: () => {
              const fileToUpdate = documentUploadValuesRef.current?.find(
                f => f.clientKey === data.generatePresignedUrl.clientKey
              );
              if (fileToUpdate) {
                fileToUpdate.status = "valid";
                fileToUpdate.scratchId = data.generatePresignedUrl.scratchId;
                setDocumentUploadValues(documentUploadValuesRef.current);
                props.onChange(documentUploadValuesRef.current);
              }
            },
            onError: () => {
              const fileToUpdate = documentUploadValuesRef.current?.find(
                f => f.clientKey === data.generatePresignedUrl.clientKey
              );
              if (fileToUpdate) {
                fileToUpdate.status = "invalid";
                setDocumentUploadValues(documentUploadValuesRef.current);
                props.onChange(documentUploadValuesRef.current);
              }
            },
          });
        },
        onError: () => {
          const fileToUpdate = documentUploadValuesRef.current?.find(
            f => f.clientKey === file.clientKey
          );
          if (fileToUpdate) {
            fileToUpdate.status = "invalid";
            setDocumentUploadValues(documentUploadValuesRef.current);
            props.onChange(documentUploadValuesRef.current);
          }
        },
      });
    }
  }

  const onDropRejected = (fileWithErrors: Array<FileRejection>) => {
    fileWithErrors.forEach(fileWithError => {
      const errorCodesForFile = fileWithError.errors.map(error => error.code);
      if (errorCodesForFile.includes(ErrorCode.FileTooSmall)) {
        addErrorToast(`${fileWithError.file.name} is an empty file`);
      }
      if (errorCodesForFile.includes(ErrorCode.FileTooLarge)) {
        addErrorToast(`${fileWithError.file.name} is too large`);
      }
      if (errorCodesForFile.includes(ErrorCode.FileInvalidType)) {
        addErrorToast(`${fileWithError.file.name} is the wrong file type`);
      }
    });

    if (
      fileWithErrors.some(file =>
        file.errors.some(error => error.code.includes(ErrorCode.TooManyFiles))
      )
    ) {
      const fileText = maxFiles === 1 ? "file" : "files";
      addErrorToast(`Only ${maxFiles} ${fileText} can be uploaded at a time.`);
    }
  };

  const shouldShowDropzone =
    !props.disabled &&
    maxFiles &&
    (documentUploadValues?.length ?? 0) < maxFiles;

  const tooltip = getTooltip(props);

  return (
    <Input id={props.idSchema.$id}>
      <Wrapper
        name={props.name}
        required={props.required && !props.disabled}
        label={props.schema.title}
        error={props.rawErrors?.join(", ")}
        tooltip={tooltip}
      >
        <div style={{ width: "100%" }}>
          {shouldShowDropzone && (
            <EmptyFileContainer>
              <DropzonePositioner>
                <DropzoneComponent
                  border={false}
                  onDrop={onDrop}
                  onDropRejected={onDropRejected}
                  maxSizePerMimeType={MAX_SIZE_PER_MIME_TYPE_IN_MB}
                  maxFiles={maxFiles}
                  allowedMimeTypes={
                    accountDocumentType?.allowedMimeTypes ?? [MIME_TYPE.PDF]
                  }
                  content={DropzoneContent}
                />
              </DropzonePositioner>
              {!documentUploadValues?.length && <Information {...props} />}
            </EmptyFileContainer>
          )}

          {isNil(documentUploadValues) && props.disabled && (
            <NoDocuments>{props.uiSchema!["ui:emptyFilesMessage"]}</NoDocuments>
          )}

          {!isNil(documentUploadValues) && (
            <ExistingDocuments
              onChange={props.onChange}
              documentUploadValues={documentUploadValues}
              setDocumentUploadValues={setDocumentUploadValues}
              disabled={props.disabled}
            />
          )}
        </div>
      </Wrapper>
    </Input>
  );
};
