import React from "react";

import "./__styles__/Icon.scss";

const Loading = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64px"
      height="64px"
      viewBox="0 0 100 100"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#c4c6ca"
        strokeWidth="8"
        r="35"
      />
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#212121"
        strokeWidth="8"
        r="35"
        strokeDasharray="50 169"
        strokeLinecap="round"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1.5s"
          from="0 50 50"
          to="360 50 50"
        />
      </circle>
    </svg>
  );
};

export default Loading;
