import React from "react";
import { TabLink } from "./__styles__/TabItem";

export default <T extends string>({
  value,
  active,
  setTab,
  children,
  color,
}: {
  value: T;
  active: boolean;
  setTab: (value: T) => void;
  children: React.ReactNode;
  color: "blue" | "grey";
}) => (
  <TabLink
    onClick={() => setTab(value)}
    active={active}
    color={color}
    aria-selected={active}
  >
    {children}
  </TabLink>
);
