import React, { useContext, useEffect, useState } from "react";
import Loading from "../../Common/Icons/SmallLoading";
import { Button } from "../../Common/Button";
import { FIRM, LayerContext } from "../layers";
import {
  useGetApproximateBfeLazyQuery,
  useUpdatePropertyFirmMutation,
} from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { Body } from "../../Common/Typography";
import { Tag } from "../../Common/Tag";
import { CrossSectionsWrapper } from "./__styles__/approximateBfeTool";
import {
  ActionButtons,
  Calculation,
  LoadingWrapper,
} from "../../Common/__styles__/BottomBanner";
import { BottomBanner } from "../../Common/BottomBanner";
import { StyledLink } from "../../Common/__styles__/Typography";
import { colors } from "common-client/utils/styling";

export const ApproximateBfeTool = ({
  openEditFIRM,
  propertyId,
  mapLayerFirm,
  refetchQueries = [
    "propertyWarnings",
    "getBuildingAttributes",
    "getFIRMInfo",
    "getFloodInfo",
    "getOrCreateProperty",
  ],
}: {
  openEditFIRM: () => void;
  propertyId: string;
  mapLayerFirm: NonNullable<FIRM>;
  refetchQueries?: string[];
}) => {
  const { addErrorToast, addSuccessToast } = useStatusToasts();
  const { approximateBfeToolDispatch, approximateBfeToolState } =
    useContext(LayerContext);
  const [approximateBfe, setApproximateBfe] = useState<Maybe<string>>(null);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [getApproximateBfe, { loading }] = useGetApproximateBfeLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [saveApproximateBfe, { loading: saving }] =
    useUpdatePropertyFirmMutation({
      refetchQueries,
      onCompleted: () => {
        approximateBfeToolDispatch?.({
          type: "setMode",
          data: { mode: "off" },
        });
        addSuccessToast(`Base Flood Elevation successfully updated`);
      },
      onError: () => {
        addErrorToast(
          `There was an issue updating the Base Flood Elevation. Please try again. If the problem persists, please email us at support@withforerunner.com`
        );
      },
    });

  useEffect(() => {
    const fetchApproximateBfe = async () => {
      if (approximateBfeToolState?.crossSections.length !== 2) {
        return;
      }
      await getApproximateBfe({
        variables: {
          data: {
            crossSectionIds: approximateBfeToolState.crossSections.map(
              crossSection => crossSection.id
            ),
            propertyId,
            firmId: mapLayerFirm.id,
          },
        },
        onCompleted: data => {
          if (data.approximateBfe) {
            setApproximateBfe(data.approximateBfe.value);
            setErrorMessage(null);
            approximateBfeToolDispatch?.({
              type: "setDisableMapInteractions",
              data: true,
            });
          }
        },
        onError: error => {
          setErrorMessage(error.message || "error");
        },
      });
    };
    void fetchApproximateBfe();
  }, [approximateBfeToolState?.crossSections.length]);

  useEffect(() => {
    if (loading) {
      approximateBfeToolDispatch?.({
        type: "setDisableMapInteractions",
        data: true,
      });
    }
  }, [loading]);

  const onClose = () => {
    approximateBfeToolDispatch?.({
      type: "setMode",
      data: { mode: "off" },
    });
  };

  const EnterManuallyButton = () => {
    const onClick = () => {
      approximateBfeToolDispatch?.({
        type: "setMode",
        data: { mode: "off" },
      });
      openEditFIRM();
    };

    return (
      <Body
        as="div"
        type="emphasis"
        size="default"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledLink
          style={{
            color: colors.contentInfoDark.toString(),
            cursor: "pointer",
          }}
          onClick={onClick}
          as="div"
        >
          Enter manually
        </StyledLink>
      </Body>
    );
  };

  const onSave = async ({ value }: { value: string }) => {
    await saveApproximateBfe({
      variables: {
        propertyId,
        firmId: mapLayerFirm.id,
        data: {
          stringStaticBFE: value,
          isApproximateBfe: true,
        },
      },
    });
  };

  const SaveButton = ({ value }: { value: string }) => (
    <Button
      styleVariant="outlineDark"
      size="small"
      onClick={() => onSave({ value })}
      tabIndex={-1}
      loading={saving}
      disabled={saving}
    >
      Save value
    </Button>
  );

  const ResetButton = () => (
    <Button
      styleVariant="outlineDark"
      size="small"
      onClick={() => {
        approximateBfeToolDispatch?.({ type: "resetCrossSections" });
        setApproximateBfe(null);
        setErrorMessage(null);
      }}
      tabIndex={-1}
    >
      Reset calculations
    </Button>
  );

  if (
    !mapLayerFirm.components.crossSections.hasGeometries ||
    !mapLayerFirm.components.crossSections.hasElevations ||
    !mapLayerFirm.components.profileBaselines.hasGeometries
  ) {
    return (
      <BottomBanner
        title={"No map data"}
        style="alert"
        onClose={onClose}
        subtitle={
          "To calculate the approximate BFE for this property, ensure your selected FIRM is equipped with cross sections and a profile baseline."
        }
        panelOpen={true}
      >
        <ActionButtons>
          <EnterManuallyButton />
        </ActionButtons>
      </BottomBanner>
    );
  }

  if (errorMessage) {
    const missingProfileBaseline = errorMessage.match(
      /continous profile baseline/
    );

    let title = missingProfileBaseline
      ? "No baseline data"
      : "Error calculating BFE";
    let subtitle = missingProfileBaseline
      ? "To calculate the approximate BFE for this property, select two cross sections with a continuous profile baseline running through them."
      : "Unable to calculate an approximate BFE for this property. Please try again.";

    return (
      <BottomBanner
        title={title}
        style={missingProfileBaseline ? "warning" : "alert"}
        onClose={onClose}
        subtitle={subtitle}
        panelOpen={true}
      >
        <ActionButtons>
          <ResetButton />
          <EnterManuallyButton />
        </ActionButtons>
      </BottomBanner>
    );
  }

  return (
    <BottomBanner
      title={"Base Flood Elevation"}
      onClose={onClose}
      subtitle={
        "To calculate the approximate BFE for this property, select two cross sections with a continuous profile baseline running through them."
      }
      panelOpen={true}
    >
      <>
        {!!approximateBfeToolState?.crossSections.length && !approximateBfe && (
          <CrossSectionsWrapper>
            {approximateBfeToolState.crossSections.map(crossSection => {
              const value =
                crossSection.letter ||
                crossSection.regulatoryWaterSurfaceElevation;
              return (
                <Tag
                  styleVariant="neutralDark"
                  key={crossSection.id}
                  onClose={() => {
                    approximateBfeToolDispatch?.({
                      type: "toggleCrossSection",
                      data: { crossSection },
                    });
                  }}
                  disabled={loading}
                >
                  {`Cross Section ${value}`}
                </Tag>
              );
            })}
          </CrossSectionsWrapper>
        )}
        {(loading || approximateBfe) && (
          <Calculation data-testid="calculation">
            <Body type="emphasis" size="default" color="contentPrimaryDark">
              Approximate BFE:
              {loading ? (
                <LoadingWrapper>
                  <Loading data-testid="loading-spinner" />
                </LoadingWrapper>
              ) : (
                ` ${approximateBfe}'`
              )}
            </Body>
          </Calculation>
        )}
      </>
      <ActionButtons>
        {approximateBfe && <SaveButton value={approximateBfe} />}
        <EnterManuallyButton />
      </ActionButtons>
    </BottomBanner>
  );
};
