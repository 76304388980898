import React from "react";

import { colors } from "../../stitches.config";

import {
  TextSize,
  Title as StyledTitle,
  Body as StyledBody,
} from "./__styles__/Typography";

type AllowedStyleOverrides = Omit<
  React.CSSProperties,
  "fontWeight" | "fontSize" | "lineHeight" | "color"
>;

const titleSizeToHtmlTag = {
  small: "h3",
  default: "h2",
  large: "h1",
} as const;

export type TitleType = "semiBold" | "regular";

export const Title = ({
  size,
  type,
  color = "contentPrimary",
  style,
  children,
}: {
  size: TextSize;
  type: TitleType;
  color?: keyof typeof colors;
  style?: AllowedStyleOverrides;
  children: React.ReactNode;
}) => {
  const css = { color: colors[color].toString() };

  return (
    <StyledTitle
      size={size}
      type={type}
      color={color}
      css={css}
      style={style}
      as={titleSizeToHtmlTag[size]}
    >
      {children}
    </StyledTitle>
  );
};

export type BodyType = "emphasis" | "regular" | "underline";

export const Body = ({
  size,
  type,
  color = "contentPrimary",
  style,
  as = "p",
  children,
}: {
  size: TextSize;
  type: BodyType;
  color?: keyof typeof colors;
  style?: AllowedStyleOverrides;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  children: React.ReactNode;
}) => {
  const css = { color: colors[color].toString() };

  return (
    <StyledBody
      tabIndex={0}
      size={size}
      type={type}
      css={css}
      style={style}
      as={as}
    >
      {children}
    </StyledBody>
  );
};
