import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import { ModalProvider } from "react-modal-hook";

import { AppScaffold } from "./components/AppScaffold";
import { setupEntrypoint } from "./utils/entrypoint";

import "./styles/base.scss";
import { MainRoutes } from "./components/Guest/MainRoutes";
import { StagingBanner } from "./components/AdminApp/AccountsApp/StagingBanner";

const { history } = setupEntrypoint();

ReactDOM.render(
  <AppScaffold history={history} app="guest">
    <ModalProvider>
      <StagingBanner />
      <MainRoutes />
    </ModalProvider>
  </AppScaffold>,
  document.getElementById("app")
);
