import React from "react";

import "./__styles__/Icon.scss";

const Alarm = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13.5C10 14.0304 9.78929 14.5391 9.41421 14.9142C9.03914 15.2893 8.53043 15.5 8 15.5C7.46957 15.5 6.96086 15.2893 6.58579 14.9142C6.21071 14.5391 6 14.0304 6 13.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 11.5H15"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 11.5C14.4696 11.5 13.9609 11.2893 13.5858 10.9142C13.2107 10.5391 13 10.0304 13 9.5V5.5C13 4.17392 12.4732 2.90215 11.5355 1.96447C10.5979 1.02678 9.32608 0.5 8 0.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 11.5C1.53043 11.5 2.03914 11.2893 2.41421 10.9142C2.78929 10.5391 3 10.0304 3 9.5V5.5C3 4.17392 3.52678 2.90215 4.46447 1.96447C5.40215 1.02678 6.67392 0.5 8 0.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Alarm;
