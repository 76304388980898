import React, {
  KeyboardEvent,
  MouseEvent,
  useContext,
  useEffect,
  useRef,
} from "react";
import { head, sortBy } from "lodash";

import { Icon as LucideIcon } from "../../Common/Icons/LucideIcons";
import { DistanceMeasureTool } from "./Distance";
import { track } from "../../../utils/tracking";
import { ObserveRasterMeasureTool } from "./ObserveRaster";
import { LayerContext } from "../layers";
import {
  isKeyboardEvent,
  usedInaccessibleKey,
  usedNavKey,
  usedPrevNavKey,
  usedEscapeKey,
  usedActivationKey,
} from "./utils";

import {
  LegendControl,
  MeasureModeToggle,
  MeasureModeToggleLabel,
} from "../__styles__/FullPageMap";

const MeasureToolLegendControl = () => {
  const {
    measureToolDispatch,
    measureToolState,
    visibleRaster: getVisibleRaster,
    a11yEnabled,
    setA11yEnabled,
    rasters,
  } = useContext(LayerContext);

  const measureToolMenuRef = useRef<HTMLDivElement>(null);
  const legendControlRef = useRef<HTMLButtonElement>(null);

  const { measureToolMode } = measureToolState;
  const accountHasRaster = rasters.length > 0;
  const visibleRaster = getVisibleRaster();

  const offModeDispatch = () => {
    measureToolDispatch({
      type: "setMeasureMode",
      data: { measureToolMode: "off" },
    });
  };

  const selectingModeDispatch = () => {
    measureToolDispatch({
      type: "setMeasureMode",
      data: { measureToolMode: "selecting" },
    });
  };

  const distanceModeDispatch = () => {
    measureToolDispatch({
      type: "setMeasureMode",
      data: {
        measureToolMode: "distance",
        dataMode: "observe",
        render: () => <DistanceMeasureTool />,
      },
    });
    track("Clicked distance measure tool");
  };

  const rasterModeDispatch = () => {
    const defaultRasterId = head(sortBy(rasters, "createdAt"))!.id;
    const rasterId = visibleRaster?.id ?? defaultRasterId;

    measureToolDispatch({
      type: "setMeasureMode",
      data: {
        measureToolMode: "raster",
        dataMode: "observe",
        render: () => <ObserveRasterMeasureTool rasterId={rasterId} />,
      },
    });
    track("Clicked raster measure tool");
  };

  const handleDistanceMenuItemEvent = (event: KeyboardEvent | MouseEvent) => {
    if (isKeyboardEvent(event)) {
      if (usedInaccessibleKey(event) || usedNavKey(event)) return;

      event.preventDefault();
      setA11yEnabled(true);

      if (usedPrevNavKey(event) || usedEscapeKey(event)) {
        offModeDispatch();
        legendControlRef.current?.focus();
        return;
      }
    } else {
      setA11yEnabled(false);
    }

    distanceModeDispatch();
  };

  const handleRasterMenuItemEvent = (event: KeyboardEvent | MouseEvent) => {
    if (isKeyboardEvent(event)) {
      if (usedInaccessibleKey(event) || usedPrevNavKey(event)) return;

      event.preventDefault();
      setA11yEnabled(true);

      if (usedNavKey(event) || usedEscapeKey(event)) {
        offModeDispatch();
        legendControlRef.current?.focus();
        return;
      }
    } else {
      setA11yEnabled(false);
    }

    rasterModeDispatch();
  };

  const handleLegendControlEvent = (event: KeyboardEvent | MouseEvent) => {
    if (isKeyboardEvent(event)) {
      if (!usedActivationKey(event)) return;
      event.preventDefault();
      setA11yEnabled(true);
    } else {
      setA11yEnabled(false);
    }

    if (accountHasRaster) {
      if (measureToolMode === "selecting") {
        offModeDispatch();
      } else {
        selectingModeDispatch();
      }
    } else {
      distanceModeDispatch();
    }
  };

  useEffect(() => {
    // If the user is using the keyboard to toggle the measure tool,
    // focus on the first element in the measure tool menu
    if (a11yEnabled && measureToolMode === "selecting") {
      measureToolMenuRef.current?.focus();
    }
  }, [a11yEnabled, measureToolMode]);

  return (
    <>
      {measureToolMode === "selecting" && (
        <MeasureModeToggle>
          <MeasureModeToggleLabel
            ref={measureToolMenuRef}
            onClick={handleDistanceMenuItemEvent}
            onKeyDown={handleDistanceMenuItemEvent}
            tabIndex={0}
          >
            Measure distance
          </MeasureModeToggleLabel>
          <MeasureModeToggleLabel
            onClick={handleRasterMenuItemEvent}
            onKeyDown={handleRasterMenuItemEvent}
            tabIndex={0}
          >
            Measure raster layer
          </MeasureModeToggleLabel>
        </MeasureModeToggle>
      )}
      <LegendControl
        ref={legendControlRef}
        styleVariant="outlineLight"
        onClick={handleLegendControlEvent}
        onKeyDown={handleLegendControlEvent}
        aria-label="Toggle measure tool"
      >
        <LucideIcon iconName="pencil-ruler" color="contentPrimary" size={16} />
      </LegendControl>
    </>
  );
};

export default MeasureToolLegendControl;
