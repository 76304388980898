import React from "react";

import "./__styles__/Icon.scss";

export default () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="23" fill="white" />
      <rect x="0.5" y="0.5" width="19" height="23" stroke="#E39600" />
      <path
        d="M10 10C12.4853 10 14.5 9.32843 14.5 8.5C14.5 7.67157 12.4853 7 10 7C7.51472 7 5.5 7.67157 5.5 8.5C5.5 9.32843 7.51472 10 10 10Z"
        stroke="#E39600"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50003 8.5V15.5C5.49725 15.7405 5.66793 15.9776 5.9977 16.1915C6.32747 16.4054 6.80665 16.5897 7.39485 16.7289C7.98306 16.8681 8.66304 16.9581 9.37749 16.9914C10.0919 17.0246 10.8199 17.0002 11.5 16.92"
        stroke="#E39600"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 8.5V10"
        stroke="#E39600"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 12C5.50072 12.2338 5.66534 12.4642 5.98073 12.673C6.29611 12.8818 6.75353 13.0632 7.31648 13.2026C7.87944 13.342 8.53234 13.4356 9.22311 13.4759C9.91387 13.5163 10.6234 13.5023 11.295 13.435"
        stroke="#E39600"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clip-path="url(#clip0_2543_8067)">
        <path
          d="M17 13.5C17 15 15 16.5 15 16.5C15 16.5 13 15 13 13.5C13 12.9696 13.2107 12.4609 13.5858 12.0858C13.9609 11.7107 14.4696 11.5 15 11.5C15.5304 11.5 16.0391 11.7107 16.4142 12.0858C16.7893 12.4609 17 12.9696 17 13.5Z"
          stroke="#E39600"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 14.25C15.4142 14.25 15.75 13.9142 15.75 13.5C15.75 13.0858 15.4142 12.75 15 12.75C14.5858 12.75 14.25 13.0858 14.25 13.5C14.25 13.9142 14.5858 14.25 15 14.25Z"
          stroke="#E39600"
          strokeWidth="0.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2543_8067">
          <rect
            width="6"
            height="6"
            fill="white"
            transform="translate(12 11)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
