import React from "react";
import { useModal } from "react-modal-hook";
import { track } from "../../../utils/tracking";
import { RESOURCE_NAME } from "common/authorization";
import { AuthContext } from "../../Authorization/AuthContext";
import {
  Property,
  useGetAccountDocumentTemplatesIdAndNameQuery,
} from "../../../generated/graphql";

import { DropdownMenu } from "../../Inputs";
import { Button } from "../../Common/Button";

import { EmptyState } from "../../Common/EmptyState";
import { LayerContext } from "../../Maps/layers";
import Modal from "../../Common/Modal";
import UploadDocumentsForm from "../../DocumentUploads/UploadDocumentsForm";
import { GenerateDocumentForm } from "../../DocumentUploads/GeneratedDocuments/GenerateDocumentForm";

import "./__styles__/List.scss";
import { GetHelpLink } from "../../Guest/GetHelpLink";

export interface Props {
  property: Pick<Property, "id" | "fullAddress">;
  children: Array<React.ReactNode>;
}

export default ({ property, children }: Props) => {
  const { authorized, isGuest } = React.useContext(AuthContext);
  const { updateMap } = React.useContext(LayerContext);

  const canUploadDocument = authorized({
    resource: RESOURCE_NAME.DOCUMENT_UPLOAD,
    permission: "create",
  });

  const canGenerateDocument = authorized({
    resource: RESOURCE_NAME.GENERATED_DOCUMENT,
    permission: "create",
  });

  const canListDocumentTemplates = authorized({
    resource: RESOURCE_NAME.DOCUMENT_TEMPLATE,
    permission: "list",
  });

  const { data, loading } = useGetAccountDocumentTemplatesIdAndNameQuery({
    skip: !canListDocumentTemplates,
  });

  const documentTemplates = data?.account?.documentTemplates ?? [];

  const [showUploadModal, hideUploadModal] = useModal(
    () => (
      <Modal onRequestClose={hideUploadModal}>
        <UploadDocumentsForm
          closeModal={() => {
            hideUploadModal();
          }}
          updateMap={updateMap}
          property={property}
        />
      </Modal>
    ),
    [property]
  );

  const onClickUpload = () => {
    track("Clicked upload from EC List", {
      propertyId: property.id,
      fullAddress: property.fullAddress,
    });
    showUploadModal();
  };

  const onClickGenerate = () => {
    track("Clicked 'Generate from template' button", {
      propertyId: property.id,
      fullAddress: property.fullAddress,
    });
    showGenerateModal();
  };

  const [showGenerateModal, hideGenerateModal] = useModal(
    () => (
      <Modal onRequestClose={hideGenerateModal}>
        <GenerateDocumentForm
          property={property}
          documentTemplates={documentTemplates}
          updateMap={updateMap}
          closeModal={() => hideGenerateModal()}
        />
      </Modal>
    ),
    [documentTemplates, property]
  );

  const actions = [
    {
      label: "Upload file",
      onClick: onClickUpload,
      disabled: !canUploadDocument,
    },
    {
      label: "Generate from template",
      onClick: onClickGenerate,
      disabled: !canGenerateDocument,
    },
  ];

  const AddNewButton = ({
    onClick,
  }: {
    onClick: (_: React.MouseEvent<HTMLElement>) => void;
  }) => (
    <Button
      onClick={onClick}
      styleVariant="primary"
      size="small"
      data-testid="addNewButton"
      rightIconName="chevron-down"
    >
      Add new
    </Button>
  );

  const emptyStateMessage = isGuest ? (
    <>
      There are currently no published files for this property in Forerunner.
      For more information, please <GetHelpLink propertyId={property.id} />.
    </>
  ) : (
    <>There are no files uploaded to this property yet</>
  );

  return (
    <div>
      <h4 styleName="title">
        Recent files
        {!isGuest &&
          !loading &&
          (documentTemplates.length > 0 ? (
            <DropdownMenu actions={actions} customButton={AddNewButton} />
          ) : (
            <Button
              size="small"
              styleVariant="primary"
              onClick={onClickUpload}
              disabled={!canUploadDocument}
              data-testid="uploadButton"
              leftIconName="plus"
            >
              Upload
            </Button>
          ))}
      </h4>
      {!children.length ? (
        <div styleName="line">
          <EmptyState message={emptyStateMessage} />
        </div>
      ) : (
        children
      )}
    </div>
  );
};
