import React from "react";
import { Layer, Source } from "react-map-gl";
import { LayerContext } from ".";
import { FIRM } from "./firm";
import { colors } from "../../../stitches.config";

export const group = "profileBaselines";

class MapLayer extends React.PureComponent<{ firm: FIRM }> {
  render() {
    const { isLayerVisible } = this.context;
    const { firm } = this.props;

    const tiles = [
      `${window.env.APPLICATION_URL}/api/tiles/profileBaselines/${firm.id}/{z}/{x}/{y}`,
    ];

    const id = `${group}-${firm.id}`;

    return (
      <Source id={id} tiles={tiles} type="vector">
        <Layer
          id={`${id}-lines`}
          type="line"
          source-layer="src"
          layout={{
            visibility: isLayerVisible({ group, id: firm.id })
              ? "visible"
              : "none",
          }}
          filter={["step", ["zoom"], false, 10, true]}
          paint={{
            "line-color": colors.featureBlue.value,
            "line-width": 3,
            "line-dasharray": [2, 2],
          }}
        />
        <Layer
          id={`${id}-labels`}
          type="symbol"
          source-layer="src"
          layout={{
            "symbol-placement": "line-center",
            visibility: isLayerVisible({ group, id: firm.id })
              ? "visible"
              : "none",
            "text-field": ["get", "surfaceWaterFeature"],
            "text-padding": 10,
            "text-size": 15,
            "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
          }}
          filter={["step", ["zoom"], false, 12, true]}
          paint={{
            "text-color": colors.featureBlue.value,
            "text-halo-color": colors.mapOutlineLight.value,
            "text-opacity": 1,
            "text-halo-width": 1,
          }}
        />
      </Source>
    );
  }
}

MapLayer.contextType = LayerContext;

export default MapLayer;
