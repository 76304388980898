import React, { useState } from "react";
import { useModal } from "react-modal-hook";

import Modal from "../../../Common/Modal";
import { Button } from "../../../Common/Button";
import { useForm } from "react-hook-form";
import { IgnoreReason } from "./__styles__/IgnoreModal";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../../Common/__styles__/Modal";

type IgnoreModalProps = {
  ignoredReason: Maybe<string>;
  onCancel: () => void;
  onSave: () => void;
  onIgnore: ({ ignoredReason }: { ignoredReason: Maybe<string> }) => void;
};

type IgnoreModalHookProps = Pick<IgnoreModalProps, "ignoredReason">;
type IgnoreModalShowProps = Pick<IgnoreModalProps, "onIgnore">;

interface FormStructure {
  ignoredReason: Maybe<string>;
}

export const useIgnoreModal = ({ ...props }: IgnoreModalHookProps) => {
  const [showProps, setShowProps] = useState<IgnoreModalShowProps>();
  const [show, hide] = useModal(() => {
    return (
      <Modal onRequestClose={hide}>
        <IgnoreModal
          onCancel={hide}
          onSave={hide}
          {...props}
          onIgnore={showProps?.onIgnore!}
        />
      </Modal>
    );
  }, [props]);

  const showWithProps = (additionalProps: IgnoreModalShowProps) => {
    setShowProps(additionalProps);
    show();
  };

  return [showWithProps, hide] as const;
};

const IgnoreModal = ({
  ignoredReason,
  onCancel,
  onSave,
  onIgnore,
}: IgnoreModalProps) => {
  const { getValues, register } = useForm<FormStructure>({
    defaultValues: {
      ignoredReason: ignoredReason,
    },
  });

  const handleCancel = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    onCancel();
  };

  const handleSave = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    onIgnore({ ignoredReason: getValues("ignoredReason") });
    onSave();
  };

  return (
    <Container>
      <HeaderSection>
        <h1>Ignore issue reason</h1>
        <h2> Leave an optional reason for why you are ignoring the issue</h2>
      </HeaderSection>
      <ContentSection data-testid="inputs">
        <IgnoreReason
          data-testid="ignoredReasonTextarea"
          {...register("ignoredReason")}
        />
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button styleVariant="secondary" size="medium" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave} size="medium" styleVariant="primary">
            Save
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};

export default IgnoreModal;
