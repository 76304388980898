import { mapValues, merge, filter } from "lodash";
import React, { createContext, ReactNode, useState } from "react";
import {
  MapsForAccountQuery,
  PropertyWarningGroup,
} from "../../../generated/graphql";
import { v4 as uuidv4 } from "uuid";
import {
  MeasureToolReducer,
  useMeasureTool,
} from "common-client/utils/useMeasureTool";
import {
  useApproximateBfeTool,
  ApproximateBfeToolReducer,
} from "common-client/utils/useApproximateBfeTool";
import {
  defaultsForFirmGroupComponents,
  generateDefaultLayerState,
  Group,
  GroupId,
  LayerState,
  MultipleSelectionGroup,
  WarningGroup,
} from "common-client/utils/layerState";

type OptionalLayers =
  | "firms"
  | "customMaps"
  | "rasters"
  | "baseMaps"
  | "accountDocumentTypes"
  | "accountPropertyWarningDefinitions";
export type Account = Omit<
  NonNullable<MapsForAccountQuery["account"]>,
  OptionalLayers | "name"
> &
  Partial<Pick<NonNullable<MapsForAccountQuery["account"]>, OptionalLayers>>;

export type FIRM = NonNullable<MapsForAccountQuery["account"]>["firms"][number];
export type Raster = NonNullable<
  MapsForAccountQuery["account"]
>["rasters"][number];
export type SavedView = NonNullable<
  NonNullable<MapsForAccountQuery["account"]>["savedViews"]
>[number];

export type CustomMap = NonNullable<Account["customMaps"]>[number];
export type BaseMap = NonNullable<Account["baseMaps"]>[number];

type LayerId = string;

export type LayerContextType = {
  toggleLayer: <G extends Group>(args: {
    group: G;
    id?: GroupId<G>;
    isVisible: boolean;
  }) => void;
  isLayerVisible: <G extends Group>(args: {
    group: G;
    id: GroupId<G>;
  }) => boolean;
  isLayerGroupVisible: <G extends Group>(args: { group: G }) => boolean;
  visibleLayerIdsForGroup: (group: keyof LayerState) => LayerId[];
  visibleFIRM: () => FIRM | undefined;
  visibleRaster: () => Raster | undefined;
  visibleSavedViews: () => Array<SavedView> | undefined;
  rasters: Array<Raster>;
  mapNonce: string;
  updateMap: () => void;
  measureToolState: ReturnType<typeof useMeasureTool>[0];
  measureToolDispatch: ReturnType<typeof useMeasureTool>[1];
  approximateBfeToolState?: ReturnType<typeof useApproximateBfeTool>[0];
  approximateBfeToolDispatch?: ReturnType<typeof useApproximateBfeTool>[1];
  a11yEnabled: boolean;
  setA11yEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LayerContext = createContext<LayerContextType>({} as any);

export const LayerContextProvider = ({
  account,
  children,
  defaultState = {},
}: {
  account: Account;
  children: ReactNode;
  defaultState?: Partial<LayerState>;
}) => {
  const firms = account.firms ?? [];

  const [layers, updateLayers] = useState<LayerState>(
    generateDefaultLayerState({ account, defaultState })
  );

  const toggleLayer = <G extends Group>({
    group,
    id,
    isVisible,
  }: {
    group: G[] | G;
    id?: GroupId<G>;
    isVisible: boolean;
  }) => {
    let updatedLayers = merge({}, layers);
    let groups = Array.isArray(group) ? group : [group];

    for (const group of groups) {
      const warningGroups = Object.values(PropertyWarningGroup).map(
        group => `${group}Warnings` as WarningGroup
      );
      // these groups allow for multiple toggles within the same group
      const allowMultipleSelectionGroups: Group[] = [
        ...warningGroups,
        "customMaps",
        "savedViews",
      ];

      // if the group does not allow for multiple selections disable toggles from the same group
      if (!allowMultipleSelectionGroups.includes(group)) {
        updatedLayers[group as WarningGroup] = mapValues(
          layers[group as WarningGroup],
          () => false
        );
      }
      // Some sections have mutually exclusive toggles,
      // so we disable toggles from the mutually exclusive groups before enabling just the one
      const mutuallyExclusiveGroups: Array<Array<Group>> = [
        ["firms", "rasters"],
        ["rasters", "customMaps"],
        ["documents", ...warningGroups, "savedViews"],
      ];

      for (const mutuallyExclusiveGroup of mutuallyExclusiveGroups) {
        if (mutuallyExclusiveGroup.includes(group)) {
          for (const meg of mutuallyExclusiveGroup) {
            if (group !== meg) {
              updatedLayers[meg as WarningGroup] = mapValues(
                layers[meg as WarningGroup],
                () => false
              );
            }
          }
        }
      }

      if (id) {
        updatedLayers[group][id] = isVisible as any;

        if (group === "firms") {
          updatedLayers = {
            ...updatedLayers,
            ...defaultsForFirmGroupComponents(
              firms.map(firm => ({
                ...firm,
                visible: firm.id === id && isVisible,
              }))
            ),
          };
        }
      } else {
        updatedLayers[group as MultipleSelectionGroup] = mapValues(
          layers[group as MultipleSelectionGroup],
          () => isVisible
        );
      }
    }
    updateLayers(updatedLayers);
  };

  const visibleLayerIdsForGroup = (group: Group) => {
    const enabledLayer = filter(
      Object.entries(layers[group]),
      ([_layer, enabled]) => enabled
    );

    return enabledLayer.map(layer => layer[0]);
  };

  const visibleFIRM = () => {
    const firmId = visibleLayerIdsForGroup("firms")[0];
    return account.firms?.find(firm => firm.id === firmId);
  };

  const visibleRaster = () => {
    const rasterId = visibleLayerIdsForGroup("rasters")[0];
    return account.rasters?.find(raster => raster.id === rasterId);
  };

  const visibleSavedViews = () => {
    const viewIds = visibleLayerIdsForGroup("savedViews");
    return account.savedViews?.filter(view => viewIds.includes(view.id));
  };

  const isLayerVisible = <G extends Group>({
    group,
    id,
  }: {
    group: G;
    id: GroupId<G>;
  }) => {
    return !!layers[group][id];
  };

  const isLayerGroupVisible = <G extends Group>({ group }: { group: G }) => {
    const currentLayers = layers[group as WarningGroup];
    return Object.values(currentLayers).every(layer => layer);
  };

  const [mapNonce, setMapNonce] = useState(uuidv4());
  const updateMap = () => setMapNonce(uuidv4());

  const [measureToolState, measureToolDispatch] = useMeasureTool({
    defaultValue: {
      measureToolMode: "off",
      coordinates: [],
      loading: false,
    },
    useReducer: React.useReducer as MeasureToolReducer,
  });

  const [approximateBfeToolState, approximateBfeToolDispatch] =
    useApproximateBfeTool({
      defaultValue: {
        mode: "off",
        crossSections: [],
        disableMapInteractions: false,
      },
      useReducer: React.useReducer as ApproximateBfeToolReducer,
      showBfeLayers: () => {
        const id = visibleFIRM()?.id;
        toggleLayer({
          group: ["profileBaselines", "crossSections"],
          id,
          isVisible: true,
        });
      },
    });

  const [a11yEnabled, setA11yEnabled] = useState(false);

  const value = {
    toggleLayer,
    isLayerVisible,
    isLayerGroupVisible,
    visibleFIRM,
    visibleRaster,
    visibleSavedViews,
    visibleLayerIdsForGroup,
    mapNonce,
    updateMap,
    measureToolDispatch,
    measureToolState,
    approximateBfeToolState,
    approximateBfeToolDispatch,
    a11yEnabled,
    setA11yEnabled,
    rasters: account.rasters ?? [],
  };

  return (
    <LayerContext.Provider value={value}>{children}</LayerContext.Provider>
  );
};
