import React, { useCallback } from "react";
import { Control, Controller } from "react-hook-form";
import { CategoryFieldValues, Field } from "common-client/utils/Search";
import { isValidSearchQuery } from "common/utils/strings";

import { Text } from "../Inputs";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";

import { IconWrapper } from "./__styles__/Search";
import { debounce } from "lodash";

export const SearchField = ({
  listedField,
  control,
  handleOnChange,
  deactivateCategorySelectionStatus,
  onSearchFieldClear,
}: {
  listedField: Field;
  control: Control;
  handleOnChange: () => void;
  deactivateCategorySelectionStatus: () => void;
  onSearchFieldClear: (value: CategoryFieldValues) => void;
}) => {
  const debouncedOnChange = useCallback(debounce(handleOnChange, 250), []);

  return (
    <Controller
      control={control}
      name={listedField.value}
      render={({ field }) => (
        <>
          {field.value && (
            <IconWrapper
              iconPosition={"inside"}
              icon="close"
              onClick={() => {
                onSearchFieldClear(listedField.value);
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  onSearchFieldClear(listedField.value);
                }
              }}
              data-testid={`${listedField.value}-clear`}
              tabIndex={0}
              clickable
            >
              <Icon icon={Icons.CLOSE} color={ICON_COLORS.GREY_2} />
            </IconWrapper>
          )}
          <Text
            data-testid={`${listedField.value}-search-input`}
            name={listedField.value}
            placeholder="Search"
            onChange={value => {
              if (isValidSearchQuery(value)) {
                field.onChange(value);
                debouncedOnChange();
              }
            }}
            onClick={deactivateCategorySelectionStatus}
            size="small"
            value={field.value}
            maxLength={256}
            style={{
              paddingLeft: "15px",
              paddingRight: "30px",
              border: "none",
              borderRadius: 0,
            }}
          />
        </>
      )}
    />
  );
};
