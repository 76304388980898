import React from "react";

import "./__styles__/Icon.scss";

const Checkmark = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.725 0.725L3.75 7.7L1.275 5.225C0.975 4.925 0.525 4.925 0.225 5.225C-0.075 5.525 -0.075 5.975 0.225 6.275L3.225 9.275C3.375 9.425 3.525 9.5 3.75 9.5C3.975 9.5 4.125 9.425 4.275 9.275L11.775 1.775C12.075 1.475 12.075 1.025 11.775 0.725C11.475 0.425 11.025 0.425 10.725 0.725Z"
      fill="#2A70FF"
    />
  </svg>
);

export default Checkmark;
