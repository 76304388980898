import React, { PropsWithChildren } from "react";
import { ToastProps, AppearanceTypes } from "react-toast-notifications";
import { Container, IconWrapper, LeftContent } from "./__styles__/Toast";
import { Icon, IconNames } from "./Icons/LucideIcons";
import { colors } from "../../stitches.config";
import { Body } from "./Typography";
const IconMap: Record<AppearanceTypes, IconNames> = {
  success: "check-circle",
  error: "x-circle",
  info: "info",
  warning: "alert-circle",
};

const ColorMap: Record<AppearanceTypes, keyof typeof colors> = {
  success: "contentSuccessDark",
  error: "contentCriticalDark",
  warning: "contentAttentionDark",
  info: "contentInfoDark",
};

export default ({
  children,
  appearance = "info",
  transitionDuration,
  onDismiss,
  transitionState,
}: PropsWithChildren<ToastProps> & { appearance?: AppearanceTypes }) => {
  return (
    <Container
      transitionState={transitionState}
      style={{ transitionDuration: `${transitionDuration}ms` }}
    >
      <LeftContent>
        <IconWrapper>
          <Icon
            iconName={IconMap[appearance] as IconNames}
            color={ColorMap[appearance] as keyof typeof colors}
            size={16}
          />
        </IconWrapper>
        <Body color={"contentPrimaryDark"} size={"default"} type={"emphasis"}>
          {children}
        </Body>
      </LeftContent>
      <IconWrapper>
        <Icon
          iconName={"x"}
          color="contentPrimaryDark"
          size={16}
          cursor="pointer"
          onClick={() => onDismiss()}
        />
      </IconWrapper>
    </Container>
  );
};
