import React from "react";

import { Address } from "./__styles__/Submission";
import { Header } from "../Common/__styles__/FullPageFormLayout";
import { Submission, SubmissionType } from "../../generated/graphql";
import { formatCoordinates } from "common/utils/coordinates";

type SubmissionHeaderProps = {
  property?: Maybe<
    Pick<
      NonNullable<Submission["property"]>,
      "fullAddress" | "latitude" | "longitude"
    >
  >;
  submissionType: Pick<SubmissionType, "name">;
};

const SubmissionHeader: React.FC<SubmissionHeaderProps> = ({
  property,
  submissionType,
  children,
}) => {
  const address = !!property?.fullAddress ? (
    <Address>{property.fullAddress}</Address>
  ) : property ? (
    <Address>
      {formatCoordinates({
        latitude: property.latitude,
        longitude: property.longitude,
      })}
    </Address>
  ) : null;

  return (
    <Header style={{ marginBottom: "8px" }}>
      <h1>{submissionType.name}</h1>
      {address}
      {children}
    </Header>
  );
};

export default SubmissionHeader;
