import React from "react";

import { AuthContext } from "./AuthContext";
import { authorized } from "common/authorization";
import { useGetGuestAccountDetailsQuery } from "../../generated/graphql";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import { setTitle } from "../../utils/title";
import { CurrentAdmin } from "./types";

import UnavailablePage from "../Guest/UnavailablePage";
import { FEATURE_FLAG_NAME } from "common/constants";
import { LoadingContainer } from "./__styles__/GuestAuthContext";

interface Props {
  children: React.ReactNode;
}
const GuestAuthContextProvider = ({ children }: Props) => {
  const { data, loading, error } = useGetGuestAccountDetailsQuery();

  if (loading) {
    setTitle(`Loading Flood Information`);
    return (
      <LoadingContainer>
        <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
      </LoadingContainer>
    );
  }

  if (error || !data) return null;

  const account = data.account!;

  return account.publicPortal.enabled ? (
    <AuthContext.Provider
      value={{
        account,
        user: null,
        admin: null,
        isGuest: true,
        setAdmin: (_admin: CurrentAdmin) => {},
        isFeatureEnabled: (_: FEATURE_FLAG_NAME) => false,
        authorized: authDetails =>
          authorized({
            entity: {
              role: { name: "guest" },
              canViewPersonalIdentifiableInformation: false,
            },
            ...authDetails,
          }),
      }}
    >
      {children}
    </AuthContext.Provider>
  ) : (
    <UnavailablePage />
  );
};

export default GuestAuthContextProvider;
