import React from "react";
import { useModal } from "react-modal-hook";
import Modal from "../../Common/Modal";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../../Common/__styles__/Modal";
import { Button } from "../../Common/Button";
import { Textarea } from "../../Inputs";
import { startCase } from "lodash";
import { SISDForm } from "common/services/formBuilderService";
import { Body, Title } from "../../Common/Typography";

type Props = {
  formData: SISDForm;
  onComplete: (formData: SISDForm) => void;
};

export const useSISDSubmissionGateModal = () => {
  const [props, setProps] = React.useState<Maybe<Props>>(null);

  const [showUploadModal, hideUploadModal] = useModal(
    () => (
      <Modal onRequestClose={hideUploadModal}>
        <SISDSubmissionGateModal
          onRequestClose={hideUploadModal}
          onSave={description => {
            props?.onComplete({ ...props.formData, description });
            hideUploadModal();
          }}
          costType={props!.formData.costType}
          description={props!.formData.description || ""}
        />
      </Modal>
    ),
    [props]
  );

  const showModalWithProps = (props: Props) => {
    setProps(props);
    showUploadModal();
  };

  return [showModalWithProps, hideUploadModal] as const;
};

const SISDSubmissionGateModal = ({
  onSave,
  costType,
  description: initialDescription,
  onRequestClose,
}: Pick<SISDForm, "costType" | "description"> & {
  onRequestClose: () => void;
  onSave: (description: string) => void;
}) => {
  const [description, setDescription] = React.useState(initialDescription);

  return (
    <Container width={"narrow"}>
      <HeaderSection>
        <Title size="default" type="regular">
          {startCase(costType)} information has changed
        </Title>
      </HeaderSection>
      <ContentSection>
        <Body color="contentSecondary" size="default" type="regular">
          Describe the change in damage cost information or calculation.
        </Body>
        <Textarea
          name={"modal-description"}
          value={description}
          onChange={setDescription}
          title="Description"
          label="Description"
          labelSize="small"
        />
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button
            styleVariant="secondary"
            onClick={onRequestClose}
            size="medium"
          >
            Cancel
          </Button>
          <Button
            size="medium"
            styleVariant="primary"
            onClick={() => onSave(description || "")}
          >
            Save
          </Button>
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
