import React from "react";
import { Button } from "../../Common/Button";
import { isNil, startCase } from "lodash";
import { BUILDING_ELEVATION_SOURCE } from "common/constants";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { formatDate, formatBoolean, BLANK } from "common/utils/strings";

import { Comments, CommentsBox, Table } from "./__styles__/PermitInfo";
import Divider from "../../Common/Divider";
import { DocumentUploadContext } from "../Tabs";

interface RowProps {
  label: string;
  value?: Maybe<string | boolean>;
  format?: (arg: any) => string | null;
}
const Row = ({ label, value, format = v => v }: RowProps) => {
  const finalValue = isNil(value) ? BLANK : format(value);

  return (
    <tr>
      <td>{label}</td>
      <td>{finalValue}</td>
    </tr>
  );
};

const CommentBox = ({ comments }: { comments: string }) => {
  return (
    <tr>
      <CommentsBox colSpan={2}>
        <Comments>{comments}</Comments>
      </CommentsBox>
    </tr>
  );
};

interface Props {
  prevLocation: { pathname: string; search?: string } | string;
}
export default ({ prevLocation }: Props) => {
  const history = useHistory();
  const { authorized } = React.useContext(AuthContext);
  const { documentUpload } = React.useContext(DocumentUploadContext);
  const { permit, floodZone, buildingElevationSource, issuedAt, buildingUse } =
    documentUpload.elevationCertificate!;
  const {
    permitNumber,
    permitDate,
    type,
    final,
    floodProofingCertificateRequired,
    vZoneDesignCertificateRequired,
    comments,
  } = permit!;
  const finishedConstruction =
    buildingElevationSource === BUILDING_ELEVATION_SOURCE.FINISHED_CONSTRUCTION;
  const canEditPermitInfo = authorized({
    resource: RESOURCE_NAME.PERMIT,
    permission: "update",
  });

  const onClickEdit = () => {
    history.push({
      pathname: `/permits/edit/${documentUpload.elevationCertificate!.id}`,
      state: { prevLocation },
    });
  };

  const editButton = (
    <Button
      size="small"
      styleVariant="outlineLight"
      onClick={onClickEdit}
      disabled={!canEditPermitInfo}
      style={{ display: "inline-flex" }}
    >
      Edit
    </Button>
  );

  return (
    <>
      <Table>
        <thead>
          <tr>
            <td>Permit Info</td>
            <td>{editButton}</td>
          </tr>
        </thead>
        <tbody>
          <Row label="Permit Number" value={permitNumber} />
          <Row label="Permit Date" value={permitDate} format={formatDate} />
          <Row label="EC Issue Date" value={issuedAt} format={formatDate} />
          <Row label="Building Type" value={buildingUse} format={startCase} />
          <Row label="New Bldg or SI/SD?" value={type} format={startCase} />
          <Row label="FIRM Zone" value={floodZone} />
          <Row label="Permit Final" value={final} format={formatBoolean} />
          <Row
            label="Finished Const. EC?"
            value={finishedConstruction}
            format={formatBoolean}
          />
          <Row
            label="Floodproofing Certificate Required?"
            value={floodProofingCertificateRequired}
            format={formatBoolean}
          />
          <Row
            label="V Zone Design Certificate Required?"
            value={vZoneDesignCertificateRequired}
            format={formatBoolean}
          />
          <tr>
            <td>Comments</td>
            {isNil(comments) && <td>{BLANK}</td>}
          </tr>
          {!isNil(comments) && <CommentBox comments={comments} />}
        </tbody>
      </Table>
      <Divider margins="16px 0" />
    </>
  );
};
