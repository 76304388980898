import React, { useMemo } from "react";

import CustomMapLayer from "../layers/customMap";
import { LayerConfig, LayerHook, LayerHookBuilder } from "./types";
import { CustomMap } from "../layers";

export type CustomMapLayerConfig = LayerConfig & { maps: Array<CustomMap> };

const customMapsHook: LayerHookBuilder<CustomMapLayerConfig> = ({ config }) => {
  return useMemo<LayerHook>(() => {
    return {
      render: () => {
        return config.maps.map(map => (
          <CustomMapLayer
            id={map.id}
            tileset={map.tileset}
            key={map.id}
            labelsVisibleOnMap={map.labelsVisibleOnMap}
          />
        ));
      },
    };
  }, [config]);
};

export default customMapsHook;
