import React from "react";
import { Layer, Source } from "react-map-gl";
import { LayerContext } from ".";

export const id = "cbrs";
export const group = "topology";

const TILES = [`${window?.env?.APPLICATION_URL}/api/tiles/cbrs/{z}/{x}/{y}`];

class MapLayer extends React.PureComponent {
  render() {
    const { isLayerVisible } = this.context;

    return (
      <Source id={id} tiles={TILES} type="vector">
        <Layer
          id={`${id}-zones`}
          type="fill"
          source-layer="src"
          layout={{
            visibility: isLayerVisible({ group, id }) ? "visible" : "none",
          }}
          paint={{
            "fill-pattern": "shape-line",
            "fill-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              10,
              0.8,
              22,
              0.5,
            ],
          }}
          beforeId="ground"
        ></Layer>
      </Source>
    );
  }
}

MapLayer.contextType = LayerContext;

export default MapLayer;
