import React, { ReactElement } from "react";
import Icon from "./Icons";
import {
  Note,
  Container,
  Heading,
  IconWrapper,
} from "./__styles__/NoticeBoard";

interface Props {
  icon: ReactElement<Icon>;
  heading: string;
  note: string;
}
export default ({ icon, heading, note }: Props) => (
  <Container>
    <IconWrapper>{icon}</IconWrapper>

    <Heading tabIndex={0}>{heading}</Heading>

    <Note tabIndex={0}>{note}</Note>
  </Container>
);
