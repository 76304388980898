import React, { ComponentProps } from "react";
import { useDropzone as useReactDropzone } from "react-dropzone";
import { groupBy, omit, truncate } from "lodash";
import { ErrorCode, FileRejection } from "react-dropzone";
import { captureException } from "@sentry/browser";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { MAX_SIZE_PER_MIME_TYPE_IN_MB } from "common/utils/documentUploads";

import { Datepicker, Select } from "../Inputs";
import { Button } from "../Common/Button";

import { Property, useUploadDocumentsMutation } from "../../generated/graphql";
import Icon, { Icons } from "../Common/Icons";
import { GET_DOCUMENTS } from "../AddressPanel/DocumentUploads/__queries__/getDocumentsForProperty";
import { FlexColumn } from "../Common/__styles__/Layout";
import { track } from "../../utils/tracking";
import {
  DOCUMENT_TYPE_NAME,
  MIME_TYPE,
  MULTIPART_UPLOAD_TYPE,
} from "common/constants";
import { AuthContext } from "../Authorization/AuthContext";
import { formatCollection } from "common/utils/strings";
import { useIsMobileWidth } from "../Guest/utils";
import { DropzoneComponent, SuccessOrErrorIcon } from "../FileUploads";

import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
} from "../Common/__styles__/Modal";
import {
  BlobError,
  Block,
  DataRow,
  DesktopIcon,
  FieldColumn,
  FileName,
  FilesTable,
  Header,
  Info,
  MobileIcon,
  NameColumn,
  RequiredField,
} from "./__styles__/UploadDocumentsForm";
import { EmptyFileContainer } from "../FileUploads/__styles__/FileUploads";
import { useMultipartFileUpload } from "../../hooks/useMultipartFileUpload";
import { isNotNil } from "common/utils/tools";
import { getAllowedMimeTypes } from "common-client/utils/documentUploads";
import { CAROUSEL_DOCUMENTS_QUERY } from "../AddressPanel/PropertyOverview/__queries__/getCarouselDocuments";

export interface DroppedFile extends File {
  path?: string; //only marked optional to appease unavoidable, too strict type in useDropzone
}

interface Inputs {
  propertyId: string;
  documentUploads: Array<UploadingDocument>;
}

interface UploadingDocument {
  blob: DroppedFile;
  issuedAt: Maybe<string>;
  documentTypeName?: string;
  accountDocumentTypeId: string;
}

const UploadDocumentsForm = ({
  closeModal,
  property,
  updateMap,
  elevationCertificatesOnly = false,
  useDropzone = useReactDropzone,
}: {
  closeModal: () => void;
  property?: Maybe<Pick<Property, "id" | "fullAddress">>;
  updateMap: () => void;
  elevationCertificatesOnly?: boolean;
  useDropzone?: ComponentProps<typeof DropzoneComponent>["useDropzone"];
}) => {
  const { account } = React.useContext(AuthContext);
  const isMobile = useIsMobileWidth();

  const [uploadSourceFile, { loading: isUploading }] = useMultipartFileUpload(
    {}
  );

  const accountDocumentTypes = account?.accountDocumentTypes ?? [];
  const idsToAccountDocumentTypes = groupBy(accountDocumentTypes, "id");

  const fullAddress = property?.fullAddress;
  const propertyId = property?.id;
  const maxFiles = 10;

  const elevationCertificateType = accountDocumentTypes.find(
    accountDocumentType =>
      accountDocumentType.documentType?.name ===
      DOCUMENT_TYPE_NAME.ELEVATION_CERTIFICATE
  );

  const allowedMimeTypes = elevationCertificatesOnly
    ? elevationCertificateType!.allowedMimeTypes
    : getAllowedMimeTypes({ accountDocumentTypes });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    setError,
    clearErrors,
    getValues,
    watch,
  } = useForm<Inputs>();

  const { fields, append, remove } = useFieldArray({
    name: "documentUploads",
    control,
  });

  const { addErrorToast, addSuccessToast } = useStatusToasts();

  const onDrop = (acceptedFiles: Array<DroppedFile>) => {
    const uploadingDocuments = acceptedFiles.map(f => ({
      blob: Object.assign(f, { url: URL.createObjectURL(f) }),
      issuedAt: null,
      documentTypeName: elevationCertificatesOnly
        ? elevationCertificateType?.documentType?.name
        : undefined,
      accountDocumentTypeId: elevationCertificatesOnly
        ? elevationCertificateType?.id
        : undefined,
    }));

    if (fields.length + uploadingDocuments.length > maxFiles) {
      addErrorToast(`You can only upload up to ${maxFiles} files at a time.`);

      return;
    }

    append(uploadingDocuments as Array<UploadingDocument>);
  };

  const onDropRejected = (fileWithErrors: Array<FileRejection>) => {
    fileWithErrors.forEach(fileWithError => {
      const errorCodesForFile = fileWithError.errors.map(error => error.code);
      if (errorCodesForFile.includes(ErrorCode.FileTooSmall)) {
        addErrorToast(`${fileWithError.file.name} is an empty file`);
      }
      if (errorCodesForFile.includes(ErrorCode.FileTooLarge)) {
        addErrorToast(`${fileWithError.file.name} is too large`);
      }
      if (errorCodesForFile.includes(ErrorCode.FileInvalidType)) {
        addErrorToast(`${fileWithError.file.name} is the wrong file type`);
      }
    });

    if (
      fileWithErrors.some(file =>
        file.errors.some(error => error.code.includes(ErrorCode.TooManyFiles))
      )
    ) {
      addErrorToast(`Only ${maxFiles} files can be uploaded at a time`);
    }
  };

  const refetchQueries = propertyId
    ? [
        {
          query: GET_DOCUMENTS,
          variables: { propertyId, isGuest: false },
        },
        {
          query: CAROUSEL_DOCUMENTS_QUERY,
          variables: { propertyId, filterHidden: false },
        },
      ]
    : [];

  const [uploadDocuments, { loading: isSubmitting }] =
    useUploadDocumentsMutation({
      refetchQueries,
      onCompleted: () => {
        closeModal();
        updateMap();

        addSuccessToast("Your files were successfully uploaded");
      },
      onError: error => {
        let message;

        if (error.message.includes("Invalid PDF file")) {
          message = error.message;
        } else {
          message =
            "There was an issue uploading files. Please try again. If the problem persists, please email us at support@withforerunner.com";
          captureException(error);
        }

        addErrorToast(message);
      },
    });

  const onChangeDocumentType = ({
    onChange,
    number,
    accountDocumentTypeId,
  }: {
    onChange: (...event: any[]) => void;
    number: number;
    accountDocumentTypeId: Maybe<string>;
  }) => {
    const accountDocumentType = accountDocumentTypes.find(
      accountDocumentType => accountDocumentType.id === accountDocumentTypeId
    );
    if (accountDocumentType === elevationCertificateType)
      setValue(`documentUploads.${number}.issuedAt`, null);
    onChange(accountDocumentTypeId);
  };

  const onSubmit = async (data: Inputs) => {
    let documentUploads = (
      await Promise.all(
        data.documentUploads.map(async documentUpload => {
          const accountDocumentType = accountDocumentTypes.find(
            accountDocumentType =>
              accountDocumentType.id === documentUpload.accountDocumentTypeId
          );

          // this looks odd, but we favor the documentType's name over accountDocumentType's name
          // because the server is looking for some specific string names, and the document type
          // name is immutable compared to the account document type's name
          documentUpload.documentTypeName =
            accountDocumentType?.documentType?.name ??
            accountDocumentType?.name;

          let inputFileKey: Maybe<string> = await new Promise(resolve =>
            uploadSourceFile({
              file: documentUpload.blob,
              uploadType: MULTIPART_UPLOAD_TYPE.DOCUMENT_UPLOADS,
              onCompleted: key => {
                resolve(key);
              },
              onUploadError: e => {
                captureException(e);
                resolve(null);
              },
            })
          );

          if (!inputFileKey) {
            addErrorToast("Failed to upload file");
            return;
          }

          const metadata =
            property && !accountDocumentType
              ? {
                  propertyId: property.id,
                  address: property.fullAddress,
                }
              : {};

          track(`Uploaded ${accountDocumentType?.name}`, metadata);

          return {
            ...omit(documentUpload, "blob"),
            inputFileKey,
          };
        })
      )
    ).filter(isNotNil);

    await uploadDocuments({
      variables: {
        data: {
          propertyId: propertyId!,
          documentUploads,
        },
      },
    });
  };

  const dropzoneTitleId = "dropzone-title";
  const dropzoneSubtitleId = "dropzone-subtitle";
  const DropzoneContent = () => (
    <>
      <h5 id={dropzoneTitleId}>
        Drop your{" "}
        {elevationCertificatesOnly ? "Elevation Certificates" : "files"} here
      </h5>
      <p id={dropzoneSubtitleId}>
        or <span>browse</span> to choose{" "}
        {elevationCertificatesOnly ? "an EC" : "a file"}
      </p>
    </>
  );

  const isProcessingDocuments = isUploading || isSubmitting;

  return (
    <Container tabIndex={-1} width={"wide"} overflows>
      {elevationCertificatesOnly ? (
        <HeaderSection>
          <h1 id="upload-form-heading">Upload Elevation Certificates</h1>
        </HeaderSection>
      ) : (
        <HeaderSection>
          <h1 id="upload-form-heading">Upload files</h1>
          <h2>Upload files for {fullAddress}</h2>
        </HeaderSection>
      )}
      <FormSection
        onSubmit={handleSubmit(onSubmit)}
        data-testid="form"
        overflows
      >
        <ContentSection overflows>
          <EmptyFileContainer>
            <div style={{ position: "relative" }}>
              <DropzoneComponent
                border={false}
                onDrop={onDrop}
                maxSizePerMimeType={MAX_SIZE_PER_MIME_TYPE_IN_MB}
                maxFiles={maxFiles}
                allowedMimeTypes={allowedMimeTypes}
                content={DropzoneContent}
                onDropRejected={onDropRejected}
                ariaDescribedBy={`${dropzoneTitleId} ${dropzoneSubtitleId}`}
                useDropzone={useDropzone}
              />
            </div>
            {!fields.length && (
              <Info>
                <Block icon={"limit"}>
                  You can upload as many as {maxFiles} files at a time. You will
                  select the file type after you upload file.
                </Block>
                <Block icon={"pages"}>
                  If you expect to export your PDFs for CRS documentation, make
                  sure your file contains all required pages, such as the
                  Engineered Flood Openings Certification.
                </Block>
                <Block icon={"notify"}>
                  Up to {MAX_SIZE_PER_MIME_TYPE_IN_MB["application/pdf"]}MB each
                  (PDFs, docs and images), or{" "}
                  {MAX_SIZE_PER_MIME_TYPE_IN_MB["video/mp4"]}MB (videos)
                </Block>
              </Info>
            )}
          </EmptyFileContainer>

          {!!fields.length && (
            <FilesTable>
              <Header>
                <tr>
                  <th style={{ width: "30px" }}></th>
                  <th>File name</th>
                  <th style={{ padding: "0 20px" }}>
                    File type<RequiredField>*</RequiredField>
                  </th>
                  <th style={{ padding: "0 20px" }}>Date</th>
                  <th style={{ width: "55px", textAlign: "right" }}>Remove</th>
                </tr>
              </Header>
              <tbody>
                {fields.map((item, i) => (
                  <DataRow
                    key={item.id}
                    error={
                      !!Object.keys(errors.documentUploads?.[i] || {}).length
                    }
                  >
                    <DesktopIcon>
                      <SuccessOrErrorIcon
                        success={
                          !Object.keys(errors.documentUploads?.[i] || {}).length
                        }
                      />
                    </DesktopIcon>
                    <NameColumn>
                      <MobileIcon>
                        <SuccessOrErrorIcon
                          success={
                            !Object.keys(errors.documentUploads?.[i] || {})
                              .length
                          }
                        />
                      </MobileIcon>
                      <Controller
                        control={control}
                        name={`documentUploads.${i}.blob`}
                        render={({ field, fieldState }) => {
                          return (
                            <FlexColumn style={{ flexGrow: 1 }}>
                              <FileName>
                                {truncate(
                                  field.value.path || field.value.name,
                                  {
                                    length: isMobile ? 40 : 100,
                                  }
                                )}
                              </FileName>
                              {fieldState.error && (
                                <BlobError>
                                  {fieldState.error.message}
                                </BlobError>
                              )}
                            </FlexColumn>
                          );
                        }}
                      />

                      <MobileIcon remove tabIndex={0}>
                        <Icon
                          icon={Icons.CIRCLE_X}
                          onClick={() => {
                            if (!isProcessingDocuments) remove(i);
                            clearErrors(`documentUploads.${i}`);
                          }}
                          style={{ display: "flex" }}
                        />
                      </MobileIcon>
                    </NameColumn>
                    <FieldColumn data-testid="document-type-select">
                      <Controller
                        control={control}
                        name={`documentUploads.${i}.accountDocumentTypeId`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            value={field.value}
                            name={field.name}
                            error={
                              !!errors.documentUploads?.[i]
                                ?.accountDocumentTypeId
                                ? "File type is required"
                                : undefined
                            }
                            onChange={accountDocumentTypeId => {
                              onChangeDocumentType({
                                onChange: field.onChange,
                                accountDocumentTypeId,
                                number: i,
                              });

                              if (accountDocumentTypeId) {
                                const accountDocumentType =
                                  idsToAccountDocumentTypes[
                                    accountDocumentTypeId
                                  ]![0];
                                const file = getValues(
                                  `documentUploads.${i}.blob`
                                );

                                if (
                                  !accountDocumentType.allowedMimeTypes.includes(
                                    file.type as MIME_TYPE
                                  )
                                ) {
                                  const fileExtensions =
                                    accountDocumentType.allowedMimeTypes.map(
                                      mime => mime.split("/")[1]!.toUpperCase()
                                    );
                                  setError(`documentUploads.${i}.blob`, {
                                    type: "string",
                                    message: `${
                                      accountDocumentType.name
                                    } must be a ${formatCollection(
                                      fileExtensions,
                                      "or"
                                    )}`,
                                  });
                                } else {
                                  clearErrors(`documentUploads.${i}.blob`);
                                }
                              }
                            }}
                            disabled={elevationCertificatesOnly}
                            options={accountDocumentTypes!.map(
                              accountDocumentType => ({
                                value: accountDocumentType.id,
                                label: accountDocumentType.name,
                              })
                            )}
                            placeholder="Select file type"
                            menuPlacement={isMobile ? "top" : "bottom"}
                          />
                        )}
                      />
                    </FieldColumn>
                    <FieldColumn>
                      <Controller
                        control={control}
                        name={`documentUploads.${i}.issuedAt`}
                        render={({ field }) => (
                          <Datepicker
                            disabled={
                              watch(
                                `documentUploads.${i}.accountDocumentTypeId`
                              ) === elevationCertificateType?.id
                            }
                            key={item.id}
                            name={field.name}
                            placeholderText={
                              watch(
                                `documentUploads.${i}.accountDocumentTypeId`
                              ) === elevationCertificateType?.id
                                ? "To be extracted"
                                : "Select date"
                            }
                            // Empty string needs to be treated as undefined
                            onChange={date => field.onChange(date || undefined)}
                            value={field.value}
                          />
                        )}
                      />
                    </FieldColumn>
                    <DesktopIcon data-testid="remove" remove tabIndex={0}>
                      <Icon
                        data-testid="remove"
                        icon={Icons.CIRCLE_X}
                        onClick={() => {
                          if (!isProcessingDocuments) remove(i);
                          clearErrors(`documentUploads.${i}`);
                        }}
                      />
                    </DesktopIcon>
                  </DataRow>
                ))}
              </tbody>
            </FilesTable>
          )}
        </ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button
              styleVariant="secondary"
              onClick={closeModal}
              disabled={isProcessingDocuments}
              size="medium"
            >
              Cancel
            </Button>
            <Button
              data-testid="upload"
              onClick={() => handleSubmit(onSubmit)} // uses html form submit
              disabled={
                !fields.length ||
                isProcessingDocuments ||
                Object.keys(errors).length > 0 ||
                !isValid
              }
              styleVariant="primary"
              size="medium"
            >
              {isProcessingDocuments ? "Uploading..." : "Upload files"}
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};

export default UploadDocumentsForm;
