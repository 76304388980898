import React from "react";

import { Switch } from "../Inputs";

import { DataRow, Header, Section, Toggle } from "./__styles__/Settings";
import { AuthContext } from "../Authorization/AuthContext";

interface DisplaySettingsProps {
  hiddenFromPublic: boolean;
  authorized: boolean;
  onChange: () => void;
  loading: boolean;
}

export default ({
  hiddenFromPublic,
  authorized,
  onChange,
  loading,
}: DisplaySettingsProps) => {
  const { account } = React.useContext(AuthContext);
  if (!account?.publicPortal.enabled) return null;

  const toggleText = hiddenFromPublic ? "Hidden" : "Displayed";

  return (
    <Section>
      <Header>Display Settings</Header>
      <DataRow>
        <div>Display file on public website?</div>
        <Toggle>
          <Switch
            label={toggleText}
            name="displayToggle"
            onChange={onChange}
            value={!hiddenFromPublic}
            size="tiny"
            disabled={loading || !authorized}
          />
        </Toggle>
      </DataRow>
    </Section>
  );
};
