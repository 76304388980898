import React from "react";
import * as Sentry from "@sentry/browser";
import { colors } from "../stitches.config";

type ErrorBoundaryState = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<
  {},
  ErrorBoundaryState
> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: {}) {
    Sentry.withScope(scope => {
      scope.setExtras({
        ...errorInfo,
        source: "default-error-boundary",
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // todo: error ui
      return (
        <div style={{ color: colors.contentPrimaryDark.toString() }}>
          Something went wrong
        </div>
      );
    }

    return this.props.children;
  }
}
