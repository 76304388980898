import React from "react";
import { Layer, Source } from "react-map-gl";
import { LayerContext } from ".";
import { FIRM } from "./firm";
import { colors } from "../../../stitches.config";

export const group = "crossSections";

export const getInteractiveCrossSectionLayerId = (firmId: string) => {
  return `${group}-${firmId}-clickable-area` as const;
};

class MapLayer extends React.PureComponent<{ firm: FIRM }> {
  render() {
    const { isLayerVisible } = this.context;
    const { firm } = this.props;

    const tiles = [
      `${window.env.APPLICATION_URL}/api/tiles/crossSections/${firm.id}/{z}/{x}/{y}`,
    ];

    const id = `${group}-${firm.id}`;

    return (
      <Source
        id={id}
        tiles={tiles}
        type="vector"
        //@ts-ignore
        //this ensures the feature.id is defined so we can use setFeatureState
        promoteId="id"
      >
        <Layer
          id={`${id}-lines`}
          type="line"
          source-layer="src"
          layout={{
            visibility: isLayerVisible({ group, id: firm.id })
              ? "visible"
              : "none",
          }}
          filter={["step", ["zoom"], false, 10, true]}
          paint={{
            "line-width": [
              "case",
              [
                "any",
                ["==", ["feature-state", "click"], true],
                ["==", ["feature-state", "hover"], true],
              ],
              3,
              2,
            ],
            "line-color": [
              "case",
              ["==", ["feature-state", "hover"], true],
              colors.mapHovered.value,
              ["==", ["feature-state", "click"], true],
              colors.mapSelected.value,
              colors.featureGrey.value,
            ],
          }}
        />
        <Layer
          id={`${id}-labels`}
          type="symbol"
          source-layer="src"
          layout={{
            "symbol-placement": "line",
            visibility: isLayerVisible({ group, id: firm.id })
              ? "visible"
              : "none",
            "text-field": ["get", "regulatoryWaterSurfaceElevation"],
            "text-padding": 10,
            "text-max-width": 15,
            "text-size": 15,
            "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
          }}
          filter={["step", ["zoom"], false, 12, true]}
          paint={{
            "text-color": colors.contentPrimaryDark.value,
            "text-halo-color": "hsla(0, 0%, 2%, 0.82)",
            "text-opacity": 1,
            "text-halo-width": 1,
          }}
        />
        <Layer
          id={`${id}-hexagon`}
          type="symbol"
          source-layer="src"
          layout={{
            visibility: isLayerVisible({ group, id: firm.id })
              ? "visible"
              : "none",
            "icon-image": "cross-section-hexagon",
            "icon-size": 1,
            "text-field": ["get", "letter"],
            "text-padding": 10,
            "text-size": 15,
            "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
            //must allow overlap in order for hover and click icons to show up when we change opacity
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
          }}
          filter={[
            "step",
            ["zoom"],
            false,
            12,
            ["to-boolean", ["get", "letter"]],
          ]}
          paint={{
            "text-color": colors.contentPrimaryDark.value,
            "text-halo-color": "hsla(0, 0%, 2%, 0.82)",
            "text-halo-width": 1,
            "icon-opacity": [
              "case",
              [
                "any",
                ["==", ["feature-state", "hover"], true],
                ["==", ["feature-state", "click"], true],
              ],
              0,
              1,
            ],
          }}
        />
        <Layer
          id={`${id}-hexagon-click`}
          type="symbol"
          source-layer="src"
          layout={{
            visibility: isLayerVisible({ group, id: firm.id })
              ? "visible"
              : "none",
            "icon-image": "cross-section-hexagon-select",
            "icon-size": 1,
            "text-field": ["get", "letter"],
            "text-padding": 10,
            "text-size": 15,
            "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
            //must allow overlap in order for hover and click icons to show up when we change opacity
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
          }}
          filter={[
            "step",
            ["zoom"],
            false,
            12,
            ["to-boolean", ["get", "letter"]],
          ]}
          paint={{
            "text-color": colors.contentPrimaryDark.value,
            "text-halo-color": "hsla(0, 0%, 2%, 0.82)",
            "text-halo-width": 1,
            "icon-opacity": [
              "case",
              ["==", ["feature-state", "click"], true],
              1,
              0,
            ],
          }}
        />
        <Layer
          id={`${id}-hexagon-hover`}
          type="symbol"
          source-layer="src"
          layout={{
            visibility: isLayerVisible({ group, id: firm.id })
              ? "visible"
              : "none",
            "icon-image": "cross-section-hexagon-hover",
            "icon-size": 1,
            "text-field": ["get", "letter"],
            "text-padding": 10,
            "text-size": 15,
            "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
            //must allow overlap in order for hover and click icons to show up when we change opacity
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
          }}
          filter={[
            "step",
            ["zoom"],
            false,
            12,
            ["to-boolean", ["get", "letter"]],
          ]}
          paint={{
            "text-color": colors.contentPrimaryDark.value,
            "text-halo-color": "hsla(0, 0%, 2%, 0.82)",
            "text-halo-width": 1,
            "icon-opacity": [
              "case",
              ["==", ["feature-state", "hover"], true],
              1,
              0,
            ],
          }}
        />
        <Layer
          id={getInteractiveCrossSectionLayerId(firm.id)!}
          type="line"
          source-layer="src"
          filter={["step", ["zoom"], false, 10, true]}
          paint={{
            "line-width": 30,
            "line-opacity": 0,
          }}
          layout={{
            visibility: isLayerVisible({ group, id: firm.id })
              ? "visible"
              : "none",
          }}
        />
      </Source>
    );
  }
}

MapLayer.contextType = LayerContext;

export default MapLayer;
