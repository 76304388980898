import React, { useContext } from "react";
import { every, get, isNil, map } from "lodash";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import Icon, { ICON_COLORS, Icons } from "../../../../../Common/Icons";

import { AuthContext } from "../../../../../Authorization/AuthContext";
import { Label } from "../__styles__/FIRMTable";
import {
  TableCell,
  TableRow,
} from "../../../../../Common/__styles__/StripedTable";
import { RenderMode } from "../../types";
import { FIRMInfoContext } from "../../FIRMInfoContext";

const Noop = ({ children }: { children?: React.ReactNode }) => (
  <span>{children || null}</span>
);

export const Blank = () => <span>-</span>;

export interface FIRMInfoRowProps<T> {
  wrap?: React.ComponentType<{ firmId: string }>;
  valueForFIRM?: (firm: FIRMWithWarnings) => Maybe<T>;
  property: string;
  format?: (value: T) => Maybe<string>;
  label: string;
  skipIfBlank?: boolean;
  isPublic: boolean;
  tooltip?: React.ReactNode;
  size: RenderMode;
  header?: boolean;
}

export const FIRMInfoRow = <T,>({
  wrap = Noop,
  valueForFIRM,
  property,
  format = (v: any) => v,
  label,
  skipIfBlank,
  isPublic,
  tooltip,
  header = false,
  size,
}: FIRMInfoRowProps<T>) => {
  const { isGuest } = useContext(AuthContext);
  const { activeFirmIds, firms: allFirms } = useContext(FIRMInfoContext);
  const firms = allFirms.filter(firm => activeFirmIds.includes(firm.id));

  if (isGuest && !isPublic) return null;

  const finalValueForFIRM =
    valueForFIRM ??
    function (firm) {
      return get(firm, property!);
    };

  const values = map(firms, finalValueForFIRM);

  if (skipIfBlank) {
    if (every(values, isNil)) return null;
  }

  const children = firms.map(firm => {
    const value = finalValueForFIRM(firm);
    const id = firm.id;
    const Wrapper = wrap;
    const key = `${property}-${id}`;

    return (
      <TableCell key={key} size={size} wrap={true}>
        {firm.warnings.some(warning => warning.property === property) && (
          <Icon
            icon={Icons.INFORMATION_CIRCLE}
            color={ICON_COLORS.YELLOW}
            style={{
              height: "12px",
              width: "12px",
              display: "inline-block",
              marginRight: "8px",
            }}
            data-testid="firmInfoWarningIcon"
          />
        )}
        <Wrapper firmId={id}>
          {isNil(value) ? <Blank /> : format(value)}
        </Wrapper>
      </TableCell>
    );
  });

  return (
    <TableRow header={header}>
      <TableCell containsTooltip>
        <div style={{ overflowX: "hidden", whiteSpace: "normal" }}>
          <Label size={size}>{label}</Label>
          {tooltip}
        </div>
      </TableCell>
      {children}
    </TableRow>
  );
};
