import React, { useContext, useState } from "react";
import Collapsible from "react-collapsible";
import {
  BLURRED_TABLE_DATA,
  REPETITIVE_LOSS_COLUMN_DATA,
  REPETITIVE_LOSS_DATE_COLUMN_DATA,
  RenderRepLossRowProps,
  makeBlurredTableDisclaimer,
} from "common-client/utils/repetitiveLoss";

import Loading from "../../../Common/Icons/Loading";
import { Button } from "../../../Common/Button";
import ArrowDown from "../../../../images/arrow-down-black.svg";
import ArrowUp from "../../../../images/arrow-up-black.svg";
import {
  GetRepetitiveLossDataQuery,
  RepetitiveLossDate,
  useGetRepetitiveLossDataLazyQuery,
} from "../../../../generated/graphql";
import { track } from "../../../../utils/tracking";
import { AuthContext } from "../../../Authorization/AuthContext";

import { Title } from "../../__styles__/AddressPanel";
import {
  DateOfLoss,
  LossDateToggle,
  RowValue,
  Table,
  TableSubTitle,
  TitleWrapper,
  Trigger,
  WarningBackground,
  WarningMessage,
  WarningWrapper,
} from "./__styles__/RepetitiveLossData";
import { BLANK } from "common/utils/strings";
import { AddressPanelContext } from "../../AddressPanelContext";
import { Account } from "../../../Authorization/types";

const Row = ({ columnName, value, formatter }: RenderRepLossRowProps) => {
  if (formatter) {
    value = formatter(value);
  }

  return (
    <li>
      <div>{columnName}</div>
      <RowValue>{value ?? BLANK}</RowValue>
    </li>
  );
};

const BlurredDummyData = () => {
  return (
    <Table blurred>
      {Object.entries(BLURRED_TABLE_DATA).map(([key, value]) => {
        return <Row columnName={key} value={value} key={key} />;
      })}
    </Table>
  );
};

const RepetitiveLossDateRow = ({
  repetitiveLossDate,
  number,
}: {
  repetitiveLossDate: RepetitiveLossDate;
  number: number;
}) => {
  const [show, toggleShow] = React.useState(false);

  const trigger = (
    <Trigger>
      <div>{`Date Of Loss ${number}`}</div>
      <LossDateToggle>
        {repetitiveLossDate.dateOfLoss}
        <img src={show ? ArrowUp : ArrowDown} />
      </LossDateToggle>
    </Trigger>
  );

  return (
    <DateOfLoss open={show}>
      <Collapsible
        trigger={trigger}
        transitionTime={100}
        onOpen={() => {
          toggleShow(true);
        }}
        onClose={() => {
          toggleShow(false);
        }}
      >
        <RepetitiveLossDateTable repetitiveLossDate={repetitiveLossDate} />
      </Collapsible>
    </DateOfLoss>
  );
};

const RepetitiveLossDateTable = ({
  repetitiveLossDate,
}: {
  repetitiveLossDate: RepetitiveLossDate;
}) => {
  return (
    <Table indented>
      {REPETITIVE_LOSS_DATE_COLUMN_DATA.map(column => {
        return (
          <Row
            columnName={column.columnName}
            value={repetitiveLossDate[column.key]}
            key={column.key}
            formatter={column.formatter}
          />
        );
      })}
    </Table>
  );
};

type RepetitiveLoss = NonNullable<
  NonNullable<GetRepetitiveLossDataQuery["property"]>["repetitiveLoss"]
>;

const RepetitiveLossTable = ({
  repetitiveLoss,
}: {
  repetitiveLoss: RepetitiveLoss;
}) => {
  const { repetitiveLossDates } = repetitiveLoss;

  return (
    <Table>
      {REPETITIVE_LOSS_COLUMN_DATA.map(column => {
        return (
          <Row
            columnName={column.columnName}
            value={repetitiveLoss[column.key]}
            key={column.key}
            formatter={column.formatter}
          />
        );
      })}
      <TableSubTitle>Losses</TableSubTitle>
      {repetitiveLossDates.map((repetitiveLossDate, i) => (
        <RepetitiveLossDateRow
          repetitiveLossDate={repetitiveLossDate}
          number={i + 1}
          key={i + 1}
        />
      ))}
    </Table>
  );
};

export const RepetitiveLossData = ({
  section,
}: {
  section: Account["propertyInformationSections"][number];
}) => {
  const { user, account } = useContext(AuthContext);
  const { property } = useContext(AddressPanelContext);
  const propertyId = property!.id;

  const [repetitiveLossDataVisible, setRepetitiveLossVisible] = useState(false);

  const [getRepetitiveLossData, { loading, data }] =
    useGetRepetitiveLossDataLazyQuery({
      fetchPolicy: "network-only",
    });

  const toggleData = () => {
    if (!repetitiveLossDataVisible) {
      void getRepetitiveLossData({ variables: { propertyId } });
    }
    setRepetitiveLossVisible(!repetitiveLossDataVisible);
    track(
      `${repetitiveLossDataVisible ? "Hide" : "View"} Repetitive Loss Data`,
      {
        email: user?.email,
        name: `${user?.firstName} ${user?.lastName}`,
        account: account?.name,
      }
    );
  };

  return (
    <>
      <TitleWrapper>
        <Title>{section.label}</Title>
        <Button
          styleVariant="primary"
          onClick={toggleData}
          data-testid={"toggle-data"}
          size="small"
          leftIconName={repetitiveLossDataVisible ? "eye-off" : "eye"}
          style={{ minWidth: "120px" }}
        >
          {repetitiveLossDataVisible ? "Hide" : "View"} data
        </Button>
      </TitleWrapper>
      {repetitiveLossDataVisible && data && !loading ? (
        <RepetitiveLossTable repetitiveLoss={data.property!.repetitiveLoss!} />
      ) : (
        <WarningWrapper>
          <WarningMessage>
            {loading ? (
              <Loading />
            ) : (
              makeBlurredTableDisclaimer({
                publicPortal: account?.publicPortal,
              })
            )}
          </WarningMessage>
          <WarningBackground />
          <BlurredDummyData />
        </WarningWrapper>
      )}
    </>
  );
};
