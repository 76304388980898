import React from "react";

import "./__styles__/Icon.scss";

const ExportLink = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.293 2.293L4.586 6L6 7.414L9.707 3.707L12 6V0H6L8.293 2.293Z"
        fill="black"
      />
      <path
        d="M10 10H2V2H4V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 10C0 10.5304 0.210714 11.0391 0.585786 11.4142C0.960859 11.7893 1.46957 12 2 12H10C10.5304 12 11.0391 11.7893 11.4142 11.4142C11.7893 11.0391 12 10.5304 12 10V8H10V10Z"
        fill="black"
      />
    </svg>
  );
};

export default ExportLink;
