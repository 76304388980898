import React, { JSXElementConstructor, ReactElement, useRef } from "react";
import Modal, { Props } from "react-modal";

import { useOutsideAlerter } from "../../utils/outsideAlerter";

import { CloseIcon, modalOverlay } from "./__styles__/Modal";

interface ModalProps extends Omit<Props, "isOpen"> {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}

export default ({
  children,
  customAppElement = "#app",
  ...modalProps
}: ModalProps & { customAppElement?: string }) => {
  Modal.setAppElement(customAppElement);

  modalOverlay();

  const wrapperRef = useRef(null as Maybe<HTMLDivElement>);

  useOutsideAlerter(wrapperRef, () => modalProps.onRequestClose);

  return (
    <div ref={wrapperRef}>
      <Modal
        className="modal-content"
        overlayClassName="modal-overlay"
        shouldFocusAfterRender={false}
        shouldCloseOnEsc={true}
        isOpen
        {...modalProps}
      >
        {!!modalProps.onRequestClose && (
          <CloseIcon
            onClick={modalProps.onRequestClose}
            name="close-modal"
            aria-label="Close"
            data-testid="closeModal"
          />
        )}
        {React.cloneElement(children, {
          closeModal: modalProps.onRequestClose,
        })}
      </Modal>
    </div>
  );
};
