import { v4 as uuidv4 } from "uuid";
import React, { useContext, useRef } from "react";

import { WatchQueryFetchPolicy } from "@apollo/client";

import ActionButton from "./ActionButton";
import HeaderNav from "../Common/HeaderNav";
import Tabs from "./Tabs";
import { useGetDocumentUploadQuery } from "../../generated/graphql";
import { RouteComponentProps, useLocation } from "react-router";

import {
  ADDRESS_PANEL_TAB_NAME,
  buildLink,
  getPath,
  pipLink,
} from "common/routing";
import {
  Container,
  Content,
  FileViewer,
  IFrame,
  Image,
  Sidebar,
} from "./__styles__/DetailView";
import { MIME_TYPE } from "common/constants";
import { AuthContext } from "../Authorization/AuthContext";
import { setTitle } from "../../utils/title";
import { captureMessage } from "@sentry/browser";
import AccessDeniedPage, {
  ACCESS_DENIED_RESOURCE,
} from "../Guest/AccessDeniedPage";

export type DetailViewProps = {
  fetchPolicy?: WatchQueryFetchPolicy;
  generateUuid?: typeof uuidv4;
} & Partial<Pick<RouteComponentProps<{ id: string }>, "match">> & {
    documentUploadId?: string;
  };

const getPreviousLocation = (
  isCertificate: boolean,
  property?: Maybe<{
    id: string;
    fullAddress?: Maybe<string>;
    latitude?: Maybe<number>;
    longitude?: Maybe<number>;
  }>
) => {
  return isCertificate
    ? buildLink("documentUploads")
    : pipLink({
        propertyId: property?.id,
        address: property?.fullAddress ?? "",
        lat: property?.latitude?.toString() ?? "",
        lng: property?.longitude?.toString() ?? "",
        tab: ADDRESS_PANEL_TAB_NAME.DOCUMENT_UPLOADS,
      });
};

// we allow for the overriding of fetch policy because `cache-and-network`
// does not appear to work in tests: https://github.com/apollographql/apollo-client/issues/6803
export default ({
  match,
  fetchPolicy = "cache-and-network",
  documentUploadId,
  generateUuid = uuidv4,
}: DetailViewProps) => {
  const location = useLocation<{ prevLocation: string }>();
  const { account, isGuest } = useContext(AuthContext);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const isCertificate = !!documentUploadId;
  const documentUploadKey = useRef(generateUuid());

  const { data, error, loading, refetch } = useGetDocumentUploadQuery({
    variables: { id: documentUploadId ?? match!.params.id, isGuest },
    fetchPolicy,
  });

  if (loading && !data?.documentUpload) return null;

  if (!data?.documentUpload || error)
    return <AccessDeniedPage resource={ACCESS_DENIED_RESOURCE.FILE} />;

  const documentUpload = data.documentUpload;
  const property = documentUpload.property;

  if (isGuest) {
    setTitle(`File | ${documentUpload.originalFilename}`);

    // For now, we only want to explose this view to ECs
    if (!documentUpload.certificateUpload) {
      captureMessage(`A guest attempted to view a non-EC in detail view`);
      return null;
    }
  }

  const mimeType = documentUpload.mimeType as MIME_TYPE;

  const fileUrl = buildLink("documentUploadFile", { id: documentUpload.id });

  const actionButtonProps = {
    isDetailView: true,
    onUpdate: refetch,
    documentUpload,
  };

  const actionButton = <ActionButton {...actionButtonProps} />;

  const defaultPrevLocation = isGuest
    ? getPath("guestHome")
    : getPreviousLocation(isCertificate, property);

  const prevLocation = location?.state?.prevLocation || defaultPrevLocation;

  let viewDocumentComponent: JSX.Element;

  if ([MIME_TYPE.JPEG, MIME_TYPE.JPG, MIME_TYPE.PNG].includes(mimeType)) {
    viewDocumentComponent = <Image src={fileUrl} data-testid="document-img" />;
  } else {
    viewDocumentComponent = (
      <IFrame
        // editing a generated document will not re-render the iframe because the fileUrl doesn't change
        // using a unique documentUploadKey in the url will force a re-render of the iframe
        // we have to use a ref here and not state because changing state will force the parent
        // to re-render and then the iframe to re-render which looks like a duplicate re-render to the user
        src={`${fileUrl}?nonce=${documentUploadKey.current}`}
        data-testid="document-iframe"
      />
    );
  }

  const refetchAndSetKey = async () => {
    documentUploadKey.current = generateUuid();
    await refetch();
  };

  return (
    <Container data-testid="details-view">
      <HeaderNav
        subtitle={documentUpload.originalFilename}
        prevLocation={prevLocation}
        rightContainer={actionButton}
      />
      <Content>
        <Sidebar ref={sidebarRef}>
          <Tabs
            documentUpload={documentUpload}
            sidebarRef={sidebarRef}
            account={account!}
            fileUrl={fileUrl}
            prevLocation={prevLocation}
            onUpdate={refetchAndSetKey}
          />
        </Sidebar>
        <FileViewer>{viewDocumentComponent}</FileViewer>
      </Content>
    </Container>
  );
};
