import React from "react";
import { Divider } from "./__styles__/Divider";

interface Divider {
  margins?: string;
  extension?: 8 | 16;
}

export default ({ margins, extension }: Divider) => {
  return <Divider style={{ margin: margins }} extension={extension} />;
};
