import React from "react";
import { Category } from "common-client/utils/Search";

import Icon, { ICON_COLORS, Icons } from "../Common/Icons";

import { Dropdown, IconWrapper, CategoryOption } from "./__styles__/Dropdown";

export const CategoryDropdown = ({
  categories,
  selectedCategory,
  onClear,
  reset,
  setSelectedCategory,
}: {
  categories: Array<Category>;
  selectedCategory: Category;
  onClear: () => void;
  reset: () => void;
  setSelectedCategory: (category: Category) => void;
}) => {
  const onCategorySelect = (category: Category) => {
    setSelectedCategory(category);
    reset();
    onClear();
  };

  return (
    <Dropdown data-testid="search-dropdown">
      {categories.map((category, index) => (
        <CategoryOption
          selected={category.value === selectedCategory.value}
          key={index}
          onClick={() => onCategorySelect(category)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              onCategorySelect(category);
            }
          }}
          tabIndex={0}
        >
          {category.value === selectedCategory.value && (
            <IconWrapper icon="checkmark" iconPosition="left">
              <Icon icon={Icons.CHECKMARK} color={ICON_COLORS.BLUE} />
            </IconWrapper>
          )}
          {category.label}
        </CategoryOption>
      ))}
    </Dropdown>
  );
};
