import React from "react";

import "./__styles__/Icon.scss";

const HouseTrendingUp = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.88562 3.41511L7.87762 3.40878L4.21095 0.408779C4.15141 0.360093 4.07686 0.333496 3.99995 0.333496C3.92304 0.333496 3.84849 0.360093 3.78895 0.408779L0.122285 3.40878C0.0553935 3.46522 0.0133826 3.5457 0.00532341 3.63285C-0.00273582 3.72 0.0238032 3.80682 0.0792102 3.87457C0.134617 3.94232 0.214446 3.98556 0.301461 3.99495C0.388476 4.00435 0.475695 3.97914 0.544285 3.92478L0.999952 3.55211V7.33345C0.999952 7.42185 1.03507 7.50664 1.09758 7.56915C1.16009 7.63166 1.24488 7.66678 1.33329 7.66678H6.66662C6.75502 7.66678 6.83981 7.63166 6.90232 7.56915C6.96483 7.50664 6.99995 7.42185 6.99995 7.33345V3.55211L7.45562 3.92478C7.52321 3.9818 7.61067 4.00964 7.69879 4.00217C7.7869 3.9947 7.86843 3.95253 7.92545 3.88495C7.98247 3.81736 8.01031 3.72989 8.00284 3.64178C7.99537 3.55367 7.95321 3.47213 7.88562 3.41511ZM5.99995 5.33345L5.40229 4.73578L3.99995 6.13811L3.33329 5.47145L2.33329 6.47145L1.86195 6.00011L3.33329 4.52878L3.99995 5.19545L4.93095 4.26445L4.33329 3.66678H5.99995V5.33345Z"
        fill="white"
      />
    </svg>
  );
};

export default HouseTrendingUp;
