import React from "react";
import { SUBMISSION_TYPE_MODULE } from "common/constants";
import { SDESummary } from "./SDESummary";
import {
  Property,
  Submission,
  SubmissionType,
  SubmissionTypeVersion,
} from "../../../generated/graphql";
import {
  SDEForm,
  SISDForm,
  buildSubstantialDamageFromSubstantialDamageEstimate,
} from "common/services/formBuilderService";
import { entries, isEqual } from "lodash";
import { MODULE_CONFIGURATIONS as COMMON_MODULE_CONFIGURATIONS } from "common-client/utils/submissions";
import {
  ADDRESS_PANEL_TAB_NAME,
  RECORDS_TAB_NAME,
  buildLink,
  pipLink,
} from "common/routing";
import { ActionsProps } from "../../Inputs/DropdownMenu";
import { useSISDSubmissionGateModal } from "./SISDSubmissionGateModal";

type RelatedSubmissions = Array<{
  id: string;
  submissionTypeVersion: {
    submissionType: {
      modules: Array<SUBMISSION_TYPE_MODULE>;
      name: string;
    };
  };
}>;

export type SummaryProps = {
  submission: Pick<Submission, "id" | "summaries"> & {
    documentUploads: Array<
      Pick<Submission["documentUploads"][number], "id" | "originalFilename">
    >;
    formData: SDEForm;
    submissionTypeVersion: {
      formStructure: SubmissionTypeVersion["formStructure"];
    };
  };
};

export type DisclaimerBuilder = (_: {
  user: { accountId: string; isAdmin: boolean };
  relatedSubmissions: RelatedSubmissions;
}) => Array<string>;

export type ActionBuilder = (_: {
  submission: Pick<Submission, "formData" | "summaries" | "id"> & {
    relatedSubmissions: RelatedSubmissions;
  };
  property?: Maybe<
    Pick<Property, "latitude" | "longitude" | "id" | "fullAddress">
  >;
  submissionTypes: Array<Pick<SubmissionType, "modules" | "id">>;
}) => Array<ActionsProps>;

type SubmissionGate<T extends Submission["formData"]> = (_: {
  onComplete: (_: { formData: T; previousFormData: Maybe<T> }) => void;
  formData: T;
  previousFormData: Maybe<T>;
  relatedSubmissions: RelatedSubmissions;
}) => void;

export type SubmitGateBuilder<
  T extends Submission["formData"] = Submission["formData"]
> = () => SubmissionGate<T>;

const MODULE_SUMMARIES: Partial<
  Record<SUBMISSION_TYPE_MODULE, React.ComponentType<SummaryProps>>
> = {
  [SUBMISSION_TYPE_MODULE.SUBSTANTIAL_DAMAGE_ESTIMATE]: SDESummary,
};

const MODULE_DISCLAIMERS: Partial<
  Record<SUBMISSION_TYPE_MODULE, DisclaimerBuilder>
> = {
  [SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE]: ({
    relatedSubmissions,
    user,
  }) => {
    const relatedSDESubmission = relatedSubmissions.find(submission => {
      return submission.submissionTypeVersion.submissionType.modules.includes(
        SUBMISSION_TYPE_MODULE.SUBSTANTIAL_DAMAGE_ESTIMATE
      );
    });

    if (relatedSDESubmission) {
      const link = buildLink(
        "viewSubmission",
        {
          submissionId: relatedSDESubmission.id,
        },
        {},
        {
          admin: user.isAdmin,
          accountId: user.accountId,
        }
      );

      const title =
        relatedSDESubmission.submissionTypeVersion.submissionType.name;

      return [
        `This damage cost was created from a [${title}](${link}) record. ` +
          `Any edits made to this damage cost will not be reflected on the original estimate. ` +
          `Please note that the base cost of the structure does not include the depreciation outlined in the associated SDE.`,
      ];
    }

    return [];
  },
};

const MODULE_SUMMARY_ACTIONS: Partial<
  Record<SUBMISSION_TYPE_MODULE, ActionBuilder>
> = {
  [SUBMISSION_TYPE_MODULE.SUBSTANTIAL_DAMAGE_ESTIMATE]: ({
    submission,
    property,
    submissionTypes,
  }) => {
    const relatedSISDSubmission = submission.relatedSubmissions.find(
      submission => {
        return submission.submissionTypeVersion.submissionType.modules.includes(
          SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
        );
      }
    );

    const sisdSubmissionType = submissionTypes.find(submissionType =>
      submissionType.modules.includes(
        SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
      )
    );

    const sdeSummary = submission.summaries.find(summary => {
      return summary.__typename === "SDESubmissionSummary";
    });

    if (
      relatedSISDSubmission ||
      !sisdSubmissionType ||
      !property ||
      !sdeSummary
    ) {
      return [];
    }

    const { pathname, search } = pipLink({
      lat: property.latitude,
      lng: property.longitude,
      address: property.fullAddress,
      propertyId: property.id,
      tab: ADDRESS_PANEL_TAB_NAME.RECORDS,
      recordTab: RECORDS_TAB_NAME.IMPROVEMENTS,
    });

    return [
      {
        label: "Create SD record",
        to: {
          pathname: buildLink("createSubmission", {
            submissionTypeId: sisdSubmissionType.id,
            propertyId: property.id,
          }),
          state: {
            prevLocation: `${pathname}?${search}`,
            formData: buildSubstantialDamageFromSubstantialDamageEstimate({
              summary: sdeSummary,
              sde: { formData: submission.formData },
            }),
            relatedSubmissionId: submission.id,
          },
        },
      },
    ];
  },
};

const MODULE_SUBMIT_GATES: Partial<
  Record<SUBMISSION_TYPE_MODULE, SubmitGateBuilder>
> = {
  [SUBMISSION_TYPE_MODULE.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE]:
    (): SubmissionGate<SISDForm> => {
      const [showUploadModal] = useSISDSubmissionGateModal();

      return ({
        previousFormData,
        formData,
        onComplete,
        relatedSubmissions,
      }) => {
        const relatedSDESubmission = relatedSubmissions.find(submission => {
          return submission.submissionTypeVersion.submissionType.modules.includes(
            SUBMISSION_TYPE_MODULE.SUBSTANTIAL_DAMAGE_ESTIMATE
          );
        });

        const sisdExcluded =
          previousFormData?.excluded !== formData.excluded && formData.excluded;

        const sisdValueChanged =
          previousFormData &&
          relatedSDESubmission &&
          (previousFormData.value !== formData.value ||
            !isEqual(
              previousFormData.propertyMarketValue,
              formData.propertyMarketValue
            ));

        if (sisdExcluded || sisdValueChanged) {
          showUploadModal({
            formData,
            onComplete: updatedFormData => {
              onComplete({ formData: updatedFormData, previousFormData });
            },
          });
        } else {
          onComplete({ formData, previousFormData });
        }
      };
    },
};

const defaultSubmitGate: SubmitGateBuilder =
  () =>
  ({ onComplete, formData, previousFormData }) =>
    onComplete({ formData, previousFormData });

export const MODULE_CONFIGURATIONS = entries(
  COMMON_MODULE_CONFIGURATIONS
).reduce(
  (acc, [module, processor]) => {
    return {
      ...acc,
      [module]: {
        ...processor,
        summaryComponent: MODULE_SUMMARIES[module as SUBMISSION_TYPE_MODULE],
        buildDisclaimers:
          MODULE_DISCLAIMERS[module as SUBMISSION_TYPE_MODULE] ?? (() => []),
        buildActions:
          MODULE_SUMMARY_ACTIONS[module as SUBMISSION_TYPE_MODULE] ??
          (() => []),
        buildSubmitGate:
          MODULE_SUBMIT_GATES[module as SUBMISSION_TYPE_MODULE] ??
          defaultSubmitGate,
      },
    };
  },
  {} as Record<
    SUBMISSION_TYPE_MODULE,
    typeof COMMON_MODULE_CONFIGURATIONS[SUBMISSION_TYPE_MODULE] & {
      summaryComponent?: React.ComponentType<SummaryProps>;
      buildDisclaimers: DisclaimerBuilder;
      buildActions: ActionBuilder;
      buildSubmitGate: SubmitGateBuilder;
    }
  >
);
