import React, { useCallback, useEffect, useState } from "react";

export const useWindowSize = () => {
  const [size, setSize] = React.useState({ width: 0, height: 0 });

  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};

export const useIsKeyboardOpen = () => {
  //if the document height is less than the window height the virtual keyboard is open:
  //https://twitter.com/rikschennink/status/1361598959828037633
  return useDocumentHeight() < window.innerHeight;
};

const useDocumentHeight = () => {
  const getHeight = useCallback(
    () =>
      window.visualViewport ? window.visualViewport.height : window.innerHeight,
    []
  );
  const [height, setHeight] = useState<number>(getHeight());

  useEffect(() => {
    const handleResize = () => {
      setHeight(getHeight());
    };

    //call handleResize on resize and orientationchange events
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    window.visualViewport?.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
      window.visualViewport?.removeEventListener("resize", handleResize);
    };
  }, [getHeight]);

  return height;
};
