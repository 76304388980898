import React from "react";
import cx from "classnames";

import { PROJECT_TRACKING } from "common/constants";
import { formatCurrency, pluralize } from "common/utils/strings";

import "./__styles__/Summary.scss";

type Status = "under" | "close" | "over";

export interface SummaryProps {
  projectThresholdPercentage: number;
  projectTracking: string;
  cumulativeTrackingPeriod: Maybe<number | undefined>;
  improvementTotal: number;
  cumulativeImprovementProgress?: Maybe<number>;
  status?: Status;
}
const Summary = ({
  projectThresholdPercentage,
  projectTracking,
  cumulativeTrackingPeriod,
  improvementTotal,
  cumulativeImprovementProgress,
  status,
}: SummaryProps) => {
  const threshold = (projectThresholdPercentage ?? 0) * 100;
  const progress = (cumulativeImprovementProgress ?? 0) * 100;
  const trackByProject = projectTracking === PROJECT_TRACKING.PER_PROJECT;
  const projectTrackingPrefix = trackByProject ? "" : "Cumulative ";
  const percentageSummaryText = `${projectTrackingPrefix}SI/SD Percentage (%)`;
  const costSummaryText = `${projectTrackingPrefix}SI/SD Costs ($)`;

  // Cap to 100% to avoid stretching the progress bar
  const progressFill = Math.min(100, progress);

  return (
    <table styleName="summary" data-testid="sisd-summary">
      <tbody>
        <tr>
          <td colSpan={3}>
            <div styleName="progress-bar">
              <div
                styleName={cx("fill", status)}
                style={{ width: `${progressFill}%` }}
              />
              <div styleName="threshold" style={{ left: `${threshold}%` }} />
            </div>
          </td>
        </tr>
        <tr>
          <td tabIndex={0}>{percentageSummaryText}</td>
          <td></td>
          <td styleName={cx("total-costs", status)} tabIndex={0}>
            {progress.toFixed(2)}%
          </td>
        </tr>
        <tr>
          <td tabIndex={0}>{costSummaryText}</td>
          <td></td>
          <td styleName={cx("total-costs", status)} tabIndex={0}>
            {formatCurrency(improvementTotal)}
          </td>
        </tr>
        {projectTracking === PROJECT_TRACKING.CUMULATIVE &&
          cumulativeTrackingPeriod != null && (
            <tr>
              <td tabIndex={0}>Tracking period</td>
              <td></td>
              <td tabIndex={0}>
                {cumulativeTrackingPeriod}{" "}
                {pluralize({
                  count: cumulativeTrackingPeriod,
                  options: { singular: "year", plural: "years" },
                })}
              </td>
            </tr>
          )}
      </tbody>
    </table>
  );
};

export default Summary;
