import React from "react";

import "./__styles__/Icon.scss";

const PDF = () => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 0.5L10.5 2.5L3.5 9.5L0.5 10.5L1.5 7.5L8.5 0.5Z"
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PDF;
