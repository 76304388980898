import React, { useMemo } from "react";

import AccountBoundaryLayer, { id as layerId } from "../layers/accountBoundary";
import {
  findRelevantFeature,
  LayerConfig,
  LayerHook,
  LayerHookBuilder,
  Point,
} from "./types";
export type AccountBoundaryLayerConfig = LayerConfig<never, Point>;

const accountBoundaryHook: LayerHookBuilder<AccountBoundaryLayerConfig> = ({
  config,
}) => {
  return useMemo<LayerHook>(() => {
    return {
      zIndex: 1,
      interactiveLayerIds: [layerId],
      render: ({ account }) => {
        return (
          <AccountBoundaryLayer
            key={"accountBoundary"}
            accountId={account.id}
          />
        );
      },
      canHandleClick: event => {
        return !!(
          config.interactive?.click &&
          findRelevantFeature(event, "accountBoundary")
        );
      },
      onClick: ({ event }) => {
        const [longitude, latitude] = event.lngLat;
        if (latitude && longitude) {
          config.onClick?.({ latitude, longitude });
          return true;
        } else {
          return false;
        }
      },
    };
  }, [config]);
};

export default accountBoundaryHook;
