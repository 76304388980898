import React, { useContext, useEffect } from "react";

import List from "./List";
import Summary from "./Summary";
import { Warning } from "../Warnings";
import MissingProperty from "../MissingProperty";
import { track } from "../../../utils/tracking";
import {
  DisclaimerName,
  PropertyWarning,
  PropertyWarningGroup,
  SubmissionCategory,
  SubmissionModule,
  useGetImprovementsForMapQuery,
} from "../../../generated/graphql";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import { isApplicableImprovementWarning } from "common/services/warningsHelpers";
import { AddressPanelTabProps } from "..";
import {
  DEFAULT_GUEST_SISD_DISCLAIMER,
  DEFAULT_USER_SISD_DISCLAIMER,
  getDisclaimerText,
} from "common-client/utils/disclaimerOverrides";

import { HeaderContainer, SummaryInfo, Title } from "./__styles__";
import Disclaimer from "../../Common/Disclaimer";
import { CreateSubmissionButton } from "../../Common/CreateSubmissionButton";
import { EmptyState } from "../../Common/EmptyState";
import { AddressPanelContext } from "../AddressPanelContext";

type Warnings = Array<
  NonNullable<Pick<PropertyWarning, "severity" | "title" | "message">>
>;

const Warnings = ({ warnings }: { warnings: Warnings }) => {
  if (!warnings.length) return null;

  return (
    <>
      <HeaderContainer>
        <Title tabIndex={0}>Warnings</Title>
      </HeaderContainer>
      {warnings.map(warning => (
        <Warning key={warning.title} {...warning} />
      ))}
    </>
  );
};

const Improvements = ({
  address,
  latitude,
  longitude,
  publicOnly = false,
}: AddressPanelTabProps & {
  publicOnly?: boolean;
}) => {
  const { isGuest, account, authorized } = useContext(AuthContext);
  const { property: contextProperty, loading: loadingPropertyId } =
    useContext(AddressPanelContext);
  const propertyId = contextProperty?.id;

  const { data, loading, refetch, error } = useGetImprovementsForMapQuery({
    variables: {
      propertyId: propertyId!,
      publicOnly,
    },
    fetchPolicy: "cache-and-network",
    skip: loadingPropertyId || !propertyId,
  });

  useEffect(() => {
    track("Opened improvements", { address });
  }, [address]);

  if (loading || loadingPropertyId) return <em>Loading...</em>;

  if (error || !data)
    return <em>Problem loading improvements and damage entries.</em>;

  const property = data.property;
  if (!property) {
    return (
      <MissingProperty
        title="SI/SD Tracking is not available for this property"
        preamble="SI/SD tracking is unfortunately not available for properties that do not have a street address"
        address={address}
        latitude={latitude}
        longitude={longitude}
      />
    );
  }

  const disclaimer = getDisclaimerText({
    disclaimerOverrides: account?.disclaimerOverrides,
    name: DisclaimerName.SISD,
    isPublic: isGuest,
    defaultDisclaimers: {
      public: DEFAULT_GUEST_SISD_DISCLAIMER,
      internal: DEFAULT_USER_SISD_DISCLAIMER,
    },
  });

  if (!property.sisd.rule) {
    const message = isGuest
      ? "Substantial Improvements and Damages are not currently tracked for this property"
      : "SI/SD tracking is not configured for this property";
    return (
      <div style={{ marginBottom: "32px" }}>
        <EmptyState message={message} />{" "}
        <Disclaimer message={disclaimer} testId={"sisd-disclaimer"} />
      </div>
    );
  }

  const summary = property.sisd;
  const warnings = property.warnings.filter(
    warning =>
      isApplicableImprovementWarning(warning) ||
      (warning.group === PropertyWarningGroup.PRELIMINARY_DAMAGE_ASSESSMENT &&
        warning.applies)
  );

  const canCreateImprovement = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "create",
  });

  const showSummary = isGuest ? !account?.publicPortal.hideSISD : true;

  return (
    <div style={{ overflow: "hidden" }}>
      <Warnings warnings={warnings} />
      <HeaderContainer topSpacing={!!warnings.length}>
        <Title>Improvements & Damages</Title>
        {!isGuest && (
          <CreateSubmissionButton
            disabled={!canCreateImprovement || loadingPropertyId}
            address={address}
            latitude={latitude}
            longitude={longitude}
            propertyId={propertyId!}
            submissionText="SI/SD"
            submissionTypeFilter={submissionType =>
              submissionType.category ===
                SubmissionCategory.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE &&
              submissionType.modules.includes(
                SubmissionModule.SUBSTANTIAL_IMPROVEMENT_SUBSTANTIAL_DAMAGE
              )
            }
          />
        )}
      </HeaderContainer>
      <List
        address={address}
        improvements={summary.improvements}
        onUpdate={refetch}
      />
      {showSummary && (
        <SummaryInfo>
          <HeaderContainer>
            <Title tabIndex={0}>Summary</Title>
          </HeaderContainer>
          <Summary
            projectThresholdPercentage={property.sisd.rule!.threshold}
            projectTracking={property.sisd.rule!.trackingPeriod}
            cumulativeTrackingPeriod={
              property.sisd.rule!.cumulativeTrackingLength
            }
            improvementTotal={summary.improvementTotal}
            cumulativeImprovementProgress={
              summary.cumulativeImprovementProgress
            }
            status={summary.status}
          />
        </SummaryInfo>
      )}

      <Disclaimer message={disclaimer} testId={"sisd-disclaimer"} />
    </div>
  );
};

export default Improvements;
