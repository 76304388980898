import React from "react";
import { Layer, Source } from "react-map-gl";

import { LayerContext } from ".";
import { Firm as GraphQLFIRM } from "../../../generated/graphql";
import {
  generateFloodzoneLayerPaint,
  generateFloodzoneOutlineLayerPaint,
  generateFloodzoneLabelLayer,
  generateFloodzoneLabelLayerPaint,
} from "common/utils/maps/index";
import { FLOODZONES_SOURCE_LAYER } from "common/utils/maps/constants";
export type FIRM = Pick<GraphQLFIRM, "id" | "tileset"> & {
  components: Pick<GraphQLFIRM["components"], "limwas">;
};
export const group = "firms";

class MapLayer extends React.PureComponent<{ firm: FIRM }> {
  render() {
    const { isLayerVisible } = this.context;
    const { firm } = this.props;
    const { tileset, id, components } = firm;

    return (
      <React.Fragment>
        <Source
          id={tileset.id}
          url={`mapbox://${tileset.mapboxId}`}
          type="vector"
          minzoom={10}
          maxzoom={16}
        >
          <Layer
            id={`${id}-${FLOODZONES_SOURCE_LAYER}`}
            type="fill"
            source-layer={FLOODZONES_SOURCE_LAYER}
            layout={{
              visibility: isLayerVisible({ group, id }) ? "visible" : "none",
            }}
            paint={generateFloodzoneLayerPaint()}
            beforeId="ground"
          ></Layer>
          <Layer
            id={`${id}-${FLOODZONES_SOURCE_LAYER}-outlines`}
            type="line"
            source-layer="floodzones"
            layout={{
              visibility: isLayerVisible({ group, id }) ? "visible" : "none",
            }}
            paint={generateFloodzoneOutlineLayerPaint()}
            beforeId="ground"
          ></Layer>
          <Layer
            id={`${id}-floodways`}
            type="fill"
            source-layer="floodzones"
            layout={{
              visibility: isLayerVisible({ group, id }) ? "visible" : "none",
            }}
            filter={["==", ["get", "floodway"], true]}
            paint={{ "fill-pattern": "shape-line-reversed" }}
            beforeId="ground"
          ></Layer>
          <Layer
            id={`${id}-labels`}
            type="symbol"
            source-layer="floodzones"
            layout={{
              visibility: isLayerVisible({ group, id }) ? "visible" : "none",
              ...generateFloodzoneLabelLayer(),
            }}
            paint={generateFloodzoneLabelLayerPaint()}
          ></Layer>
          {components.limwas.hasGeometries ? (
            <Layer
              id={`${id}-limwa-lines`}
              source-layer="limwa"
              type="line"
              layout={{
                visibility: isLayerVisible({ group, id }) ? "visible" : "none",
              }}
              paint={{ "line-color": "hsl(219, 0%, 0%)", "line-width": 1.5 }}
              beforeId={`${id}-labels`}
            ></Layer>
          ) : (
            <div />
          )}
          {components.limwas.hasGeometries ? (
            <Layer
              id={`${id}-limwa-icons`}
              type="symbol"
              source-layer="limwa"
              layout={{
                visibility: isLayerVisible({ group, id }) ? "visible" : "none",
                "icon-offset": [0, -4],
                "icon-image": "triangle-up-black",
                "symbol-avoid-edges": true,
                "symbol-spacing": 30,
                "icon-allow-overlap": true,
                "symbol-placement": "line",
                "icon-size": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  12,
                  0.5,
                  14,
                  0.8,
                ],
                "text-anchor": "bottom",
                "icon-ignore-placement": true,
              }}
              beforeId={`${id}-labels`}
              paint={{}}
            />
          ) : (
            <div />
          )}
        </Source>
      </React.Fragment>
    );
  }
}

MapLayer.contextType = LayerContext;

export default MapLayer;
