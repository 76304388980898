import React from "react";
import BlueFileIcon from "../../images/blue-file.svg";
import {
  Link,
  LinkText,
  MobileDocumentButton,
} from "./__styles__/MobileDocumentLink";

// DPI-501
// Beware that iOS Safari has a limitation for rendering documents inside iframes.
// This is a dealbreaker for rendering multi-page documents like PDFs as it would
// truncate the entire document to only the first page. This also
// means that the typical functions of a PDFViewer are absent - no zooming,
// downloading, printing, scrolling, etc.
// There are no clear solutions at this time.
// This is why we use the MobileDocumentLink to link mobile users to view documents.
// Here are some sources I found to confirm this issue:
// * https://discussions.apple.com/thread/250075244
// * https://stackoverflow.com/questions/15480804/problems-displaying-pdf-in-iframe-on-mobile-safari
// * https://stackoverflow.com/questions/53558808/pdf-in-iframe-only-first-page-on-iphone-ipad
// * https://stackoverflow.com/questions/38325655/pdf-link-showing-only-one-page-inside-iframe-ipad-only
export default ({
  fileUrl,
  documentUpload,
}: {
  fileUrl: string;
  documentUpload: {
    originalFilename: string;
    accountDocumentType: {
      name: string;
    };
  };
}) => {
  return (
    <MobileDocumentButton>
      <p>View the {documentUpload.accountDocumentType.name}</p>

      <Link href={fileUrl} target="_blank">
        <LinkText>
          <img src={BlueFileIcon} alt="Small blue paper file" />
          {documentUpload.originalFilename}
        </LinkText>
      </Link>
    </MobileDocumentButton>
  );
};
