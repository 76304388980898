import React from "react";

import "./__styles__/Icon.scss";

const Activity = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V7C0 7.26522 0.105357 7.51957 0.292893 7.70711C0.48043 7.89464 0.734784 8 1 8H13C13.2652 8 13.5196 7.89464 13.7071 7.70711C13.8946 7.51957 14 7.26522 14 7V1C14 0.734784 13.8946 0.48043 13.7071 0.292893C13.5196 0.105357 13.2652 0 13 0V0Z"
        fill="#7E8490"
      />
      <path d="M14 10H0V12H14V10Z" fill="#7E8490" />
      <path d="M14 14H0V16H14V14Z" fill="#7E8490" />
    </svg>
  );
};

export default Activity;
