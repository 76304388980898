import React, { useContext } from "react";
import { PropertyOverviewContext } from "./PropertyOverviewContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../Common/__styles__/StripedTable";
import { AddressPanelContext } from "../AddressPanelContext";
import { CustomMapRow } from "./Common/CustomMapRow";
import { RasterRow } from "./Common/RasterRow";
import { Account } from "../../Authorization/types";
import { PropertyAttributeSource } from "../../../generated/graphql";

export const CustomSection = ({
  section,
}: {
  section: Account["propertyInformationSections"][number];
}) => {
  const { loading: loadingFloodInfo } = useContext(PropertyOverviewContext);
  const { property, loading: loadingPropertyId } =
    useContext(AddressPanelContext);

  if (loadingPropertyId || loadingFloodInfo) {
    return <em>loading...</em>;
  }

  return (
    <Table spacing="negative">
      <TableHeader>
        <TableRow header>
          <TableCell>{section.label}</TableCell>
        </TableRow>
      </TableHeader>

      <TableBody>
        {section
          .accountPropertyAttributes!.filter(
            attribute =>
              attribute.source === PropertyAttributeSource.CUSTOM_MAP ||
              attribute.source === PropertyAttributeSource.RASTER
          )
          .map(attribute => {
            if (attribute.source === PropertyAttributeSource.CUSTOM_MAP) {
              return (
                <CustomMapRow
                  accountPropertyAttribute={attribute}
                  key={attribute.id}
                  size={"narrow"}
                />
              );
            }

            return (
              <RasterRow
                accountPropertyAttribute={attribute}
                key={attribute.id}
                property={property}
                size={"narrow"}
              />
            );
          })}
      </TableBody>
    </Table>
  );
};
