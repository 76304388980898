import React, { createContext, ReactNode, useState } from "react";

type InternalMapContextType = {
  resetSearchCategory: () => void;
  setResetSearchCategory: (passedMethod: () => void) => void;
};

export const InternalMapContext = createContext<InternalMapContextType>(
  {} as any
);

export const InternalMapContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [resetSearchCategory, setInternalState] = useState<() => void>(
    () => {}
  );

  const setResetSearchCategory = (passedMethod: () => void) => {
    setInternalState(() => passedMethod);
  };

  const value = {
    resetSearchCategory,
    setResetSearchCategory,
  };

  return (
    <InternalMapContext.Provider value={value}>
      {children}
    </InternalMapContext.Provider>
  );
};
