import React from "react";

import "./__styles__/Icon.scss";

const MagnifyingGlass = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0001 11.0001L7.82812 7.82812"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M5 9.00009C7.20914 9.00009 9 7.20923 9 5.00009C9 2.79095 7.20914 1.00009 5 1.00009C2.79086 1.00009 1 2.79095 1 5.00009C1 7.20923 2.79086 9.00009 5 9.00009Z"
      stroke="black"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default MagnifyingGlass;
