import React, { ReactNode } from "react";
import {
  Accept,
  DropEvent,
  ErrorCode,
  FileRejection,
  useDropzone as useReactDropzone,
} from "react-dropzone";
import { MIME_TYPE, MIME_TYPES_TO_EXTENSIONS } from "common/constants";

import Icon, { Icons } from "../Common/Icons";
import { FlexColumn } from "../Common/__styles__/Layout";
import { DroppedFile } from "../DocumentUploads/UploadDocumentsForm";

import {
  Dropzone,
  Label,
  FileTable,
  HeaderRow,
  FileRow,
  BlobError,
  Info,
  RemoveFile,
  GreenCheckMark as SuccessOrError,
  DropzoneContainer,
} from "./__styles__/FileUploads";
import { isNil, max } from "lodash";
import { ONE_MB_IN_BYTES } from "common/utils/documentUploads";

interface DropzoneProps<SUPPORTED_MIME_TYPES extends MIME_TYPE> {
  useDropzone?: (
    args: Pick<
      NonNullable<Parameters<typeof useReactDropzone>[0]>,
      | "onDrop"
      | "minSize"
      | "maxSize"
      | "maxFiles"
      | "accept"
      | "onDropRejected"
      | "validator"
    >
  ) => Pick<
    ReturnType<typeof useReactDropzone>,
    "getRootProps" | "getInputProps"
  >;
  onDrop: (any: Array<DroppedFile>) => void;
  onDropRejected?: (fileRejections: FileRejection[], event: DropEvent) => void;
  allowedMimeTypes: Array<SUPPORTED_MIME_TYPES>;
  hasImage?: boolean;
  compact?: boolean;
  border?: boolean;
  forceRelative?: boolean;
  content: React.FC;
  maxSizePerMimeType?: Record<SUPPORTED_MIME_TYPES, number>;
  maxFiles?: number;
  ariaDescribedBy?: string;
}

export const DropzoneComponent = <
  SUPPORTED_MIME_TYPES extends MIME_TYPE = MIME_TYPE
>({
  useDropzone = useReactDropzone,
  onDrop,
  onDropRejected,
  allowedMimeTypes,
  hasImage = true,
  compact = false,
  border = true,
  forceRelative = false,
  content,
  maxSizePerMimeType = undefined,
  maxFiles = undefined,
  ariaDescribedBy = undefined,
}: DropzoneProps<SUPPORTED_MIME_TYPES>) => {
  const accept = {} as Accept;

  allowedMimeTypes.forEach(
    type => (accept[type] = MIME_TYPES_TO_EXTENSIONS[type as MIME_TYPE])
  );

  const maxSize = maxSizePerMimeType
    ? max(Object.values<number>(maxSizePerMimeType))! * ONE_MB_IN_BYTES
    : undefined;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize,
    maxFiles,
    accept,
    minSize: 1,
    validator: file => {
      const maxSize = maxSizePerMimeType?.[file.type as SUPPORTED_MIME_TYPES];
      if (!isNil(maxSize) && file.size > maxSize * ONE_MB_IN_BYTES) {
        return {
          code: ErrorCode.FileTooLarge,
          // even though message is required, it doesn't actually show up in the UI
          message: `File size exceeds the maximum limit of ${maxSize} MB.`,
        };
      }

      return null;
    },
  });

  return (
    <DropzoneContainer
      {...getRootProps({
        id: "dropzone-container",
        role: "link",
        "aria-label": "Upload File Dropzone",
        "aria-describedby": ariaDescribedBy,
      })}
    >
      <Dropzone compact={compact} border={border} forceRelative={forceRelative}>
        <input {...getInputProps()} accept={allowedMimeTypes.join(",")} />
        <Label hasImage={hasImage} compact={compact}>
          {content({})}
        </Label>
      </Dropzone>
    </DropzoneContainer>
  );
};

export const HeaderRowComponent = ({
  children,
  hasGreenCheckmark = true,
}: {
  children: ReactNode;
  hasGreenCheckmark: boolean;
}) => (
  <HeaderRow hasGreenCheckmark={hasGreenCheckmark}>
    {children}
    <div tabIndex={0}>Remove</div>
  </HeaderRow>
);

export const FileRowComponent = ({
  isErrorRow = false,
  compact = false,
  children,
}: {
  isErrorRow?: boolean;
  compact?: boolean;
  children: ReactNode;
}) => (
  <FileRow isErrorRow={isErrorRow} compact={compact}>
    {children}
  </FileRow>
);

export const SuccessOrErrorIcon = ({
  success = true,
}: {
  success?: boolean;
}) => {
  if (success)
    return (
      <SuccessOrError>
        <Icon icon={Icons.GREEN_CHECK} style={{ display: "flex" }} />
      </SuccessOrError>
    );
  else {
    return (
      <SuccessOrError>
        <Icon icon={Icons.RED_X} style={{ display: "flex" }} />
      </SuccessOrError>
    );
  }
};

export const Filename = ({
  filename,
  errorMessage,
}: {
  filename: string;
  errorMessage?: string;
}) => (
  <FlexColumn>
    <span>{filename}</span>
    {errorMessage && <BlobError>{errorMessage}</BlobError>}
  </FlexColumn>
);

export const Remove = ({ onClick }: { onClick: () => void }) => (
  <RemoveFile tabIndex={0}>
    <Icon data-testid="remove" icon={Icons.CIRCLE_X} onClick={onClick} />
  </RemoveFile>
);

export { FileTable, Info };
