import React from "react";
import {
  formatFirstOptionRow,
  formatSecondOptionRow,
} from "common-client/utils/Search";

import { SearchResult } from "./__queries__/getSearchResults";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import { SearchResultProps, Status } from ".";

import {
  Dropdown,
  Empty,
  LoadingContainer,
  Option,
  OptionFirstRow,
  OptionSecondRow,
} from "./__styles__/Dropdown";

export const ResultsDropdown = ({
  results,
  onClear,
  handleResultClick,
  reset,
  resetSearchCategory,
  status,
}: {
  results: Array<Maybe<SearchResult>>;
  onClear: () => void;
  handleResultClick: ({
    point,
    address,
    propertyId,
    streetAddress,
    city,
    state,
    zip,
  }: SearchResultProps) => void;
  reset: () => void;
  resetSearchCategory: () => void;
  status: Status;
}) => {
  const handleResult = (result: SearchResult) => {
    reset();
    onClear();
    resetSearchCategory();
    const [longitude, latitude] = result!.coordinates.split(",").map(Number);
    handleResultClick({
      point: { longitude: longitude!, latitude: latitude! },
      address: result?.address!,
      propertyId: result?.propertyId,
      geocacheParcelId: result?.geocacheParcelId,
      streetAddress: result.streetAddress,
      city: result.city,
      state: result.state,
      zip: result.zip,
    });
  };

  return (
    <Dropdown data-testid="search-dropdown">
      {status === "search_complete" && !results.length && (
        <Empty tabIndex={0}>No results found</Empty>
      )}
      {status === "loading" && (
        <LoadingContainer data-testid="loading" tabIndex={0}>
          <Icon icon={Icons.SMALL_LOADING} color={ICON_COLORS.LIGHT_GREY} />
        </LoadingContainer>
      )}
      {results.map((result, index) => (
        <Option
          key={index}
          tabIndex={0}
          onClick={() => handleResult(result!)}
          onKeyDown={e => {
            if (e.key === "Enter") {
              handleResult(result!);
            }
          }}
        >
          <OptionFirstRow>{formatFirstOptionRow(result!)}</OptionFirstRow>
          <OptionSecondRow>{formatSecondOptionRow(result!)}</OptionSecondRow>
        </Option>
      ))}
    </Dropdown>
  );
};
