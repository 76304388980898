import React from "react";
import { Source, Layer } from "react-map-gl";
import { colors } from "../../../stitches.config";

export const id = "accountBoundary";

class MapLayer extends React.PureComponent<{ accountId: string }> {
  render() {
    const { accountId } = this.props;

    return (
      <Source
        id={id}
        type="geojson"
        data={`/api/geojson/account-boundary?accountId=${accountId}`}
      >
        <Layer
          id={`${id}`}
          type="fill"
          paint={{
            "fill-color": "hsla(0, 0%, 100%, 0)",
          }}
          beforeId="ground"
        />
        <Layer
          id={`${id}-outline`}
          type="line"
          paint={{
            "line-color": colors.contentPrimary.value,
            "line-width": 2,
            "line-dasharray": [1, 1],
          }}
        />
      </Source>
    );
  }
}

export default MapLayer;
