import React from "react";
import { WidgetProps } from "@rjsf/utils";
import Field from "./FormField";

export const TextField = (props: WidgetProps) => {
  return <Field type="text" {...props} />;
};

export const BooleanSelect = (props: WidgetProps) => {
  const onChange = (value: string) => {
    const boolValue = value === "true";
    props.onChange(boolValue);
  };

  return <Field type="boolean" {...props} onChange={onChange} />;
};
