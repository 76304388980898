import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { useDeleteModal } from "./DeleteModal";
import { AuthContext } from "../../Authorization/AuthContext";
import { RESOURCE_NAME } from "common/authorization";
import DropdownMenu, { ActionsProps } from "../../Inputs/DropdownMenu";
import DeleteImprovementConfirmation from "./DeleteImprovementConfirmation";
import {
  Improvement,
  ImprovementExclusionReason,
  useToggleImprovementExclusionMutation,
} from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { buildLink } from "common/routing";

interface EditImprovementDropdownProps {
  onUpdate: () => {};
  improvement: Omit<
    Improvement,
    "kind" | "createdAt" | "propertyId" | "propertyMarketValue" | "updatedAt"
  >;
  address?: Maybe<string>;
}

const EditImprovementDropdown = ({
  onUpdate,
  improvement,
  address,
}: EditImprovementDropdownProps) => {
  const isExcluded = !!improvement.exclusion?.excluded;

  const { authorized } = useContext(AuthContext);
  const history = useHistory();
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const [toggleImprovementExclusion] = useToggleImprovementExclusionMutation({
    fetchPolicy: "network-only",
  });

  const [showDeleteModal] = useDeleteModal({
    address,
    onSave: () => {
      onUpdate();
    },
    onCancel: () => {},
    modalProps: { improvement },
    DeletePanel: DeleteImprovementConfirmation,
  });

  const toggleExclusion = async () => {
    const toastPhrasalVerb = isExcluded
      ? "removed from manual exclusion"
      : "excluded from the summary";

    await toggleImprovementExclusion({
      variables: {
        id: improvement.id,
        data: { excluded: !isExcluded },
      },
      onCompleted: () => {
        addSuccessToast(`Your record was ${toastPhrasalVerb}.`);
      },
      onError: () => {
        addErrorToast(`Your record failed to be ${toastPhrasalVerb}.`);
      },
    });
  };

  const canUpdateImprovement = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "update",
  });

  const canDeleteImprovement = authorized({
    resource: RESOURCE_NAME.SUBMISSION,
    permission: "delete",
  });

  const pathname = buildLink("viewSubmission", {
    submissionId: improvement.id,
  });

  const actions: Array<ActionsProps> = [
    {
      label: "View",
      to: {
        pathname,
        state: {
          prevLocation: history.location.pathname,
          search: location.search,
          address,
        },
      },
      disabled: !canUpdateImprovement,
    },
    {
      label: isExcluded ? "Include" : "Exclude",
      onClick: () => toggleExclusion(),
      disabled: !canUpdateImprovement,
      hidden:
        improvement.exclusion?.reason === ImprovementExclusionReason.AUTOMATIC,
    },
    {
      label: "Delete",
      disabled: !canDeleteImprovement,
      variant: "red",
      onClick: () => showDeleteModal(),
      closeOnClick: false,
    },
  ];

  return <DropdownMenu actions={actions} />;
};

export default EditImprovementDropdown;
