import React, { ReactNode } from "react";

import { HomeButton } from "./HomeButton";

import "./__styles__/PageSubheader.scss";

export const PageSubheader = ({
  title,
  children,
  showHomeButton = true,
}: {
  title: Maybe<String>;
  children: ReactNode;
  showHomeButton?: boolean;
}) => (
  <div styleName="container">
    {showHomeButton && (
      <div styleName="controls">
        <HomeButton />
      </div>
    )}
    <div styleName="line-block">
      {title && <h1>{title}</h1>}
      {children}
    </div>
  </div>
);
