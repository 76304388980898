import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useModal } from "react-modal-hook";

import Modal from "./Modal";
import { Button } from "../Common/Button";

import {
  ButtonSection,
  Container,
  ContentSection,
  FormSection,
  HeaderSection,
  PrimaryButtons,
  SubHeader,
} from "./__styles__/Modal";

export const useDeleteModal = ({
  headerText,
  subheaderText,
  Content,
  deleteButtonText,
  width,
  onDelete,
}: {
  headerText: string;
  subheaderText?: string;
  Content: ReactNode;
  deleteButtonText: string;
  width?: "narrow" | "wide";
  onDelete: () => void;
}) => {
  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    <Modal onRequestClose={hideDeleteModal}>
      <DeleteModalForm
        headerText={headerText}
        subheaderText={subheaderText}
        Content={Content}
        deleteButtonText={deleteButtonText}
        width={width}
        onClose={hideDeleteModal}
        onDelete={onDelete}
      />
    </Modal>
  ));

  return [showDeleteModal, hideDeleteModal] as const;
};

export type DeleteModalFormProps = {
  headerText: string;
  subheaderText?: string;
  Content: ReactNode;
  deleteButtonText?: string;
  width?: "narrow" | "wide";
  onClose: () => void;
  onDelete: () => void;
};

export const DeleteModalForm = ({
  headerText,
  subheaderText,
  Content,
  deleteButtonText = "Yes, delete",
  width,
  onClose,
  onDelete,
}: DeleteModalFormProps) => {
  const { handleSubmit } = useForm();

  const onSubmit = handleSubmit(() => {
    onDelete();
    onClose();
  });

  return (
    <Container overflows width={width}>
      <HeaderSection>
        <h1>{headerText}</h1>
        {subheaderText && <SubHeader>{subheaderText}</SubHeader>}
      </HeaderSection>
      <FormSection overflows>
        <ContentSection overflows>{Content}</ContentSection>
        <ButtonSection>
          <PrimaryButtons>
            <Button
              styleVariant="secondary"
              onClick={event => {
                event.preventDefault(); // prevent the form from being submitted
                onClose();
              }}
              size="medium"
            >
              Cancel
            </Button>
            <Button styleVariant="alert" onClick={onSubmit} size="medium">
              {deleteButtonText}
            </Button>
          </PrimaryButtons>
        </ButtonSection>
      </FormSection>
    </Container>
  );
};
