import React, { useContext } from "react";
import { buildLink } from "common/routing";
import { AuthContext } from "../Authorization/AuthContext";

import { StyledLink } from "../Common/__styles__/Typography";

export const GetHelpLink = ({
  propertyId,
  text = "request help",
}: {
  propertyId?: string;
  text?: string;
}) => {
  const account = useContext(AuthContext).account!;
  return (
    <StyledLink
      href={buildLink("getHelp", { accountId: account.id }, { propertyId })}
    >
      {text}
    </StyledLink>
  );
};
