import React, { useContext, useState } from "react";
import { RasterMeasure } from "./Shared";
import { useUpdatePropertyAdditionalFieldsMutation } from "../../../generated/graphql";
import { useStatusToasts } from "../../../hooks/useStatusToasts";
import { LayerContext } from "../layers";
import { track } from "../../../utils/tracking";
import { Button } from "../../Common/Button";
import { Property } from "common-client/utils/firms";
import { captureException } from "@sentry/browser";

export type StoreRasterMeasureToolProps = {
  property: Pick<Property, "id" | "additionalFields">;
  attribute: {
    id: string;
    label: string;
    sourceId?: Maybe<string>;
  };
};

export const StoreRasterMeasureTool = ({
  property: initialProperty,
  attribute,
}: StoreRasterMeasureToolProps) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const { measureToolState, rasters } = useContext(LayerContext);

  const raster = rasters.find(raster => raster.id === attribute.sourceId);

  const [property, setProperty] =
    useState<StoreRasterMeasureToolProps["property"]>(initialProperty);

  const [updatePropertyAdditionalFields, { loading: updatePropertyLoading }] =
    useUpdatePropertyAdditionalFieldsMutation({
      refetchQueries: ["getFloodInfo"],
      onCompleted: data => {
        track("Raster attribute updated on property", {
          propertyId: property.id,
          attributeLabel: attribute.label,
        });
        addSuccessToast("Value saved to property");
        setProperty(data.updatePropertyAdditionalFields);
      },
      onError: () => {
        addErrorToast("Value failed to save to property");
      },
    });

  if (!raster) {
    captureException(
      `StoreRasterMeasureTool was called with attribute ${attribute.id} but no raster was found with id ${attribute.sourceId}`
    );
    return null;
  }

  const title = `Select ${attribute.label} location`;
  const subtitle = `Click points to measure ${raster.bandMappings.measurementName}`;

  const deleteButton = Object.keys(property.additionalFields).includes(
    attribute.id
  ) ? (
    <Button
      styleVariant="ghostWhite"
      size="small"
      disabled={updatePropertyLoading}
      onClick={() => {
        const variables = {
          data: {
            propertyId: property.id,
            attributeId: attribute.id,
          },
        };

        void updatePropertyAdditionalFields({
          variables,
        });
      }}
      tabIndex={-1}
    >
      Delete value
    </Button>
  ) : undefined;

  const onClick = (measurementValue: string) => {
    const variables = {
      data: {
        propertyId: property.id,
        attributeId: attribute.id,
        value: {
          fieldType: "raster",
          rasterId: raster.id,
          coordinates: measureToolState.coordinates[0],
          value: measurementValue,
          units: raster.bandMappings.measurementUnit,
        },
      },
    };

    void updatePropertyAdditionalFields({
      variables,
    });
  };

  return (
    <RasterMeasure
      title={title}
      subtitle={subtitle}
      onActionClick={onClick}
      parentLoading={updatePropertyLoading}
      secondaryButton={deleteButton}
      actionName="Save"
      raster={raster}
    />
  );
};
