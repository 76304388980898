import React, { useContext } from "react";
import { AuthContext } from "../Authorization/AuthContext";
import { useHistory } from "react-router";

import { Button } from "../Common/Button";

import { useIssueModal } from "./IssueModal";
import { RESOURCE_NAME } from "common/authorization";

import "./__styles__/IssueBanner.scss";

export type IssueBannerProps = {
  address?: Maybe<string>;
  coordinates: readonly [number, number];
  certificateId?: string;
  showEditButton?: boolean;
  propertyId?: Maybe<string>;
};

const IssueBanner = ({
  address,
  coordinates,
  certificateId,
  showEditButton = false,
  propertyId,
}: IssueBannerProps) => {
  const history = useHistory();
  const { authorized } = useContext(AuthContext);
  const [showModal] = useIssueModal({ address, coordinates, certificateId });

  const canShowReportDataIssue = authorized({
    resource: RESOURCE_NAME.ISSUE,
    permission: "create",
  });

  return (
    <div styleName="report-issue-banner">
      <p>See an issue with the data? Let us know!</p>
      <div styleName="report-issue-buttons">
        <Button
          styleVariant="primary"
          onClick={() => showModal()}
          disabled={!canShowReportDataIssue}
          size="small"
        >
          Report data issue
        </Button>
        {showEditButton && propertyId && (
          <Button
            onClick={() =>
              history.push({
                pathname: `/backoffice/properties/${propertyId}`,
              })
            }
            size="small"
            styleVariant="outlineLight"
          >
            Edit property
          </Button>
        )}
      </div>
    </div>
  );
};

export default IssueBanner;
