import React, { useEffect, useState } from "react";
import { NODE_ENV } from "common/env";
import { BannerContainer, BannerTextWrapper } from "./__styles__/StagingBanner";
import { Button } from "../../Common/Button";
import { Icon } from "../../Common/Icons/LucideIcons";

export const StagingBanner = () => {
  const [showBanner, setShowBanner] = useState<boolean>(
    NODE_ENV !== "production"
  );

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--staging-banner-height",
      showBanner ? "30px" : "0px"
    );
  }, [showBanner]);

  return showBanner ? (
    <BannerContainer environment={NODE_ENV}>
      <BannerTextWrapper>
        You're currently viewing the Forerunner {NODE_ENV} environment
      </BannerTextWrapper>
      <Button
        onClick={() => setShowBanner(false)}
        styleVariant={"ghost"}
        aria-label="Close Banner"
      >
        <Icon iconName="x" color="contentPrimaryDark" size={16} />
      </Button>
    </BannerContainer>
  ) : null;
};
