import React, { ReactNode } from "react";
import Collapsible from "react-collapsible";

import { Icon } from "./Icons/LucideIcons";

import {
  CollapsibleWrapper,
  ExpandableContent,
  List,
  Row,
  Title,
} from "./__styles__/ExpandableList";

type ListItem = {
  title: ReactNode;
  expandableContent: ReactNode;
  rightContent?: ReactNode;
};

const ListRow = ({ title, expandableContent, rightContent }: ListItem) => {
  const [show, toggleShow] = React.useState(false);

  const trigger = (
    <Title>
      {title}
      <Icon
        iconName={show ? "chevron-up" : "chevron-down"}
        color="contentSecondary"
        size={16}
      />
    </Title>
  );

  return (
    <Row data-testid={"row"}>
      <CollapsibleWrapper>
        <Collapsible
          trigger={trigger}
          transitionTime={50}
          onOpen={() => {
            toggleShow(true);
          }}
          onClose={() => {
            toggleShow(false);
          }}
        >
          <ExpandableContent tabIndex={0} show={show}>
            {expandableContent}
          </ExpandableContent>
        </Collapsible>
      </CollapsibleWrapper>
      {rightContent && <div>{rightContent}</div>}
    </Row>
  );
};

export const ExpandableList = ({
  title,
  list,
}: {
  title: string;
  list: Array<ListItem>;
}) => {
  return (
    <List>
      <h3>{title}</h3>
      {list.map((item, index) => (
        <ListRow
          key={index}
          title={item.title}
          expandableContent={item.expandableContent}
          rightContent={item.rightContent}
        />
      ))}
    </List>
  );
};
