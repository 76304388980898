import React from "react";

import "./__styles__/Icon.scss";

const ExpandIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2465_1395)">
        <path
          d="M5.25 0.75C5.25 0.551088 5.17098 0.360322 5.03033 0.21967C4.88968 0.0790176 4.69891 0 4.5 0L0.75 0C0.551088 0 0.360322 0.0790176 0.21967 0.21967C0.0790176 0.360322 0 0.551088 0 0.75L0 4.5C0 4.69891 0.0790176 4.88968 0.21967 5.03033C0.360322 5.17098 0.551088 5.25 0.75 5.25C0.948912 5.25 1.13968 5.17098 1.28033 5.03033C1.42098 4.88968 1.5 4.69891 1.5 4.5V2.5605L3.96975 5.03025C4.1112 5.16687 4.30065 5.24246 4.4973 5.24076C4.69395 5.23905 4.88206 5.16017 5.02111 5.02111C5.16017 4.88206 5.23905 4.69395 5.24076 4.4973C5.24246 4.30065 5.16687 4.1112 5.03025 3.96975L2.5605 1.5H4.5C4.69891 1.5 4.88968 1.42098 5.03033 1.28033C5.17098 1.13968 5.25 0.948912 5.25 0.75Z"
          fill="#0C111B"
        />
        <path
          d="M11.25 6.75C11.0511 6.75 10.8603 6.82902 10.7197 6.96967C10.579 7.11032 10.5 7.30109 10.5 7.5V9.4395L8.03025 6.96975C7.8888 6.83313 7.69935 6.75754 7.5027 6.75924C7.30605 6.76095 7.11794 6.83983 6.97889 6.97889C6.83983 7.11794 6.76095 7.30605 6.75924 7.5027C6.75754 7.69935 6.83313 7.8888 6.96975 8.03025L9.4395 10.5H7.5C7.30109 10.5 7.11032 10.579 6.96967 10.7197C6.82902 10.8603 6.75 11.0511 6.75 11.25C6.75 11.4489 6.82902 11.6397 6.96967 11.7803C7.11032 11.921 7.30109 12 7.5 12H11.25C11.4489 12 11.6397 11.921 11.7803 11.7803C11.921 11.6397 12 11.4489 12 11.25V7.5C12 7.30109 11.921 7.11032 11.7803 6.96967C11.6397 6.82902 11.4489 6.75 11.25 6.75Z"
          fill="#0C111B"
        />
        <path
          d="M6.75 0.75C6.75 0.551088 6.82902 0.360322 6.96967 0.21967C7.11032 0.0790176 7.30109 0 7.5 0L11.25 0C11.4489 0 11.6397 0.0790176 11.7803 0.21967C11.921 0.360322 12 0.551088 12 0.75V4.5C12 4.69891 11.921 4.88968 11.7803 5.03033C11.6397 5.17098 11.4489 5.25 11.25 5.25C11.0511 5.25 10.8603 5.17098 10.7197 5.03033C10.579 4.88968 10.5 4.69891 10.5 4.5V2.5605L8.03025 5.03025C7.8888 5.16687 7.69935 5.24246 7.5027 5.24076C7.30605 5.23905 7.11794 5.16017 6.97889 5.02111C6.83983 4.88206 6.76095 4.69395 6.75924 4.4973C6.75754 4.30065 6.83313 4.1112 6.96975 3.96975L9.4395 1.5H7.5C7.30109 1.5 7.11032 1.42098 6.96967 1.28033C6.82902 1.13968 6.75 0.948912 6.75 0.75Z"
          fill="#0C111B"
        />
        <path
          d="M0.75 6.75C0.948912 6.75 1.13968 6.82902 1.28033 6.96967C1.42098 7.11032 1.5 7.30109 1.5 7.5V9.4395L3.96975 6.96975C4.1112 6.83313 4.30065 6.75754 4.4973 6.75924C4.69395 6.76095 4.88206 6.83983 5.02111 6.97889C5.16017 7.11794 5.23905 7.30605 5.24076 7.5027C5.24246 7.69935 5.16687 7.8888 5.03025 8.03025L2.5605 10.5H4.5C4.69891 10.5 4.88968 10.579 5.03033 10.7197C5.17098 10.8603 5.25 11.0511 5.25 11.25C5.25 11.4489 5.17098 11.6397 5.03033 11.7803C4.88968 11.921 4.69891 12 4.5 12H0.75C0.551088 12 0.360322 11.921 0.21967 11.7803C0.0790176 11.6397 0 11.4489 0 11.25V7.5C0 7.30109 0.0790176 7.11032 0.21967 6.96967C0.360322 6.82902 0.551088 6.75 0.75 6.75Z"
          fill="#0C111B"
        />
      </g>
      <defs>
        <clipPath id="clip0_2465_1395">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExpandIcon;
