import React from "react";

import "./__styles__/Icon.scss";

const EyeballOff = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9309 4.252L9.86286 5.32C10.0714 5.58025 10.2424 5.818 10.3646 6.00025C9.79461 6.84775 8.22711 8.86525 6.19386 8.989L4.83261 10.3502C5.20461 10.4432 5.59311 10.5002 6.00036 10.5002C9.53061 10.5002 11.8084 6.53725 11.9036 6.36925C12.0319 6.14125 12.0326 5.86225 11.9044 5.63425C11.8691 5.5705 11.5294 4.9735 10.9309 4.252Z"
        fill="#434A58"
      />
      <path
        d="M0.220031 11.7804C0.366281 11.9267 0.558281 12.0002 0.750281 12.0002C0.942281 12.0002 1.13428 11.9267 1.28053 11.7804L11.7805 1.28044C12.0738 0.987188 12.0738 0.513188 11.7805 0.219938C11.4873 -0.0733125 11.0133 -0.0733125 10.72 0.219938L8.66128 2.27869C7.88653 1.82344 6.99853 1.50019 6.00028 1.50019C2.43403 1.50019 0.188531 5.46694 0.0947813 5.63569C-0.0319687 5.86294 -0.0312187 6.13969 0.0962813 6.36694C0.148781 6.46069 0.879281 7.71844 2.11603 8.82394L0.219281 10.7207C-0.0732187 11.0132 -0.0732187 11.4872 0.220031 11.7804ZM1.63603 5.99944C2.21878 5.12644 3.85978 3.00019 6.00028 3.00019C6.55678 3.00019 7.07803 3.15094 7.55878 3.38119L6.38428 4.55569C6.26128 4.52194 6.13378 4.50019 6.00028 4.50019C5.17153 4.50019 4.50028 5.17144 4.50028 6.00019C4.50028 6.13369 4.52203 6.26119 4.55578 6.38419L3.18028 7.75969C2.46403 7.13269 1.91953 6.42169 1.63603 5.99944Z"
        fill="#434A58"
      />
    </svg>
  );
};

export default EyeballOff;
