import React, { useContext, useState } from "react";
import { formatJurisdiction } from "common-client/utils/firms";

import Badge from "../../../Common/Badge";
import { track } from "../../../../utils/tracking";
import { AuthContext } from "../../../Authorization/AuthContext";
import { DropdownMenu } from "../../../Inputs";
import { Button } from "../../../Common/Button";
import { ActionsProps } from "../../../Inputs/DropdownMenu";
import WarningIcon from "../../../../images/warning-yellow.svg";

import { FirmName, Option } from "./__styles__/FIRMSelect";
import { LayerContext } from "../../../Maps/layers";
import { FIRMInfoContext } from "./FIRMInfoContext";

export interface FIRMSelectProps {
  onComparisonSelection: () => void;
}

export default ({ onComparisonSelection }: FIRMSelectProps) => {
  const { isGuest } = useContext(AuthContext);
  const { firms, activeFirmIds, setActiveFirmIds } =
    useContext(FIRMInfoContext);
  const { approximateBfeToolState } = useContext(LayerContext);

  const [selectedFIRMName, setSelectedFIRMName] = useState(
    firms.find(firm => firm.id === activeFirmIds[0])?.name
  );

  const selectFIRM = ({ id, name }: { id: string; name: string }) => {
    setSelectedFIRMName(name);
    track("FIRM Selection Made", { firm: name });
    setActiveFirmIds([id]);
  };

  const FirmSelectButton = ({
    onClick,
  }: {
    onClick: (_: React.MouseEvent<HTMLElement>) => void;
  }) => {
    return (
      <Button
        onClick={onClick}
        size="small"
        styleVariant="outlineLight"
        tabIndex={0}
        style={{ display: "inline-flex" }}
        rightIconName="chevron-down"
        disabled={
          approximateBfeToolState?.mode &&
          approximateBfeToolState.mode !== "off"
        }
      >
        {selectedFIRMName}
      </Button>
    );
  };

  const FirmOption = ({
    name,
    jurisdictions,
    hasWarnings,
  }: {
    name: string;
    jurisdictions: string;
    hasWarnings: boolean;
  }) => {
    return (
      <Option>
        <FirmName>
          {name}
          <div>{hasWarnings && <img src={WarningIcon} />}</div>
        </FirmName>
        {jurisdictions && <Badge text={jurisdictions} color="orange" />}
      </Option>
    );
  };

  const actions: Array<ActionsProps> = firms.map(
    ({ id, name, jurisdictions, warnings }) => ({
      label: FirmOption({
        name,
        jurisdictions: jurisdictions.map(formatJurisdiction).join(" & "),
        hasWarnings: !isGuest ? !!warnings.length : false,
      }),
      onClick: () => selectFIRM({ name, id }),
    })
  );

  if (!isGuest) {
    actions.push({
      label: "Compare All FIRMs",
      onClick: () => onComparisonSelection(),
      variant: "topDivider",
    });
  }

  return <DropdownMenu actions={actions} customButton={FirmSelectButton} />;
};
