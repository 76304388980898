import React, { ComponentProps, ReactNode, useMemo } from "react";
import { Offset, TooltipProps } from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

import { Icon } from "./Icons/LucideIcons";
import { StyledTooltip, TooltipWrapper } from "./__styles__/Tooltip";
import { Body } from "./Typography";
import { MarkdownContainer } from "./__styles__/MarkdownContainer";
import { colors } from "../../stitches.config";

const Tooltip = ({
  content,
  popoverContent,
  id,
  leftSpace,
  ...tooltipProps
}: {
  content: string | JSX.Element;
  popoverContent: JSX.Element;
  id?: string;
  leftSpace: boolean;
} & Omit<TooltipProps, "offset">) => {
  const autoId = useMemo(() => uuidv4(), []);
  const finalId = id ?? autoId;

  let offset: Offset;
  switch (tooltipProps.place) {
    case "right":
      offset = { top: 2 };
      break;
    case "top":
    default:
      offset = { top: -8 };
      break;
  }

  return (
    <TooltipWrapper
      tabIndex={0}
      data-tip
      data-for={finalId}
      leftSpace={leftSpace}
    >
      {content}
      <StyledTooltip
        id={finalId}
        clickable={true} // allows for markdown tooltips to have links in them
        delayHide={150}
        backgroundColor={colors.bgUiInteractionDark.value}
        border={false}
        effect="solid"
        offset={offset}
        {...tooltipProps}
        arrowColor="transparent"
      >
        {popoverContent}
      </StyledTooltip>
    </TooltipWrapper>
  );
};

export const InformationalTooltip: React.FC<
  Omit<
    ComponentProps<typeof Tooltip>,
    "content" | "popoverContent" | "leftSpace"
  > & {
    iconProps?: Partial<ComponentProps<typeof Icon>>;
    tooltipText: string | ReactNode;
  }
> = ({ iconProps, ...props }) => {
  const content = (
    <Icon
      iconName="info"
      color="contentSecondary"
      size={16}
      cursor="pointer"
      {...iconProps}
    />
  );

  const popoverContent = getTooltipContent(props.tooltipText);

  return (
    <Tooltip
      {...props}
      content={content}
      popoverContent={popoverContent}
      leftSpace={true}
    />
  );
};

export const TooltipWithText: React.FC<
  Omit<
    ComponentProps<typeof Tooltip>,
    "content" | "popoverContent" | "leftSpace"
  > & {
    hoverText: string;
    tooltipText: string | ReactNode;
  }
> = ({ hoverText, ...props }) => {
  const popoverContent = getTooltipContent(props.tooltipText);

  return (
    <Tooltip
      {...props}
      content={hoverText}
      popoverContent={popoverContent}
      leftSpace={false}
    />
  );
};

const getTooltipContent = (tooltipText: string | ReactNode) => (
  <Body
    size={"small"}
    type={"regular"}
    color="contentPrimaryDark"
    style={{
      maxWidth: "200px",
      whiteSpace: "normal",
      textAlign: "left",
      margin: 0,
    }}
    as="div"
  >
    {tooltipText}
  </Body>
);

export const MarkdownTooltip: React.FC<
  Omit<
    ComponentProps<typeof InformationalTooltip>,
    "tooltipText" | "leftSpace"
  > & {
    tooltipText: string;
  }
> = ({ tooltipText, ...props }) => {
  const tooltip = (
    <MarkdownContainer
      tooltip
      markdown={tooltipText}
      options={{
        openLinksInNewWindow: true,
      }}
    />
  );

  return (
    <InformationalTooltip
      {...props}
      tooltipText={tooltip}
      // since the tooltip is markdown, it could have links, and so
      // we want to delay the hide by 150ms to allow for links to be clicked
      delayHide={150}
      clickable={true}
    />
  );
};
