import React from "react";

import { setTitle } from "../../utils/title";
import { Header } from "./Layout";
import { accountTitle } from "./utils";

import {
  AccessDeniedMessageContainer,
  ButtonContainer,
  Container,
  HelpText,
  MainText,
} from "./__styles__/AccessDeniedPage";
import { buildLink, getPath } from "common/routing";
import { useHistory } from "react-router";
import { Button } from "../Common/Button";
import { EmptyState } from "../Common/EmptyState";
import { Page } from "./__styles__/Layout";
import { useGetAccessDeniedDataQuery } from "../../generated/graphql";
import { capitalize } from "lodash";

export enum ACCESS_DENIED_RESOURCE {
  PROPERTY = "property",
  FILE = "file",
}
const getLoginPageURL = (pathName: string) => {
  return `/login?prev=${encodeURIComponent(pathName)}`;
};

const AccessDeniedPage = ({
  resource,
}: {
  resource: ACCESS_DENIED_RESOURCE;
}) => {
  const history = useHistory();

  const { data } = useGetAccessDeniedDataQuery();

  const accountContactText =
    data?.account?.contactInformation.email &&
    data.account.contactInformation.name ? (
      <span>
        {" "}
        Reach out to{" "}
        <a href={`mailto:${data.account.contactInformation.email}`}>
          {data.account.contactInformation.name}
        </a>{" "}
        for more information or {<a href={buildLink("getHelp")}>get help</a>}{" "}
        here.
      </span>
    ) : null;

  setTitle(`No ${capitalize(resource)} Found | ${accountTitle()}`);

  return (
    <Page>
      <Header />

      <Container>
        <AccessDeniedMessageContainer>
          <EmptyState message={""} />
          <MainText>This {resource} is unavailable</MainText>{" "}
          <HelpText>
            This {resource} is unavailable because you do not have access or it
            has been removed.{accountContactText} If you have an account, log in
            below.
          </HelpText>
          <ButtonContainer>
            <Button
              onClick={() => {
                history.push(getPath("explore"));
              }}
              size="small"
              styleVariant="secondary"
            >
              Explore map
            </Button>
            <Button
              onClick={() => {
                let pathName = location.pathname.includes("/file/")
                  ? location.pathname.replace("/file/", "/files/")
                  : location.pathname;

                const loginPageURL = getLoginPageURL(pathName);

                window.location.href =
                  window.location.protocol +
                  "//" +
                  window.env.APPLICATION_DOMAIN +
                  loginPageURL;
              }}
              size="small"
              styleVariant="primary"
            >
              Log in
            </Button>
          </ButtonContainer>
        </AccessDeniedMessageContainer>
      </Container>
    </Page>
  );
};

export default AccessDeniedPage;
