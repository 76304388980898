import React, { Fragment, useContext, useEffect, useMemo, useRef } from "react";

import FIRMLayer, { FIRM } from "../layers/firm";
import PanelLayer from "../layers/panels";
import BaseFloodElevationLayer from "../layers/baseFloodElevations";
import CrossSectionsLayer, {
  getInteractiveCrossSectionLayerId,
  group,
} from "../layers/crossSections";
import ProfileBaselineLayer from "../layers/profileBaselines";
import {
  findRelevantFeature,
  LayerConfig,
  LayerHook,
  LayerHookBuilder,
} from "./types";
import { LayerContext } from "../layers";
import { MapEvent } from "react-map-gl";
import {
  ApproximateBfeToolState,
  CrossSectionFeature,
} from "common-client/utils/useApproximateBfeTool";
import { differenceBy } from "lodash";

const sourceLayer = "src";

export type FirmLayerConfig = LayerConfig<FIRM, CrossSectionFeature> & {
  firms: Array<FIRM>;
};
const firmHook: LayerHookBuilder<FirmLayerConfig> = ({ config, helpers }) => {
  const { isLayerVisible, approximateBfeToolState } = useContext(LayerContext);
  const hoveredCrossSection = useRef<Maybe<string>>(null);
  const clickedCrossSections = useRef<{ id: string }[]>([]);

  const firm = config.firms.find(firm =>
    isLayerVisible({ group: "firms", id: firm.id })
  );

  const interactiveLayerId = firm?.id
    ? getInteractiveCrossSectionLayerId(firm.id)
    : null;

  const selectedFirmSourceId = `${group}-${firm?.id}` as const;

  const canInteractWithCrossSection = ({
    crossSection,
    approximateBfeToolState,
  }: {
    crossSection?: any;
    approximateBfeToolState?: ApproximateBfeToolState;
  }) => {
    if (!approximateBfeToolState) {
      return false;
    }

    return (
      crossSection &&
      crossSection.properties.regulatoryWaterSurfaceElevation &&
      !approximateBfeToolState.disableMapInteractions &&
      (approximateBfeToolState.crossSections.length < 2 ||
        approximateBfeToolState.crossSections
          .map(crossSection => crossSection.id)
          .includes(crossSection.id))
    );
  };

  useEffect(() => {
    if (approximateBfeToolState?.mode === "off") {
      clickedCrossSections.current.forEach(crossSection => {
        helpers.emit({
          type: "removeFeatureState",
          data: {
            id: crossSection.id,
            field: "click",
            source: selectedFirmSourceId,
            sourceLayer,
          },
        });
      });

      clickedCrossSections.current = [];
    }
  }, [approximateBfeToolState?.mode]);

  useEffect(() => {
    const addClickState = differenceBy(
      approximateBfeToolState?.crossSections,
      clickedCrossSections.current,
      "id"
    );

    const removeClickState = differenceBy(
      clickedCrossSections.current,
      approximateBfeToolState?.crossSections!,
      "id"
    );

    for (const crossSection of addClickState) {
      helpers.emit({
        type: "setFeatureState",
        data: {
          id: crossSection.id,
          field: "click",
          source: selectedFirmSourceId,
          sourceLayer,
        },
      });
      if (hoveredCrossSection.current) {
        helpers.emit({
          type: "removeFeatureState",
          data: {
            id: hoveredCrossSection.current,
            field: "hover",
            source: selectedFirmSourceId,
            sourceLayer,
          },
        });
      }

      hoveredCrossSection.current = null;
    }

    for (const crossSection of removeClickState) {
      helpers.emit({
        type: "removeFeatureState",
        data: {
          id: crossSection.id,
          field: "click",
          source: selectedFirmSourceId,
          sourceLayer,
        },
      });
    }

    clickedCrossSections.current = approximateBfeToolState?.crossSections ?? [];
  }, [JSON.stringify(approximateBfeToolState?.crossSections)]);

  return useMemo<LayerHook>(() => {
    return {
      zIndex: 0,
      onClick: ({ event }: { event: MapEvent }) => {
        if (!interactiveLayerId) return false;
        const feature = findRelevantFeature(event, interactiveLayerId);

        if (
          canInteractWithCrossSection({
            crossSection: feature,
            approximateBfeToolState,
          })
        ) {
          config.onClick?.(feature.properties);
        }

        return !!feature;
      },
      onHover: ({ event }: { event: MapEvent }) => {
        if (hoveredCrossSection.current) {
          helpers.emit({
            type: "removeFeatureState",
            data: {
              id: hoveredCrossSection.current,
              field: "hover",
              source: selectedFirmSourceId,
              sourceLayer,
            },
          });
          helpers.emit({ type: "setCursor", data: "grab" });
        }

        hoveredCrossSection.current = null;

        if (!interactiveLayerId) return false;

        const feature = findRelevantFeature(event, interactiveLayerId);

        if (
          canInteractWithCrossSection({
            crossSection: feature,
            approximateBfeToolState,
          })
        ) {
          helpers.emit({ type: "setCursor", data: "pointer" });

          helpers.emit({
            type: "setFeatureState",
            data: {
              id: feature.id,
              field: "hover",
              source: selectedFirmSourceId,
              sourceLayer,
            },
          });

          hoveredCrossSection.current = feature.id;
        }

        return !!feature;
      },
      onFocusLost: () => {
        if (hoveredCrossSection.current) {
          helpers.emit({
            type: "removeFeatureState",
            data: {
              id: hoveredCrossSection.current,
              field: "hover",
              source: selectedFirmSourceId,
              sourceLayer,
            },
          });
        }

        hoveredCrossSection.current = null;
      },
      canHandleClick: () => !!config.interactive?.click,
      canHandleHover: () => !!config.interactive?.hover,
      interactiveLayerIds: interactiveLayerId ? [interactiveLayerId] : [],
      render: () => {
        return config.firms.map(firm => {
          return (
            <Fragment key={firm.id}>
              <FIRMLayer firm={firm} />
              <PanelLayer firm={firm} />
              <BaseFloodElevationLayer firm={firm} />
              <CrossSectionsLayer firm={firm} />
              <ProfileBaselineLayer firm={firm} />
            </Fragment>
          );
        });
      },
    };
  }, [config, approximateBfeToolState, interactiveLayerId]);
};

export default firmHook;
