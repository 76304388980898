import React from "react";

import { Button } from "../Common/Button";

export const PrintButton = ({ print }: { print: typeof window.print }) => {
  return (
    <Button
      styleVariant="ghostGrey"
      leftIconName="printer"
      size="small"
      onClick={() => print()}
      style={{ padding: 0 }}
    >
      Print page
    </Button>
  );
};
