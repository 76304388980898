import React, { useContext } from "react";

import { setTitle } from "../../utils/title";
import Layout from "./Layout";
import HouseFloodImage from "../../images/house-flood.svg";
import { buildLink } from "common/routing";
import { accountTitle, HELPSCOUT_BEACON_ID, useHelpscoutBeacon } from "./utils";
import { GetHelpLink } from "./GetHelpLink";
import { AuthContext } from "../Authorization/AuthContext";

import "./__styles__/NoPropertyPage.scss";
import { StyledLink } from "../Common/__styles__/Typography";

const NoPropertyPage = () => {
  const account = useContext(AuthContext).account!;
  useHelpscoutBeacon(HELPSCOUT_BEACON_ID.OVERVIEW);

  setTitle(`No Property Found | ${accountTitle()}`);

  return (
    <Layout>
      <div styleName="container">
        <div styleName="line-block">
          <div styleName="no-property-message">
            <div styleName="image-wrapper">
              <img src={HouseFloodImage} />
            </div>
            <h1>
              We're sorry — we don't have any information on the address you
              searched for.
            </h1>
            <p>
              Please return to{" "}
              <StyledLink
                href={buildLink("guestHome", { accountId: account.id })}
              >
                {accountTitle()}'s homepage
              </StyledLink>{" "}
              or <GetHelpLink />
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NoPropertyPage;
