import React from "react";
import { setTitle } from "../../utils/title";
import HouseFloodImage from "../../images/house-flood.svg";
import Logo from "../../images/logo-white.svg";

import "./__styles__/UnavailablePage.scss";
import { HELPSCOUT_BEACON_ID, useHelpscoutBeacon } from "./utils";

const UnavailablePage = () => {
  useHelpscoutBeacon(HELPSCOUT_BEACON_ID.OVERVIEW);
  setTitle("Page Unavailable");

  return (
    <div styleName="page">
      <Header />
      <div styleName="container">
        <div styleName="line-block">
          <div styleName="unavailable-message">
            <div styleName="image-wrapper">
              <img src={HouseFloodImage} />
            </div>
            <h1>
              We're sorry — the page you are looking for is not available.
            </h1>
            <p>
              Please reach out to{" "}
              <a href="mailto:support@withforerunner.com">
                support@withforerunner.com
              </a>{" "}
              if you have any questions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div styleName="header">
      <div styleName="content">
        <img src={Logo} />
      </div>
    </div>
  );
};

export default UnavailablePage;
