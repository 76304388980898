import React, { useContext } from "react";

import { DisclaimerName } from "../../../../generated/graphql";
import {
  defaultFirmDigitizationDisclaimer,
  getDisclaimerText,
} from "common-client/utils/disclaimerOverrides";
import Disclaimer from "../../../Common/Disclaimer";
import { AuthContext } from "../../../Authorization/AuthContext";

export default ({
  accountFIRMData,
}: {
  accountFIRMData?: Array<{ digitized?: Maybe<boolean>; name: string }>;
}) => {
  const { account, isGuest } = useContext(AuthContext);
  const digitizedFIRMs = accountFIRMData?.filter(firm => firm.digitized) ?? [];
  if (digitizedFIRMs.length) {
    const disclaimerText = getDisclaimerText({
      disclaimerOverrides: account?.disclaimerOverrides,
      name: DisclaimerName.FIRM_DIGITIZATION,
      isPublic: isGuest,
      defaultDisclaimers: {
        public: defaultFirmDigitizationDisclaimer({
          digitizedFIRMs,
        }),
        internal: defaultFirmDigitizationDisclaimer({
          digitizedFIRMs,
        }),
      },
    });

    return <Disclaimer message={disclaimerText} />;
  } else {
    return null;
  }
};
