import React from "react";
import { Layer, Source } from "react-map-gl";
import { LayerContext } from ".";
import { FIRM } from "./firm";

export const group = "panels";

class MapLayer extends React.PureComponent<{ firm: FIRM }> {
  render() {
    const { isLayerVisible } = this.context;
    const { firm } = this.props;

    const tiles = [
      `${window.env.APPLICATION_URL}/api/tiles/firmPanels/${firm.id}/{z}/{x}/{y}`,
    ];
    const id = `${group}-${firm.id}`;
    const color = " #ffffff";

    return (
      <Source id={id} tiles={tiles} type="vector">
        <Layer
          id={`${id}-boundaries`}
          type="line"
          source-layer="src"
          layout={{
            visibility: isLayerVisible({ group, id: firm.id })
              ? "visible"
              : "none",
          }}
          paint={{
            "line-color": color,
            "line-width": 1.8,
          }}
          beforeId="ground"
        />
        <Layer
          id={`${id}-labels`}
          type="symbol"
          source-layer="src"
          layout={{
            visibility: isLayerVisible({ group, id: firm.id })
              ? "visible"
              : "none",
            "text-field": ["concat", "Panel ", ["get", "panel_number"]],
            "text-padding": 10,
            "text-max-width": 18.5,
            "text-size": 18,
            "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
          }}
          paint={{
            "text-color": color,
            "text-halo-color": "hsla(0, 0%, 2%, 0.82)",
            "text-opacity": ["step", ["zoom"], 0, 12, 1],
            "text-halo-width": 1,
          }}
        />
      </Source>
    );
  }
}

MapLayer.contextType = LayerContext;

export default MapLayer;
