import React from "react";
import { Layer, Source } from "react-map-gl";

import { LayerContext } from ".";

class MapLayer extends React.PureComponent<{
  rasterId: string;
}> {
  render() {
    const { isLayerVisible } = this.context;

    const { rasterId } = this.props;

    const visibility = isLayerVisible({ group: "rasters", id: rasterId })
      ? "visible"
      : "none";

    const tiles = [
      `${window.env.APPLICATION_URL}/api/tiles/rasters/${rasterId}/{z}/{x}/{y}`,
    ];

    // const opacity = layerOpacityState({ groupId: "rasters" });

    return (
      <React.Fragment>
        <Source id={rasterId} tiles={tiles} type="raster" tileSize={256}>
          <Layer
            source={rasterId}
            type="raster"
            layout={{
              visibility,
            }}
            paint={{
              "raster-brightness-max": 1,
              "raster-brightness-min": 0,
              "raster-opacity": 1,
              "raster-hue-rotate": 0,
              "raster-saturation": 0,
              "raster-fade-duration": 0.3,
              "raster-resampling": "linear",
            }}
          ></Layer>
        </Source>
      </React.Fragment>
    );
  }
}

MapLayer.contextType = LayerContext;

export default MapLayer;
