import React, { createContext, ReactNode, useContext, useState } from "react";
import { AddressPanelContext } from "../AddressPanelContext";
import {
  CustomMapIntersection,
  useGetPropertyOverviewDataQuery,
} from "../../../generated/graphql";

/** Dummy ID for the case where an account has no FIRMs */
export const NO_FIRM_ID = "no-firm";

export type PropertyOverviewContextType = {
  loading: boolean;
  customMapsInfo: Array<CustomMapIntersection>;
  longitude?: Maybe<number>;
  latitude?: Maybe<number>;
};

export const PropertyOverviewContext =
  createContext<PropertyOverviewContextType>({
    loading: false,
    customMapsInfo: [],
  });

export const PropertyOverviewContextProvider = ({
  children,
  longitude,
  latitude,
}: {
  children: ReactNode;
  longitude: number;
  latitude: number;
}) => {
  const { property } = useContext(AddressPanelContext);

  const [customMapsInfo, setCustomMapsInfo] = useState<
    Array<CustomMapIntersection>
  >([]);

  const propertyId = property?.id;

  const { loading } = useGetPropertyOverviewDataQuery({
    variables: {
      propertyId,
      point: {
        crs: { type: "name", properties: { name: "EPSG:4326" } },
        type: "Point",
        coordinates: [longitude, latitude],
      },
    },
    onCompleted: data => {
      setCustomMapsInfo(data.customMapsInfo ?? []);
    },
  });

  return (
    <PropertyOverviewContext.Provider
      value={{
        loading,
        customMapsInfo,
        latitude,
        longitude,
      }}
    >
      {children}
    </PropertyOverviewContext.Provider>
  );
};
