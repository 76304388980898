export const getPreviousLocation = (props, window, landingPage = "/map") => {
  const urlSearchParams = new URLSearchParams(window?.location?.search);
  const queryString = Object.fromEntries(urlSearchParams.entries());

  return props.location?.state?.referrer ?? queryString.prev ?? landingPage;
};

export const getLoginPageURL = location => {
  return `/login?prev=${encodeURIComponent(
    location.pathname + location.search
  )}`;
};
