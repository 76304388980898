import React from "react";
import { ApolloProvider } from "@apollo/client";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/client";
import { Router } from "react-router";
import { History } from "history";
import { ToastProvider } from "react-toast-notifications";

import ErrorBoundary from "./ErrorBoundary";
import CompatibilityCheck from "./CompatibilityCheck";
import Toast from "./Common/Toast";
import getApolloClient from "../apollo";

export type AppType = "guest" | "client";

export const AppScaffold: React.FC<{
  history: History;
  app?: AppType;
}> = ({ history, app = "client", children }) => {
  const ApolloClient = getApolloClient(app);
  return (
    <ToastProvider placement="bottom-right" components={{ Toast }}>
      <ApolloProvider client={ApolloClient}>
        <ApolloHooksProvider client={ApolloClient}>
          <CompatibilityCheck app={app}>
            <Router history={history}>
              <ErrorBoundary>{children}</ErrorBoundary>
            </Router>
          </CompatibilityCheck>
        </ApolloHooksProvider>
      </ApolloProvider>
    </ToastProvider>
  );
};
