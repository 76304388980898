import React from "react";
import { Banner as BannerStyledComponent } from "./__styles__/Banner";
import type Stitches from "@stitches/react";

interface BannerProps
  extends Stitches.VariantProps<typeof BannerStyledComponent> {
  style?: React.CSSProperties;
}

const Banner: React.FC<BannerProps> = ({
  children,
  warning = false,
  beta = false,
  grey = false,
  compact = false,
  style = {},
}) => {
  return (
    <BannerStyledComponent
      warning={warning}
      compact={compact}
      beta={beta}
      grey={grey}
      style={style}
      data-testid="firm-warnings-banner"
    >
      {children}
    </BannerStyledComponent>
  );
};

export default Banner;
