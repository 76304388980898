import React from "react";
import { Source, Layer } from "react-map-gl";
import { LayerContext } from ".";
import { Point } from "../LayeredMap";
import { get } from "lodash";
import { colors } from "../../../stitches.config";

export const id = "measure-distance-tool";

const PAINTS = {
  raster: {
    "circle-radius": 5,
    "circle-color": colors.mapSelected.value,
    "circle-stroke-color": colors.bgUiSurface.value,
    "circle-stroke-width": 3,
  },
  distance: {
    "circle-radius": 5,
    "circle-color": colors.featureGrey.value,
  },
};

class MapLayer extends React.PureComponent<{}> {
  render() {
    const { measureToolState } = this.context;
    const paint = get(PAINTS, measureToolState.measureToolMode, PAINTS.raster);

    const measureData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: "FeatureCollection",
      features: [],
    };

    const coordinates: Point[] = measureToolState.coordinates;

    coordinates.forEach(coordinate => {
      measureData.features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [coordinate.longitude, coordinate.latitude],
        },
        properties: {},
      });
    });

    if (coordinates.length > 1) {
      measureData.features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: coordinates.map(coordinate => [
            coordinate.longitude,
            coordinate.latitude,
          ]),
        },
        properties: {},
      });
    }

    return (
      <Source id={id} data={measureData} type="geojson">
        <Layer
          id={`${id}-points`}
          type="circle"
          source={"geojson"}
          filter={["in", "$type", "Point"]}
          paint={paint}
        />
        <Layer
          id={`${id}-lines`}
          type="line"
          source={"geojson"}
          layout={{
            "line-cap": "round",
            "line-join": "round",
          }}
          filter={["in", "$type", "LineString"]}
          paint={{
            "line-width": 2.5,
            "line-color": colors.featureGrey.value,
          }}
        />
      </Source>
    );
  }
}

MapLayer.contextType = LayerContext;

export default MapLayer;
