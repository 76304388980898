import React from "react";
import { Layer, Source } from "react-map-gl";
import { colors } from "../../../stitches.config";

export const id = "parcels";
export const MIN_PARCEL_ZOOM = 15;

class MapLayer extends React.Component<{ accountId: string }> {
  render() {
    const { accountId } = this.props;
    const tiles = [
      `${window.env.APPLICATION_URL}/api/tiles/parcels/{z}/{x}/{y}?accountId=${accountId}`,
    ];

    return (
      <Source
        id={id}
        tiles={tiles}
        type="vector"
        //@ts-ignore - the TS types for mapbox gl leave a lot to be desired
        promoteId="id"
        minzoom={MIN_PARCEL_ZOOM}
      >
        <Layer
          id={`${id}`}
          type="fill"
          source-layer="src"
          paint={{
            "fill-color": "hsla(0, 0%, 100%, 0)",
          }}
          beforeId="ground"
        ></Layer>
        <Layer
          id={`${id}-boundaries-default`}
          type="line"
          source-layer="src"
          paint={{
            "line-color": colors.mapOutlineLight.value,
            "line-width": 0.8,
          }}
          beforeId="ground"
        ></Layer>
        <Layer
          id={`${id}-boundaries-overlays`}
          type="line"
          source-layer="src"
          minzoom={MIN_PARCEL_ZOOM}
          paint={{
            "line-opacity": [
              "case",
              ["==", ["feature-state", "hover"], true],
              1,
              ["==", ["feature-state", "click"], true],
              1,
              0,
            ],
            "line-color": [
              "case",
              ["==", ["feature-state", "click"], true],
              colors.mapSelected.value,
              ["==", ["feature-state", "hover"], true],
              colors.mapHovered.value,
              colors.mapOutlineLight.value,
            ],
            "line-width": [
              "case",
              ["boolean", ["feature-state", "click"], false],
              3,
              1.5,
            ],
          }}
          beforeId="ground"
        ></Layer>
      </Source>
    );
  }
}

export default MapLayer;
