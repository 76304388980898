import React from "react";

import "./__styles__/Icon.scss";

export default () => {
  return (
    <svg
      width="160"
      height="160"
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="160" height="160" fill="white" />
      <path
        d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
        fill="#E4E5E7"
      />
      <path
        d="M100.281 104.987H81.6145C77.9921 104.987 74.2355 104.106 71.037 102.507L65.0181 99.4974C64.5663 99.2708 64.2811 98.8099 64.2811 98.3047V79.638C64.2811 79.3255 64.3905 79.0235 64.5903 78.7839L77.6145 63.155V52.9714C77.6145 51.5339 78.3599 50.2487 79.6086 49.5313C80.8495 48.819 82.3261 48.8229 83.5578 49.543C88.1223 52.2071 88.2811 58.9336 88.2811 63.638V70.3203H102.948C106.624 70.3203 109.614 73.3034 109.614 76.9714C109.614 77.026 109.611 77.082 109.604 77.1367L106.947 98.3919C106.9 102.036 103.928 104.987 100.281 104.987Z"
        fill="white"
      />
      <path
        d="M63 105.025H52.3333C51.597 105.025 51 104.429 51 103.692V74.3585C51 73.6215 51.597 73.0251 52.3333 73.0251H63C63.7363 73.0251 64.3333 73.6215 64.3333 74.3585V103.692C64.3333 104.429 63.7363 105.025 63 105.025Z"
        fill="#434A58"
      />
    </svg>
  );
};
