import React from "react";

import "./__styles__/Icon.scss";

const CircleX = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="remove"
      role="img"
    >
      <title id="remove">Remove</title>
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#7E8490" />
      <path
        d="M10.8875 5.1125C10.7375 4.9625 10.5125 4.9625 10.3625 5.1125L8 7.475L5.6375 5.1125C5.4875 4.9625 5.2625 4.9625 5.1125 5.1125C4.9625 5.2625 4.9625 5.4875 5.1125 5.6375L7.475 8L5.1125 10.3625C4.9625 10.5125 4.9625 10.7375 5.1125 10.8875C5.1875 10.9625 5.2625 11 5.375 11C5.4875 11 5.5625 10.9625 5.6375 10.8875L8 8.525L10.3625 10.8875C10.4375 10.9625 10.55 11 10.625 11C10.7 11 10.8125 10.9625 10.8875 10.8875C11.0375 10.7375 11.0375 10.5125 10.8875 10.3625L8.525 8L10.8875 5.6375C11.0375 5.4875 11.0375 5.2625 10.8875 5.1125Z"
        fill="#7E8490"
      />
    </svg>
  );
};

export default CircleX;
