import React from "react";
import Icon, { Props as IconProps } from "./Icons";

import "./__styles__/IconText.scss";

const IconText: React.FC<IconProps> = props => (
  <div styleName="container">
    <div styleName="icon">
      <Icon {...props} />
    </div>
    <div styleName="text">{props.children}</div>
  </div>
);

export default IconText;
