import React, { useMemo } from "react";
import { LayerConfig, LayerHook, LayerHookBuilder } from "./types";

export type ContourLayerConfig = LayerConfig;

import ContourLayer, { id as layerId } from "../layers/contourLines";

const contourHook: LayerHookBuilder<ContourLayerConfig> = ({ config }) => {
  return useMemo<LayerHook>(() => {
    return {
      interactiveLayerIds: [layerId],
      render: () => <ContourLayer key={"contour"} />,
    };
  }, [config]);
};

export default contourHook;
