import { hot } from "react-hot-loader/root";
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { getPath } from "common/routing";
import ResidentPropertyPage from "./PropertyPage";
import GetHelpPage from "./GetHelp";
import GuestAuthContextProvider from "../Authorization/GuestAuthContext";
import NoPropertyPage from "./NoPropertyPage";
import GuestPropertySearch from "./index";
import CertificatesPage from "./ElevationCertificates";
import UploadElevationCertificatesPage from "./ElevationCertificates/UploadElevationCertificates";
import { Explore } from "./Explore";
import CreateSubmissionContainer from "../Submissions/CreateSubmission";
import DetailView from "../DocumentUploads/DetailView";
import AccessDeniedPage, { ACCESS_DENIED_RESOURCE } from "./AccessDeniedPage";

// we have to export this in isolation so that hot reloading works
export const MainRoutes = hot(() => (
  <Switch>
    <GuestAuthContextProvider>
      <Switch>
        <Redirect exact from="/" to={getPath("guestHome")} />
        <Route
          path={getPath("guestUploadElevationCertificates")}
          component={UploadElevationCertificatesPage}
        />
        <Route
          path={getPath("guestElevationCertificates")}
          component={CertificatesPage}
        />
        <Route
          path={getPath("guestDocumentUploadDetail")}
          component={DetailView}
        />
        <Route
          // if we have gotten to this route, the middleware has not found a file
          // therefore we should always return an access denied page
          path={getPath("guestDocumentUploadFile")}
          render={() => (
            <AccessDeniedPage resource={ACCESS_DENIED_RESOURCE.FILE} />
          )}
        />
        <Route path={getPath("getHelp")} component={GetHelpPage} />
        <Route path={getPath("noProperty")} component={NoPropertyPage} />
        <Route
          path={getPath("residentProperty")}
          component={ResidentPropertyPage}
        />
        <Route path={getPath("guestHome")} component={GuestPropertySearch} />
        <Route path={getPath("explore")} component={Explore} />
        <Route
          path={getPath("createSubmission")}
          component={CreateSubmissionContainer}
        />
      </Switch>
    </GuestAuthContextProvider>
  </Switch>
));
