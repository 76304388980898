import React, { useContext } from "react";
import { Layer, Source } from "react-map-gl";

import { LayerContext } from ".";
import { colors } from "../../../stitches.config";

export const viewsSourceId = "saved-views-source";
export const viewsLayerId = "saved-views";
export const group = "savedViews";

export const SAVED_VIEW_MIN_ZOOM = 12;

const SavedViewsLayer = ({ savedViewId }: { savedViewId: string }) => {
  const { isLayerVisible, mapNonce } = useContext(LayerContext);

  const viewTiles = [
    `${window.env.APPLICATION_URL}/api/tiles/views/${savedViewId}/{z}/{x}/{y}?nonce=${mapNonce}`,
  ];

  return (
    <Source
      id={`${viewsSourceId}-${savedViewId}`}
      tiles={viewTiles}
      type="vector"
      minzoom={SAVED_VIEW_MIN_ZOOM}
    >
      <Layer
        id={`${viewsLayerId}-${savedViewId}`}
        type="circle"
        source-layer="src"
        layout={{
          visibility: isLayerVisible({ group, id: savedViewId })
            ? "visible"
            : "none",
        }}
        paint={{
          "circle-opacity": 1,
          "circle-stroke-opacity": 1,
          "circle-radius": 5,
          "circle-stroke-width": 1.5,
          "circle-stroke-color": colors.mapOutlineLight.value,
          "circle-color": colors.mapSelected.value,
        }}
      />
    </Source>
  );
};

export default SavedViewsLayer;
