import React, { ComponentProps } from "react";
import { Icon, IconNames } from "./Icons/LucideIcons";
import { Tag as StyledTag } from "./__styles__/Tag";
import { Body } from "./Typography";
import { spacing } from "../../stitches.config";

const IconColorMap = {
  default: "contentInfo",
  neutral: "contentSecondary",
  warning: "contentAttentionAccessible",
  critical: "contentCritical",
  neutralDark: "contentSecondaryDark",
} as const;

export type StyleVariant =
  | "default"
  | "neutral"
  | "warning"
  | "critical"
  | "neutralDark";

export type TagProps = Omit<
  ComponentProps<typeof StyledTag>,
  "css" | "styleVariant" | "onClick"
> & {
  styleVariant: StyleVariant;
  leftIconName?: IconNames;
  loading?: boolean;
  onClose?: () => void;
  disabled?: boolean;
};

export const Tag = ({
  leftIconName,
  loading,
  styleVariant,
  onClose,
  disabled,
  ...props
}: TagProps) => {
  return (
    <StyledTag styleVariant={styleVariant}>
      {leftIconName && (
        <Icon
          iconName={leftIconName}
          color={IconColorMap[styleVariant]}
          size={12}
        />
      )}
      <Body
        type="emphasis"
        size="small"
        color={
          styleVariant === "neutralDark"
            ? "contentPrimaryDark"
            : "contentPrimary"
        }
      >
        {props.children}
      </Body>
      {onClose && (
        <button
          onClick={onClose}
          disabled={disabled}
          style={{ paddingTop: spacing["2xs"].value }}
        >
          <Icon
            iconName={"x"}
            color={
              styleVariant === "neutralDark"
                ? "contentSecondaryDark"
                : "contentSecondary"
            }
            size={12}
            data-testid={"tag-close-icon"}
          />
        </button>
      )}
    </StyledTag>
  );
};
