import React, { ReactNode, useContext } from "react";
import {
  generateDFETooltip,
  getDFEElevationConfiguration,
  isUserOverrideable,
  Property,
} from "common-client/utils/firms";
import { buildLink } from "common/routing";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";

import { FIRMTableTooltip } from "..";
import {
  DesignFloodElevationType,
  DfeRuleType,
} from "../../../../../../generated/graphql";
import { useDesignFloodElevationEditModal } from "../../DesignFloodElevationEditModal";
import { FIRMInfoRow } from "./FIRMInfoRow";
import { track } from "../../../../../../utils/tracking";

import { flatMap, isNil } from "lodash";
import { RequestInfoLink } from "../__styles__/FIRMTable";
import { AuthContext } from "../../../../../Authorization/AuthContext";
import { RenderMode } from "../../types";
import { FIRMInfoContext } from "../../FIRMInfoContext";

interface DesignFloodElevationRowProps {
  property?: Maybe<Property>;
  size: RenderMode;
  hasRequestBFEHelpOption?: boolean;
  isPublic: boolean;
  label: string;
}

export const DesignFloodElevationRow = ({
  property,
  size,
  hasRequestBFEHelpOption = false,
  label,
  isPublic,
}: DesignFloodElevationRowProps) => {
  const { authorized, account, isGuest } = useContext(AuthContext);
  const { activeFirmIds, firms } = useContext(FIRMInfoContext);
  const activeFirms = firms.filter(firm => activeFirmIds.includes(firm.id));

  const designFloodElevation = property?.designFloodElevation;
  const value = designFloodElevation?.value;
  const dfeRuleType = designFloodElevation?.calculated?.rule?.type;
  const override = designFloodElevation?.override;
  const isFromOverride = !!designFloodElevation?.override;

  const type = override
    ? DesignFloodElevationType.CUSTOM
    : DesignFloodElevationType.DEFAULT;
  const [showDesignFloodElevationModal] = useDesignFloodElevationEditModal({
    dfe: { type, stringValue: value },
    property,
  });

  if (
    !isUserOverrideable(property?.designFloodElevation ?? null) ||
    activeFirms.every(firm => !firm.showDFE)
  )
    return null;

  const valueForFIRM = (firm: FIRMWithWarnings) => {
    const { value, canUpdateDFE, canRequestDFE } = getDFEElevationConfiguration(
      {
        firm,
        property,
        authorized,
        isGuest,
        hasRequestBFEHelpOption,
      }
    );
    return (
      value ??
      (canUpdateDFE ? "Add" : null) ??
      (canRequestDFE ? "Request info" : null)
    );
  };

  const tooltip =
    (dfeRuleType === DfeRuleType.AUTOMATIC ||
      dfeRuleType === DfeRuleType.MINIMUM) &&
    !isNil(value) &&
    !isFromOverride ? (
      <FIRMTableTooltip
        isGuestOnly={false}
        tooltipMarkdown={
          generateDFETooltip(
            designFloodElevation!.calculated!.rule!,
            flatMap(
              account?.propertyInformationSections.map(
                section => section.accountPropertyAttributes
              )
            )
          )!
        }
      />
    ) : null;

  const wrapper = ({
    children,
    firmId,
  }: {
    children?: ReactNode;
    firmId: string;
  }) => {
    const firm = activeFirms.find(firm => firm.id === firmId)!;
    const { canUpdateDFE, canRequestDFE } = getDFEElevationConfiguration({
      firm,
      property,
      authorized,
      isGuest,
      hasRequestBFEHelpOption,
    });

    if (canRequestDFE) {
      return (
        <RequestInfoLink
          to={buildLink(
            "getHelp",
            {},
            { propertyId: property?.id, reasonCodes: "requestBFE" }
          )}
          onClick={() => track("Request DFE")}
        >
          {children}
        </RequestInfoLink>
      );
    }

    if (canUpdateDFE) {
      return (
        <a
          onClick={() => showDesignFloodElevationModal()}
          data-testid={"editDfe"}
        >
          {children}
        </a>
      );
    } else {
      return <>{children}</>;
    }
  };

  return (
    <FIRMInfoRow
      property={"dfe"}
      valueForFIRM={valueForFIRM}
      label={label}
      wrap={wrapper}
      size={size}
      tooltip={tooltip}
      isPublic={isPublic}
    />
  );
};
