import React from "react";
import { Button } from "../../Common/Button";
import DropdownMenu, { ActionsProps } from "../../Inputs/DropdownMenu";

const CarouselModalActionButton = ({
  actions,
}: {
  actions: ActionsProps[];
}) => {
  if (actions.length === 0) {
    return null;
  }

  const button = ({
    onClick,
  }: {
    onClick: (_: React.MouseEvent<HTMLElement>) => void;
  }) => {
    return (
      <Button
        onClick={onClick}
        size="small"
        styleVariant="outlineDark"
        tabIndex={0}
        rightIconName={"chevron-down"}
      >
        Actions
      </Button>
    );
  };

  return (
    <DropdownMenu
      buttonText={"Actions"}
      actions={actions}
      customButton={button}
    />
  );
};

export default CarouselModalActionButton;
