import React from "react";
import { useMemo } from "react";
import { LayerConfig, LayerHookBuilder, LayerHook } from "./types";
import RasterLayer from "../layers/raster";
import { Raster } from "../../../generated/graphql";

export type RasterLayerConfig = LayerConfig & { rasters: Array<Raster> };

const rasterHook: LayerHookBuilder<RasterLayerConfig> = ({ config }) => {
  return useMemo<LayerHook>(() => {
    return {
      render: () => {
        return config.rasters.map(raster => (
          <RasterLayer rasterId={raster.id} key={raster.id} />
        ));
      },
    };
  }, [config]);
};

export default rasterHook;
