import React from "react";

import "./__styles__/Icon.scss";

export default () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9938 5.88016 15.149 3.8489 13.6501 2.34995C12.1511 0.850987 10.1198 0.00615597 8 0V0ZM11.3 10.3573L10.3573 11.3L8 8.94267L5.64267 11.3L4.7 10.3573L7.05734 8L4.7 5.64267L5.64267 4.7L8 7.05733L10.3573 4.7L11.3 5.64267L8.94267 8L11.3 10.3573Z"
        fill="#D84D48"
      />
    </svg>
  );
};
