import React, { ReactNode, useContext } from "react";
import { RESOURCE_NAME } from "common/authorization";
import { AuthContext } from "../../../../../Authorization/AuthContext";
import { FIRMInfoRow } from "./FIRMInfoRow";
import {
  EditableMode,
  editableInformationForFIRM,
  formatDatum,
  isEmptyFIRM,
} from "common-client/utils/firms";
import { FIRMWithWarnings } from "common-client/utils/firmInfoWarnings";
import { RenderMode } from "../../types";
import { FIRMInfoContext } from "../../FIRMInfoContext";

interface DatumRowProps {
  size: RenderMode;
  isPublic: boolean;
  tooltip?: ReactNode;
  label: string;
  onFIRMEdit: (args: { firm: FIRMWithWarnings; mode: EditableMode }) => void;
}

export const DatumRow = ({
  size,
  label,
  isPublic,
  tooltip,
  onFIRMEdit,
}: DatumRowProps) => {
  const { authorized } = useContext(AuthContext);
  const { firms } = useContext(FIRMInfoContext);

  const canUpdateFIRM = authorized({
    resource: RESOURCE_NAME.FIRM,
    permission: "update",
  });

  const valueForFIRM = (firm: FIRMWithWarnings): Maybe<string> => {
    if (canUpdateFIRM && !isEmptyFIRM(firm)) {
      return formatDatum(firm.datum ?? null) || "Add";
    }

    return formatDatum(firm.datum ?? null);
  };

  const wrapper = ({
    children,
    firmId,
  }: {
    children?: ReactNode;
    firmId: string;
  }) => {
    const firm = firms.find(({ id }) => id === firmId)!;
    const canOverrideDatum =
      editableInformationForFIRM(firm)["datum"] &&
      canUpdateFIRM &&
      !isEmptyFIRM(firm);

    if (canOverrideDatum) {
      return (
        <a
          onClick={() => {
            onFIRMEdit({ firm, mode: "datum" });
          }}
        >
          {children}{" "}
        </a>
      );
    } else {
      return <>{children}</>;
    }
  };

  return (
    <FIRMInfoRow
      property={"datum"}
      valueForFIRM={valueForFIRM}
      label={label}
      isPublic={isPublic}
      tooltip={tooltip}
      wrap={wrapper}
      size={size}
      skipIfBlank
    />
  );
};
