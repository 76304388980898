import React from "react";
import { AuthContextType, CurrentAdmin } from "./types";
import { FEATURE_FLAG_NAME } from "common/constants";

export const AuthContext = React.createContext<AuthContextType>({
  account: null,
  user: null,
  admin: null,
  isGuest: true,
  authorized: () => false,
  setAdmin: (_admin: CurrentAdmin) => {},
  isFeatureEnabled: (_: FEATURE_FLAG_NAME) => false,
});
