import React from "react";

import "./__styles__/Icon.scss";

const Paperclip = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 6.74994L10.4813 5.23119C11.5065 4.20594 11.5065 2.54394 10.4813 1.51869C9.456 0.493443 7.794 0.493443 6.76875 1.51869L5.25 2.99994"
        stroke="#2A70FF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.74994 9L5.23119 10.4813C4.20594 11.5065 2.54394 11.5065 1.51869 10.4813C0.493443 9.456 0.493443 7.794 1.51869 6.76875L2.99994 5.25"
        stroke="#2A70FF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 8.25L8.25 3.75"
        stroke="#2A70FF"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Paperclip;
