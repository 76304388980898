import React from "react";

import EditImprovementDropdown from "./EditImprovementDropdown";
import { AuthContext } from "../../Authorization/AuthContext";
import { formatDateString } from "common/utils/dates";

import {
  Item,
  Header,
  Description,
  HeaderInfo,
  TagsWrapper,
} from "./__styles__/ListItem";
import EditInspectionDropdown from "../Inspections/EditInspectionDropdown";
import { isImprovement, PartialImprovement, PartialInspection } from "./utils";
import { StyleVariant, Tag } from "../../Common/Tag";

export interface ListItemProps {
  address?: Maybe<string>;
  item: PartialImprovement | PartialInspection;
  onUpdate: () => {};
  date: Date;
  title: string;
  info?: string;
  excluded?: boolean;
  showDots?: boolean;
}

const ListItem = ({
  address,
  onUpdate,
  item,
  date,
  title,
  info,
  excluded = false,
  showDots = true,
}: ListItemProps) => {
  const { isGuest } = React.useContext(AuthContext);
  const formattedDate = formatDateString({
    format: "MM/DD/YYYY",
    dateString: date.toString(),
  });

  const dropdown = isImprovement(item) ? (
    <EditImprovementDropdown
      onUpdate={onUpdate}
      improvement={item}
      address={address}
    />
  ) : (
    <EditInspectionDropdown
      onUpdate={onUpdate}
      inspection={item}
      address={address}
    />
  );

  return (
    <Item excluded={excluded}>
      <Header>
        <div tabIndex={0}>
          {formattedDate} {title}
        </div>
        <HeaderInfo showDots={showDots} tabIndex={0}>
          {info}
          {!isGuest && showDots && dropdown}
        </HeaderInfo>
      </Header>
      {item.description && (
        <Description tabIndex={0}>{item.description}</Description>
      )}
      {!isImprovement(item) && !!item.tags.length && (
        <TagsWrapper role="list">
          {item.tags.map(({ text, display }) => {
            return (
              <Tag styleVariant={display as StyleVariant} key={text}>
                {text}
              </Tag>
            );
          })}
        </TagsWrapper>
      )}
    </Item>
  );
};

export default ListItem;
