import React from "react";
import MobileDocumentLink from "../../../MobileDocumentLink";
import DocumentForm from "..";
import {
  FIELDS_1982,
  FIELDS_1984_1987,
  FIELDS_1990,
  FIELDS_1993_1996_1999,
  FIELDS_2002_2005,
  MODERN_EC,
  buildFields,
  FIELDS_2012_2022,
} from "./utils";
import { AuthContext } from "../../../../Authorization/AuthContext";
import {
  CertificateFormFieldDottableKeys,
  CertificateFormFields,
} from "../CertificatesInputs";
import {
  GetDocumentUploadDocument,
  useRemoveLowConfidenceMutation,
  useUpdateCertificateMutation,
} from "../../../../../generated/graphql";
import { track } from "../../../../../utils/tracking";
import { RESOURCE_NAME } from "common/authorization";

import { DocumentUploadContext } from "../../../Tabs";
import { CertificateType } from "../../../__queries__";
import Banner from "../../../../Common/Banner";
import { useStatusToasts } from "../../../../../hooks/useStatusToasts";

export const fieldsForCertificate = (
  certificate: Pick<CertificateType, "formYear">
) => {
  if (!certificate.formYear) {
    return MODERN_EC;
  }

  if (certificate.formYear === 1982) {
    return FIELDS_1982;
  } else if (1984 <= certificate.formYear && certificate.formYear <= 1987) {
    return FIELDS_1984_1987;
  } else if (certificate.formYear === 1990) {
    return FIELDS_1990;
  } else if (1993 <= certificate.formYear && certificate.formYear <= 1999) {
    return FIELDS_1993_1996_1999;
  } else if (2002 <= certificate.formYear && certificate.formYear <= 2005) {
    return FIELDS_2002_2005;
  } else if (2012 <= certificate.formYear && certificate.formYear <= 2022) {
    return FIELDS_2012_2022;
  }

  return MODERN_EC;
};

export type Props = {
  fileUrl: string;
};

const AccuracyBanner = (
  <Banner compact={true} beta={true}>
    Please review all extracted fields in yellow to ensure that they are
    accurately transcribed.
  </Banner>
);

export default ({ fileUrl }: Props) => {
  const { addSuccessToast, addErrorToast } = useStatusToasts();
  const { documentUpload, sidebarRef } = React.useContext(
    DocumentUploadContext
  );
  const { authorized } = React.useContext(AuthContext);

  const canEditCertificates = authorized({
    resource: RESOURCE_NAME.CERTIFICATE,
    permission: "update",
  });

  const [updateCertificate, { loading }] = useUpdateCertificateMutation({
    refetchQueries: [
      {
        query: GetDocumentUploadDocument,
        variables: { id: documentUpload.id, isGuest: false },
      },
    ],
    onCompleted: () => {
      addSuccessToast(
        "Your edits were successfully saved and your file was reprocessed"
      );
    },
    onError: () => {
      addErrorToast(
        "Sorry! There was an issue saving the field(s) you edited. Please try again."
      );
    },
  });

  const [removeLowConfidence] = useRemoveLowConfidenceMutation();

  const confirmAccuracy = ({ fields }: { fields: Array<string> }) => {
    void removeLowConfidence({
      variables: {
        data: {
          certificateUploadId: documentUpload.certificateUpload!.id,
          fields,
        },
      },
    });
  };

  if (
    !documentUpload.elevationCertificate ||
    !documentUpload.certificateUpload
  ) {
    return null;
  }

  const lowConfidenceFields = Object.keys(
    documentUpload.certificateUpload.lowConfidence ?? {}
  ) as Array<CertificateFormFieldDottableKeys>;

  const fields = fieldsForCertificate(documentUpload.elevationCertificate);

  return (
    <>
      {!!lowConfidenceFields.length && AccuracyBanner}
      <DocumentForm
        inputs={buildFields({
          certificate: documentUpload.elevationCertificate,
          fields,
          lowConfidenceFields,
        })}
        onFormSubmit={async (
          transcribedData: DeepPartial<CertificateFormFields>
        ) => {
          track('Clicked "Save Extraction(s)"', {
            formYear: documentUpload.elevationCertificate!.formYear,
            fields: Object.keys(transcribedData).join(", "),
          });

          await updateCertificate({
            variables: {
              data: {
                transcribedData,
                id: documentUpload.elevationCertificate!.id,
              },
            },
          });
        }}
        loading={loading}
        onConfirmAccuracy={confirmAccuracy}
        scrollRef={sidebarRef}
        disabled={!canEditCertificates}
      >
        <MobileDocumentLink fileUrl={fileUrl} documentUpload={documentUpload} />
      </DocumentForm>
    </>
  );
};
