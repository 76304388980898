import React, { useContext } from "react";

import TabGroup from "../Common/TabGroup";
import Settings from "./Settings";

import "./__styles__/Tabs.scss";
import {
  CertificateUploadStatus,
  GetDocumentUploadQuery,
} from "../../generated/graphql";
import useNavigationTabs from "../../hooks/useNavigationTabs";
import { map, startCase } from "lodash";
import { track } from "../../utils/tracking";
import ExtractedFields from "./Forms/ElevationCertificates/ExtractedFields";
import Errors from "./ElevationCertificates/Errors";
import FormFields from "./Forms/GeneratedDocuments/FormFields";
import { AuthContext } from "../Authorization/AuthContext";

export enum TAB_NAME {
  FORM_FIELDS = "formFields",
  SETTINGS = "settings",
  ISSUES = "issues",
  EXTRACTED_FIELDS = "extractedFields",
}

type Account = { ecErrorDetectionEnabled: boolean };

export type DocumentUploadContextType = {
  documentUpload: NonNullable<GetDocumentUploadQuery["documentUpload"]>;
  sidebarRef: React.RefObject<HTMLDivElement>;
};

export const DocumentUploadContext =
  React.createContext<DocumentUploadContextType>({
    documentUpload: null as any,
    sidebarRef: null as any,
  });

interface TabBodyProps {
  tab?: TAB_NAME;
  setTab: (tab: TAB_NAME) => void;
  prevLocation: { pathname: string; search?: string } | string;
  fileUrl: string;
  documentUpload: NonNullable<GetDocumentUploadQuery["documentUpload"]>;
  sidebarRef: React.RefObject<HTMLDivElement>;
  onUpdate: () => void;
}

const TabBody = (props: TabBodyProps) => {
  return (
    <DocumentUploadContext.Provider
      value={{
        documentUpload: props.documentUpload,
        sidebarRef: props.sidebarRef,
      }}
    >
      {props.tab === TAB_NAME.ISSUES && <Errors {...props} />}
      {props.tab === TAB_NAME.EXTRACTED_FIELDS && (
        <ExtractedFields {...props} />
      )}
      {props.tab === TAB_NAME.FORM_FIELDS && <FormFields {...props} />}
      {props.tab === TAB_NAME.SETTINGS && <Settings {...props} />}
    </DocumentUploadContext.Provider>
  );
};

const generateTabs = ({
  documentUpload,
  account,
  isGuest,
}: {
  documentUpload: NonNullable<GetDocumentUploadQuery["documentUpload"]>;
  account: Account;
  isGuest: boolean;
}) => {
  const tabs = [];

  if (
    documentUpload.certificateUpload?.status ===
    CertificateUploadStatus.COMPLETED
  ) {
    const lowConfidenceCount = Object.keys(
      documentUpload.certificateUpload.lowConfidence ?? {}
    ).length;
    const extractedFieldsLabel = lowConfidenceCount
      ? `Extracted fields (${lowConfidenceCount})`
      : "Extracted fields";
    tabs.push({
      value: TAB_NAME.EXTRACTED_FIELDS,
      label: extractedFieldsLabel,
    });
  }

  const { ecErrorDetectionEnabled } = account;
  if (
    ecErrorDetectionEnabled &&
    documentUpload.certificateUpload &&
    documentUpload.certificateUpload.status !== CertificateUploadStatus.CANCELED
  ) {
    let label: string;
    if (
      documentUpload.certificateUpload.status ===
      CertificateUploadStatus.COMPLETED
    ) {
      const validationErrorCount =
        documentUpload.elevationCertificate?.validationErrors?.count || 0;
      label = `Issues (${validationErrorCount})`;
    } else {
      label = "Issues";
    }
    tabs.unshift({
      value: TAB_NAME.ISSUES,
      label,
    });
  }

  if (documentUpload.formData && documentUpload.formStructure) {
    tabs.push({ value: TAB_NAME.FORM_FIELDS, label: "Form fields" });
  }

  if (!isGuest) {
    tabs.push({ value: TAB_NAME.SETTINGS, label: "Settings" });
  }

  return tabs;
};

export default ({
  documentUpload,
  account,
  fileUrl,
  prevLocation,
  sidebarRef,
  onUpdate,
}: {
  documentUpload: NonNullable<GetDocumentUploadQuery["documentUpload"]>;
  account: Account;
  fileUrl: string;
  prevLocation: { pathname: string; search?: string } | string;
  sidebarRef: React.RefObject<HTMLDivElement>;
  onUpdate: () => void;
}) => {
  const { isGuest } = useContext(AuthContext);

  const tabs = generateTabs({ documentUpload, account, isGuest });
  let defaultTab: TAB_NAME | undefined;

  if (
    documentUpload.certificateUpload?.status ===
    CertificateUploadStatus.COMPLETED
  ) {
    defaultTab = account.ecErrorDetectionEnabled
      ? TAB_NAME.ISSUES
      : TAB_NAME.EXTRACTED_FIELDS;
  } else if (
    documentUpload.certificateUpload?.status ===
      CertificateUploadStatus.PROCESSING &&
    account.ecErrorDetectionEnabled
  ) {
    defaultTab = TAB_NAME.ISSUES;
  } else if (documentUpload.formData && documentUpload.formStructure) {
    defaultTab = TAB_NAME.FORM_FIELDS;
  } else if (!isGuest) {
    defaultTab = TAB_NAME.SETTINGS;
  }

  const [tab, setTab] = useNavigationTabs<TAB_NAME | undefined>({
    defaultTab,
    allowedTabs: map(tabs, "value"),
  });

  const handleTabClick = (value: TAB_NAME) => {
    track("Certificate Tab Clicked", {
      fromTab: startCase(tab as string),
      toTab: startCase(value),
    });

    if (value === TAB_NAME.SETTINGS || value === TAB_NAME.ISSUES) {
      window.location.hash = "";
      sidebarRef?.current?.scrollTo({ top: 0 });
    }

    setTab(value);
  };

  return (
    <>
      {!!tab && (
        <div styleName="tab-header" data-testid="tab-header">
          <TabGroup currentTab={tab} setTab={handleTabClick} tabs={tabs} />
        </div>
      )}

      <div styleName="tab-body">
        <TabBody
          tab={tab}
          setTab={setTab}
          documentUpload={documentUpload}
          sidebarRef={sidebarRef}
          fileUrl={fileUrl}
          prevLocation={prevLocation}
          onUpdate={onUpdate}
        />
      </div>
    </>
  );
};
