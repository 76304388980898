import React from "react";
import {
  ButtonSection,
  Container,
  ContentSection,
  HeaderSection,
  PrimaryButtons,
} from "../__styles__/Modal";
import { Button } from "../Button";
import { buildLink } from "common/routing";
import { useHistory } from "react-router";

interface SuccessViewProps {
  headerText: string;
  residentLogId?: Maybe<string>;
  recipientEmail: string;
  onClose: () => void;
}
export const SuccessView = ({
  headerText,
  recipientEmail,
  residentLogId,
  onClose,
}: SuccessViewProps) => {
  const history = useHistory();

  return (
    <Container>
      <HeaderSection>
        <h1>{headerText}</h1>
      </HeaderSection>
      <ContentSection>
        <p>
          {`A public link has been successfully sent to the following email: ${recipientEmail}. `}
          {residentLogId &&
            "Click on the button below to add additional information to your resident log."}
        </p>
      </ContentSection>
      <ButtonSection>
        <PrimaryButtons>
          <Button onClick={onClose} size="medium" styleVariant="secondary">
            Close
          </Button>
          {residentLogId && (
            <Button
              onClick={() =>
                history.push({
                  pathname: buildLink("editLog", { id: residentLogId }),
                })
              }
              styleVariant="primary"
              size="medium"
            >
              Add log info
            </Button>
          )}
        </PrimaryButtons>
      </ButtonSection>
    </Container>
  );
};
