import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import {
  CancelationReason,
  CertificateUploadStatus,
} from "../../generated/graphql";
import { formatDate } from "common/utils/strings";
import HideFromPublicAction from "./HideFromPublicAction";

import { DataRow, Header, Section } from "./__styles__/Settings";
import PermitInfo from "./ElevationCertificates/PermitInfo";
import Banner from "../Common/Banner";
import { buildLink } from "common/routing";
import MobileDocumentLink from "./MobileDocumentLink";
import { DocumentUploadContext } from "./Tabs";

export interface SettingsProps {
  prevLocation: { pathname: string; search?: string } | string;
  fileUrl: string;
}

export const CANCELATION_REASON_TEXT = {
  [CancelationReason.CANNOT_GEOCODE]:
    "Forerunner was unable to process this file due to problems with geocoding.",
  [CancelationReason.NOT_EC]:
    "Forerunner was unable to process this file because it is not an Elevation Certificate.",
  [CancelationReason.QUALITY]:
    "Forerunner was unable to process this file due to problems with quality.",
  default: "Forerunner was unable to process this Elevation Certificate.",
} as const;

export default ({ prevLocation, fileUrl }: SettingsProps) => {
  const { documentUpload } = React.useContext(DocumentUploadContext);
  const { pathname, search } = useLocation();
  return (
    <React.Fragment>
      <Section>
        <Header>General Info</Header>
        <DataRow>
          <div>File type</div>
          <div>{documentUpload.accountDocumentType.name}</div>
        </DataRow>
        {!!documentUpload.issuedAt && (
          <DataRow>
            <div>Issue date</div>
            <div>{formatDate(documentUpload.issuedAt)}</div>
          </DataRow>
        )}
        {!!documentUpload.submission && (
          <DataRow>
            <div>Submission</div>
            <Link
              to={{
                pathname: buildLink("viewSubmission", {
                  submissionId: documentUpload.submission.id,
                }),
                state: { prevLocation: `${pathname}${search}` },
              }}
            >
              {
                documentUpload.submission.submissionTypeVersion.submissionType
                  .name
              }
            </Link>
          </DataRow>
        )}
      </Section>
      <HideFromPublicAction
        documentUploadId={documentUpload.id}
        hiddenFromPublic={documentUpload.hiddenFromPublic}
        hasSensitiveDocumentType={
          documentUpload.accountDocumentType.isSensitive
        }
        isViewable={
          !documentUpload.certificateUpload ||
          documentUpload.certificateUpload?.status ===
            CertificateUploadStatus.COMPLETED
        }
        propertyHiddenFromPublic={!!documentUpload.property?.hiddenFromPublic}
      />
      {documentUpload.certificateUpload?.status ===
        CertificateUploadStatus.CANCELED && (
        <Banner compact={true} warning={true}>
          <span>
            {
              CANCELATION_REASON_TEXT[
                documentUpload.certificateUpload?.cancelationReason ?? "default"
              ]
            }
          </span>{" "}
          Please upload a new file or reach out to support@withforerunner.com
          for support.
        </Banner>
      )}

      {documentUpload.certificateUpload?.status ===
        CertificateUploadStatus.COMPLETED && (
        <PermitInfo prevLocation={prevLocation} />
      )}
      <MobileDocumentLink fileUrl={fileUrl} documentUpload={documentUpload} />
    </React.Fragment>
  );
};
