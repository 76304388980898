import React, { useContext } from "react";
import { isEmpty, isNumber, isNil, isBoolean, capitalize } from "lodash";
import CertificateField from "../CertificateField";
import TextInput from "../../../../Inputs/Text";
import Textarea from "../../../../Inputs/Textarea";
import { Hint } from "./__styles__/ExtractedField";
import { Datepicker, Radio } from "../../../../Inputs";
import { formatDateString } from "common/utils/dates";
import {
  CertificateFormFieldDottableKeys,
  CertificateFormFields,
} from "../CertificatesInputs";
import {
  ActionsProps,
  DropdownPosition,
} from "../../../../Inputs/DropdownMenu";
import { track } from "../../../../../utils/tracking";
import { sanitizeExtractedFieldConstant } from "common/utils/strings";
import { AuthContext } from "../../../../Authorization/AuthContext";

export const EMPTY_VALUE = "(empty)";

export type RadioOptionsType = Array<{ value: string; text: React.ReactNode }>;

export type ExtractedFieldType =
  | "shortText"
  | "radioButtons"
  | "textarea"
  | "datePicker";

export type ExtractedFieldFormat =
  | "feet"
  | "default"
  | "constant"
  | "upperCase"
  | "date"
  | "boolean"
  | "sqIn"
  | "sqFt"
  | "capitalize";

export type ExtractedFieldProps<T extends CertificateFormFieldDottableKeys> = {
  label: string;
  value?: Maybe<GetTypeOfDottableField<CertificateFormFields, T>>;
  type?: Maybe<ExtractedFieldType>;
  name: T;
  disabled: boolean;
  isLowConfidence: boolean;
  hint?: string;
  options?: RadioOptionsType;
  inputValue?: string;
  onChange: (arg: Maybe<string>) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onConfirmAccuracy: (props: { fields: Array<string> }) => void;
  isOpen?: boolean;
  format?: ExtractedFieldFormat;
  onElementIsOpen?: () => void;
  position?: DropdownPosition;
};

type FormInput<T extends CertificateFormFieldDottableKeys> = Omit<
  ExtractedFieldProps<T>,
  | "label"
  | "isOpen"
  | "position"
  | "onElementIsOpen"
  | "isLowConfidence"
  | "onConfirmAccuracy"
>;

type InputMapping<T extends CertificateFormFieldDottableKeys> = {
  [key in ExtractedFieldType]: React.FC<FormInput<T>>;
};

const INPUTS: InputMapping<CertificateFormFieldDottableKeys> = {
  shortText: ({ name, onChange, inputValue, disabled }) => (
    <TextInput
      name={name}
      value={inputValue}
      size="smaller"
      placeholder="Enter value from file..."
      onChange={onChange}
      disabled={disabled}
    />
  ),
  radioButtons: ({ name, options, onChange, inputValue, disabled }) => (
    <Radio
      options={options ? options : []}
      name={name}
      value={(inputValue ?? "").toString()}
      onChange={onChange}
      hasBackground={true}
      required={false}
      disabled={disabled}
    />
  ),
  textarea: ({ name, onChange, inputValue, disabled }) => (
    <Textarea
      name={name}
      value={inputValue ?? ""}
      onChange={onChange}
      size="smaller"
      placeholder="Enter correct value..."
      disabled={disabled}
    />
  ),
  datePicker: ({ name, onChange, inputValue, disabled }) => (
    <Datepicker
      name={name}
      value={!inputValue || inputValue === "N/A" ? null : inputValue}
      label=""
      size="smaller"
      onChange={onChange}
      disabled={disabled}
    />
  ),
};

const FORMATTERS: Record<
  ExtractedFieldFormat,
  (value: Maybe<string>) => string
> = {
  feet: value => `${value} ft`,
  sqIn: value => `${value} sq in`,
  sqFt: value => `${value} sq ft`,
  constant: value => sanitizeExtractedFieldConstant(value),
  date: value => {
    if (isNil(value)) return "";

    return formatDateString({ format: "MM/DD/YYYY", dateString: value });
  },
  upperCase: value => (value ?? "").replace(/_/g, " ").toUpperCase(),
  boolean: value => (value?.toString() === "false" ? "No" : "Yes"),
  default: value => `${value}`,
  capitalize: value => capitalize(value ?? ""),
};

export default <T extends CertificateFormFieldDottableKeys>({
  label,
  name,
  value,
  hint,
  onChange,
  inputValue,
  format = "default",
  type = null,
  options,
  isOpen,
  disabled,
  isLowConfidence,
  onConfirmAccuracy,
  onOpen = () => {},
  onClose = () => {},
  onElementIsOpen,
  position,
}: ExtractedFieldProps<T>) => {
  const { isGuest } = useContext(AuthContext);
  const formatter = FORMATTERS[format];
  let formattedValue: string;
  if (isEmpty(value) && !isNumber(value) && !isBoolean(value)) {
    formattedValue = EMPTY_VALUE;
  } else if (value == "N/A") {
    formattedValue = value;
  } else {
    formattedValue = formatter(value ?? null);
  }

  const isFormInput = !isNil(type);
  let InputComponent: Maybe<React.FC<FormInput<T>>> = null;

  const actions: Array<ActionsProps> = [];
  if (isFormInput) {
    InputComponent = INPUTS[type!] as React.FC<FormInput<T>>;

    actions.push({
      label: "Edit extraction",
      disabled,
      onClick: () => {
        onOpen?.();
      },
    });

    if (isLowConfidence) {
      actions.push({
        label: "Confirm accuracy",
        disabled,
        onClick: () => {
          track("Confirm accuracy", { field: name });
          onConfirmAccuracy({ fields: [name] });
        },
      });
    }
  }

  return (
    <CertificateField
      label={label}
      value={formattedValue}
      isOpen={isOpen}
      isLowConfidence={isLowConfidence}
      onOpen={onOpen}
      onClose={onClose}
      showActions={isFormInput && !isGuest}
      actions={actions}
      onElementIsOpen={onElementIsOpen}
      position={position}
    >
      {InputComponent && (
        <div data-testid="certificate-field-input">
          <InputComponent
            name={name}
            onChange={onChange}
            inputValue={inputValue}
            disabled={disabled}
            options={options}
          />
          {hint && <Hint>{hint}</Hint>}
        </div>
      )}
    </CertificateField>
  );
};
