import React from "react";

import "./__styles__/Icon.scss";

export default () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C12.8355 0 9.74207 0.938384 7.11088 2.69649C4.4797 4.45459 2.42894 6.95345 1.21793 9.87706C0.0069325 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77487 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C31.995 11.7581 30.3077 7.69134 27.3082 4.69185C24.3087 1.69235 20.2419 0.00502833 16 0V0ZM3.00001 16C2.99654 13.5565 3.68275 11.1617 4.97974 9.09081C6.27673 7.01995 8.13183 5.35718 10.3317 4.29368C12.5316 3.23019 14.987 2.80915 17.4156 3.07898C19.8441 3.34881 22.1472 4.29855 24.06 5.819L5.81901 24.06C3.99367 21.7701 2.99977 18.9284 3.00001 16ZM16 29C13.0716 29.0001 10.23 28.0062 7.94001 26.181L26.181 7.94C27.7015 9.85281 28.6512 12.1559 28.921 14.5844C29.1909 17.013 28.7698 19.4684 27.7063 21.6683C26.6428 23.8682 24.9801 25.7233 22.9092 27.0203C20.8383 28.3173 18.4435 29.0035 16 29Z"
        fill="#111111"
      />
    </svg>
  );
};
