import React, { useContext } from "react";
import { getCustomMapRowContent } from "common-client/utils/firms";
import {
  Label,
  PropertyAttributeLinkContainer,
} from "../FIRMInfo/FIRMTable/__styles__/FIRMTable";
import { Icon } from "../../../Common/Icons/LucideIcons";
import { RenderMode } from "../FIRMInfo/types";
import { PropertyOverviewContext } from "../PropertyOverviewContext";
import { PropertyOverviewTooltip } from "./PropertyOverviewTooltip";
import { AccountPropertyAttribute } from "../../../../generated/graphql";
import { TableCell, TableRow } from "../../../Common/__styles__/StripedTable";
import { AuthContext } from "../../../Authorization/AuthContext";

export const CustomMapRow = ({
  accountPropertyAttribute,
  size,
}: {
  accountPropertyAttribute: Pick<
    AccountPropertyAttribute,
    | "sourceId"
    | "displayValueAsLink"
    | "label"
    | "isPublic"
    | "tooltip"
    | "displayConfig"
  >;
  size: RenderMode;
}) => {
  const {
    sourceId,
    displayValueAsLink,
    label,
    isPublic,
    tooltip,
    displayConfig,
  } = accountPropertyAttribute;

  const tooltipElement = PropertyOverviewTooltip({
    tooltipMarkdown: tooltip,
  });
  const { customMapsInfo } = useContext(PropertyOverviewContext);
  const { isGuest } = useContext(AuthContext);

  const customMap = customMapsInfo.find(map => map.id === sourceId);

  if (!customMap) {
    return null;
  }

  const { value, defaultDisplay } = getCustomMapRowContent({
    map: customMap,
    displayConfig,
  });

  if (isGuest && !isPublic) {
    return null;
  }
  if (!value && !defaultDisplay) {
    return null;
  }

  const CustomMapValue =
    displayValueAsLink && value
      ? () => {
          return (
            <PropertyAttributeLinkContainer>
              <span>
                <Icon iconName="link" color="contentInfo" size={16} />
              </span>
              <a href={value} target="_blank">
                Link
              </a>
            </PropertyAttributeLinkContainer>
          );
        }
      : () => {
          return <span>{value || defaultDisplay}</span>;
        };

  return (
    <TableRow header={false}>
      <TableCell containsTooltip>
        <div style={{ overflowX: "hidden", whiteSpace: "normal" }}>
          <Label size={size}>{label}</Label>
          {tooltipElement}
        </div>
      </TableCell>
      <TableCell>
        <CustomMapValue />
      </TableCell>
    </TableRow>
  );
};
